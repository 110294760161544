import { UPDATE_SUBMISSION_DATA, SAVE_CONSULTATION } from '../constants/action-types';
import { createSubmission } from '../../util/data';

const initialState = null;

const updateSubmissionData = (state, action) => {
  const { payload } = action;
  return payload;
};
const updateSaveSubmissionData = (state, action) => {
  const { consultation, sections, questions } = action.payload;
  const newState = createSubmission(consultation.id, sections, questions);
  return newState;
};
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SUBMISSION_DATA:
      return updateSubmissionData(state, action);
    case SAVE_CONSULTATION:
      return updateSaveSubmissionData(state, action);
    default:
      return state;
  }
};
