// Immutable clone actions

export function removeItemFromArray(array, index) {
  return [...array.slice(0, index), ...array.slice(index + 1)];
}

export function insertItemIntoArray(array, index, item) {
  return [...array.slice(0, index), item, ...array.slice(index)];
}

export function addToArray(array, item) {
  return [...array, item];
}
