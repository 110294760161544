import React from 'react';
import { withTheme } from '@material-ui/core/styles';
import CreatableSelect from 'react-select/creatable';
import Color from 'color';

const CustomCreatableSelect = props => {
  const { theme } = props;
  const darkBlue = theme.palette.darkBlue.main;
  const styles = {
    multiValue: provided => ({
      ...provided,
      margin: 5,
      marginLeft: 0,
      marginRight: 10,
    }),
    multiValueLabel: provided => ({
      ...provided,
      fontSize: '11pt',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: -4,
    }),
    multiValueRemove: provided => ({
      ...provided,
      width: 24,
      height: 24,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    control: provided => ({
      ...provided,
      minHeight: 46,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 5,
      paddingBottom: 5,
      cursor: 'text',
      '&:focus-within': {
        boxShadow: `0 0 0 1px ${darkBlue}`,
        borderColor: darkBlue,
      },
      '&:hover': {
        borderColor: darkBlue,
      },
    }),
    clearIndicator: provided => ({
      ...provided,
      cursor: 'pointer',
      marginLeft: 10,
      marginRight: 10,
      color: darkBlue,
    }),
    dropdownIndicator: provided => ({
      ...provided,
      cursor: 'pointer',
      marginLeft: 10,
      color: darkBlue,
    }),
    option: provided => ({
      ...provided,
      padding: 20,
    }),
    noOptionsMessage: provided => ({
      ...provided,
      padding: 20,
    }),
  };
  return (<CreatableSelect { ...props } styles={ styles } />
  );
};

export default withTheme(CustomCreatableSelect);
