import React, { memo } from 'react';
import { connect } from 'react-redux';
import {
  Typography, FormControl, FormHelperText, FormLabel,
} from '@material-ui/core';
import { getQuestion } from '../../redux/selectors/consultationSelectors';
import Input from './Input';
import AddCommentButton from './AddCommentButton/AddCommentButton';
import Asterisk from '../Common/Asterisk/Asterisk';
import { ENABLE_OPTIONAL_FIELD_LABELS } from '../../UserTestingFlags';
import ChangeHighlighter from '../ChangeHighlighter/ChangeHighlighter';

const Question = memo(props => {
  const {
    questionId,
    data,
    index,
    classes,
    values,
    handleBlur,
    handleChange,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    section_id,
  } = props;

  const {
    required, title, description, helper_text,
  } = data;

  function replaceAll(str, find, replace) {
    function escapeRegExp(str) {
      return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    }
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
  }

  const renderTitle = title => (
    <ChangeHighlighter monitorMultipleValues={ [title, required] }>
      <div id={ `question-${questionId}-field-title` }>
        <FormLabel component='legend' className={ classes.spacingBottom } style={ { marginTop: 10 } }>
          <Typography
            color='textPrimary'
            variant='h6'
            display='inline'
            style={ { display: 'inline-block', marginRight: 20 } }
          >
            {`${(index + 1)} .`}
          </Typography>
          <Typography
            color={ title ? 'textPrimary' : 'textSecondary' }
            variant='body1'
            style={ { fontStyle: title ? 'normal' : 'italic', display: 'inline-block' } }
          >
            {required && !ENABLE_OPTIONAL_FIELD_LABELS && (
              <span>
                <Asterisk />
                {' '}
              </span>
            )}
            {title || 'Question title Placeholder'}
            {!required && ENABLE_OPTIONAL_FIELD_LABELS && (
              <i>
                {' '}
            (Optional)
              </i>
            )}
          </Typography>
        </FormLabel>
      </div>
    </ChangeHighlighter>
  );

  const renderDescription = description => {
    /**
     * Due to a 'quirk' with react-quill, occasionally additional spacing is added
     * which results in inconsistencies. For example, when react-quill mounts, the
     * contents of the text area is 'null', however if something is typed into the
     * text area and then removed, the contents becomes '<p><br></p>'. Therefore
     * this section of code removes this to avoid unexpected spaces in the form.
    */
    const cleanedDescription = description ? replaceAll(description, '</p><p><br></p><p>', '</p><p>') : description;

    return (
      <ChangeHighlighter monitorSingleValue={ cleanedDescription }>
        <Typography
          id={ `question-${questionId}-field-description` }
          variant='body1'
          color={ cleanedDescription && cleanedDescription !== '<p><br></p>' ? 'textPrimary' : 'textSecondary' }
          dangerouslySetInnerHTML={ {
            __html:
              cleanedDescription && cleanedDescription !== '<p><br></p>'
                ? cleanedDescription
                : '<p><em>Question description placeholder</em></p>',
          } }
        />
      </ChangeHighlighter>
    );
  };

  return (
    <div id={ `question-${questionId}` } key={ `question-${questionId}` } style={ { width: '100%' } }>
      <FormControl component='fieldset' disabled={ isSubmitting } style={ { width: '100%' } }>
        <div
          style={ {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexGrow: 1,
          } }
        >
          {renderTitle(title)}
          {
            // Temporarily disabled until commenting functionality has been implemented.
            /* <AddCommentButton style={ { marginLeft: 'auto' } } />  */
          }
        </div>
        {renderDescription(description)}
        <FormHelperText>{helper_text}</FormHelperText>
        <div style={ { marginBottom: 10, marginLeft: 40 } }>
          <Input
            { ...{
              questionId,
              className: classes.spacingBottom,
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
              options: data.input_options,
              inputType: data.input_type,
              isSubmitting,
              sectionId: section_id,
            } }
          />
        </div>
      </FormControl>
    </div>
  );
});

Question.propTypes = {};

const mapStateToProps = (state, { questionId }) => getQuestion(state, questionId);

export default connect(mapStateToProps)(Question);
