import React, { useState, Fragment, memo } from 'react';
import { useTheme } from '@material-ui/styles';
import {
  Typography,
  TextField,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Divider,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { green } from '@material-ui/core/colors';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import deepKeys from 'deep-keys';
import { RequiredIndicatorText } from '../StyledComponents';
import Input from './Input';
import Header from '../../containers/Forms/Header';
import CustomButton from '../CustomButton/CustomButton';
import { getConsultation } from '../../redux/selectors/consultationSelectors';
import Section from './Section';
import { selectSubmissionData } from '../../redux/selectors/submissionFormSelectors';
import BasicLoader from '../BasicLoader/BasicLoader';
import DateInfoBar from './DateInfoBar/DateInfoBar';
import AttachmentsPanel from './AttachmentsPanel/AttachmentsPanel';
import MemoTextField from '../Common/MemoTextField/MemoTextField';
import AsteriskIndicatesRequired from '../Common/AsteriskIndicatesRequired/AsteriskIndicatesRequired';
import FormItemDecorator from '../../containers/Forms/FormItemDecorator';
import { ENABLE_OPTIONAL_FIELD_LABELS } from '../../UserTestingFlags';
import ChangeHighlighter from '../ChangeHighlighter/ChangeHighlighter';

const styles = theme => ({
  mainContainer: { width: '100%', height: '100%', overflowY: 'auto' },
  dateBarContainer: { marginTop: theme.spacing(3), marginBottom: theme.spacing(3) },
  link: {
    cursor: 'pointer',
  },
  ul: { paddingLeft: theme.spacing(3) },
  li: { color: theme.palette.secondary.main },
  divider1: { marginTop: theme.spacing(2), marginBottom: theme.spacing(4) },
  divider2: { marginTop: theme.spacing(4), marginBottom: theme.spacing(4) },
  form: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  spacingTop: { marginTop: theme.spacing(4) },
  spacingBottom: { marginBottom: theme.spacing(2) },
  bold: { fontWeight: 500 },
  green: { color: green[500] },
});

function replaceAll(str, find, replace) {
  function escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
  }
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

const MemoSelect = memo(props => <Select { ...props } />);

const renderTitle = title => (
  <ChangeHighlighter monitorSingleValue={ title }>
    <Typography
      variant='h5'
      gutterBottom
      style={ {
        maxWidth: 500,
        color: '#333333',
        fontStyle: title ? 'normal' : 'italic',
        fontWeight: 600,
        fontSize: '18pt',
      } }
    >
      {title || 'Title Placeholder'}
    </Typography>
  </ChangeHighlighter>
);

const renderDescription = description => {
  // Fix unnecessary spacing
  const cleanedDescription = description ? replaceAll(description, '</p><p><br></p><p>', '</p><p>') : description;

  return (
    <ChangeHighlighter monitorSingleValue={ cleanedDescription }>
      <Typography
        variant='body1'
        id='description'
        dangerouslySetInnerHTML={ {
          __html:
          cleanedDescription && cleanedDescription !== '<p><br></p>'
            ? cleanedDescription
            : '<p><em>Consultation description placeholder</em></p>',
        } }
      />
    </ChangeHighlighter>
  );
};

const renderUsingThisForm = () => (
  <div id='using-this-form' style={ { marginTop: 30 } }>
    <Typography variant='h6' gutterBottom style={ { color: '#333333' } }>
      Using this form
    </Typography>
    <Typography
      variant='body1'
      color='textPrimary'
      dangerouslySetInnerHTML={ {
        __html:
      `<p>Please fill in the form below to make a submission, then click Save.</p><p>If you wish to save your feedback and return to the form later you will need to provide your email address and click \'save and exit\' at the bottom of the page. You will then be emailed a link to access your submission at a later stage. The questions below are a guide only and all comments are welcome. To ensure your point of view is clearly understood, please explain your rationale. ${ENABLE_OPTIONAL_FIELD_LABELS ? '' : 'An asterisk (<span style="color: #2F80ED">*</span>) indicates a mandatory field.'}</p><p>Please fill in the form below, then click Continue to review your submission. At any time you may click Save and Exit so that you can return later.</p>`,
      } }
    />
  </div>
);

const PrivacyStatement = memo(props => {
  const { privacy_statement, setAcceptedPrivacy, acceptedPrivacy } = props;

  /**
   * Due to a 'quirk' with react-quill, occasionally additional spacing is added
   * which results in inconsistencies. For example, when react-quill mounts, the
   * contents of the text area is 'null', however if something is typed into the
   * text area and then removed, the contents becomes '<p><br></p>'. Therefore
   * this section of code removes this to avoid unexpected spaces in the form.
   */
  const cleanedPrivacyStatement = privacy_statement
    ? replaceAll(privacy_statement, '</p><p><br></p><p>', '</p><p>')
    : privacy_statement;

  return (
    <div style={ { marginTop: 30 } }>
      <ChangeHighlighter monitorSingleValue={ cleanedPrivacyStatement }>
        <Typography variant='h6' id='privacy' gutterBottom style={ { color: '#333333' } }>
        Privacy statement
        </Typography>
        <Typography
          variant='body1'
          color={ cleanedPrivacyStatement ? 'textPrimary' : 'textSecondary' }
          dangerouslySetInnerHTML={ {
            __html:
          cleanedPrivacyStatement && cleanedPrivacyStatement !== '<p><br></p>'
            ? cleanedPrivacyStatement
            : '<p><em>Privacy statement placeholder</em></p>',
          } }
        />
      </ChangeHighlighter>
      <div style={ { display: 'flex', justifyContent: 'flex-end', marginTop: 20 } }>
        <CustomButton
          label='Decline'
          aria-label='Decline Privacy Statement'
          variant='outlined'
          color='darkBlue'
          style={ { marginRight: 10 } }
        />
        <CustomButton label='Accept' aria-label='Accept Privacy Statement' variant='contained' color='darkBlue' />
      </div>
    </div>

  );
});

const ConsultationInformation = memo(props => {
  const { title, description } = props;
  return (
    <div id='title' style={ { paddingTop: 30 } }>
      {renderTitle(title)}
      {renderDescription(description)}
    </div>
  );
});

const ContactDetails = memo(props => {
  const {
    required_contact_fields,
    submitter_types,
    values,
    handleBlur,
    handleChange,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    classes,
    errors,
  } = props;

  const onSubmitterTypesChange = value => setFieldValue('submitter_types', value);
  const onSubmitterTypesBlur = () => setFieldTouched('submitter_types', true);

  return (
    <div>
      <div id='required-contact-details'>
        <Typography variant='h6' gutterBottom style={ { color: '#333333', marginBottom: 20 } }>
          Submitter details
        </Typography>
        { !ENABLE_OPTIONAL_FIELD_LABELS && <AsteriskIndicatesRequired />}
        <ChangeHighlighter monitorSingleValue={ required_contact_fields.given_or_company_name }>
          <FormItemDecorator
            label='Given name(s) or company name'
            required={ required_contact_fields.given_or_company_name }
            id='required_contact_fields.given_or_company_name'
          >
            <MemoTextField
              name='contact_details.given_or_company_name'
              autoComplete='name'
            // helperText={
            //   errors.contact_details.given_or_company_name &&
            //   touched.contact_details.given_or_company_name &&
            //   errors.contact_details.given_or_company_name
            // }
              value={ values.given_or_company_name }
              onDoneEditing={ handleChange }
              onBlur={ handleBlur }
              disabled={ isSubmitting }
            />
          </FormItemDecorator>
        </ChangeHighlighter>
        <ChangeHighlighter monitorSingleValue={ required_contact_fields.surname }>
          <FormItemDecorator label='Surname' required={ required_contact_fields.surname } id='required_contact_fields.surname'>
            <MemoTextField
              name='contact_details.surname'
              autoComplete='family-name'
            // helperText={
            //   errors.contact_details.surname && touched.contact_details.surname && errors.contact_details.surname
            // }
              value={ values.contact_details.surname }
              onDoneEditing={ handleChange }
              onBlur={ handleBlur }
              disabled={ isSubmitting }
            />
          </FormItemDecorator>
        </ChangeHighlighter>
        <ChangeHighlighter monitorSingleValue={ required_contact_fields.address }>
          <FormItemDecorator label='Address' required={ required_contact_fields.address } id='required_contact_fields.address'>
            <MemoTextField
              name='contact_details.address'
              autoComplete='street-address'
            // helperText={
            //   errors.contact_details.address && touched.contact_details.address && errors.contact_details.address
            // }
              value={ values.contact_details.address }
              onDoneEditing={ handleChange }
              onBlur={ handleBlur }
              disabled={ isSubmitting }
            />
          </FormItemDecorator>
        </ChangeHighlighter>
        <ChangeHighlighter monitorSingleValue={ required_contact_fields.region }>
          <FormItemDecorator label='Region' required={ required_contact_fields.region } id='required_contact_fields.region'>
            <MemoTextField
              name='contact_details.region'
              autoComplete='region'
            // helperText={
            //   errors.contact_details.region && touched.contact_details.region && errors.contact_details.region
            // }
              value={ values.contact_details.region }
              onDoneEditing={ handleChange }
              onBlur={ handleBlur }
              disabled={ isSubmitting }
            />
          </FormItemDecorator>
        </ChangeHighlighter>
        <ChangeHighlighter monitorSingleValue={ required_contact_fields.country }>
          <FormItemDecorator label='Country' required={ required_contact_fields.country } id='required_contact_fields.country'>
            <MemoTextField
              name='contact_details.country'
              autoComplete='country-name'
            // helperText={
            //   errors.contact_details.country && touched.contact_details.country && errors.contact_details.country
            // }
              value={ values.contact_details.country }
              onDoneEditing={ handleChange }
              onBlur={ handleBlur }
              disabled={ isSubmitting }
            />
          </FormItemDecorator>
        </ChangeHighlighter>
        <ChangeHighlighter monitorSingleValue={ required_contact_fields.phone }>
          <FormItemDecorator label='Phone' required={ required_contact_fields.phone } id='required_contact_fields.phone'>
            <MemoTextField
              name='contact_details.phone'
              autoComplete='tel'
              type='tel'
            // helperText={
            //   errors.contact_details.phone && touched.contact_details.phone && errors.contact_details.phone
            // }
              value={ values.contact_details.phone }
              onDoneEditing={ handleChange }
              onBlur={ handleBlur }
              disabled={ isSubmitting }
            />
          </FormItemDecorator>
        </ChangeHighlighter>
        <ChangeHighlighter monitorSingleValue={ required_contact_fields.email }>
          <FormItemDecorator label='Email' required={ required_contact_fields.email } id='required_contact_fields.email'>
            <MemoTextField
              name='contact_details.email'
              autoComplete='email'
              type='email'
            // helperText={
            //   errors.contact_details.email && touched.contact_details.email && errors.contact_details.email
            // }
              value={ values.contact_details.email }
              onDoneEditing={ handleChange }
              onBlur={ handleBlur }
              disabled={ isSubmitting }
            />
          </FormItemDecorator>
        </ChangeHighlighter>
        <ChangeHighlighter monitorSingleValue={ required_contact_fields.contact_person }>
          <FormItemDecorator label='Contact person' required={ required_contact_fields.contact_person } id='required_contact_fields.contact_person'>
            <MemoTextField
              name='contact_details.contact_person'
            // helperText={
            //   errors.contact_details.contact_person &&
            //   touched.contact_details.contact_person &&
            //   errors.contact_details.contact_person
            // }
              value={ values.contact_details.contact_person }
              onDoneEditing={ handleChange }
              onBlur={ handleBlur }
              disabled={ isSubmitting }
            />
          </FormItemDecorator>
        </ChangeHighlighter>
        <ChangeHighlighter monitorMultipleValues={ [submitter_types.required, submitter_types.options] }>
          <FormItemDecorator id='submitter-types' label='Submitter types' required={ submitter_types.required }>
            <MemoSelect
              name='submitter_types'
              options={ submitter_types.options }
              isMulti
              isClearable
              value={ values.submitter_types }
              onChange={ onSubmitterTypesChange }
              onBlur={ onSubmitterTypesBlur }
              isDisabled={ isSubmitting }
            />
          </FormItemDecorator>
        </ChangeHighlighter>
      </div>
    </div>
  );
});

const isReInitialising = (newState, oldState) => {
  const newArray = deepKeys(newState);
  const oldArray = deepKeys(oldState);
  return oldArray.some(key => newArray.indexOf(key) < 0);
};

const Submission = memo(props => {
  const {
    consultationId, data, classes, preview, section_ids, submissionData, setAttachment, attachment,
  } = props;
  const {
    title,
    description,
    privacy_statement,
    consultation_dates,
    emails,
    required_contact_fields,
    submitter_types,
    assets,
  } = data;

  const [acceptedPrivacy, setAcceptedPrivacy] = useState(false);

  return (
    <div className={ classes.mainContainer } id='submission-scroll-area'>
      <Container fixed>
        <ConsultationInformation
          { ...{
            title, description, classes, privacy_statement, consultation_dates, emails, assets, preview,
          } }
        />
        <AttachmentsPanel { ...{ preview, setAttachment, attachment } } />
        {renderUsingThisForm()}
        <PrivacyStatement { ...{ privacy_statement, setAcceptedPrivacy, acceptedPrivacy } } />
      </Container>
      <div className={ classes.dateBarContainer }>
        <DateInfoBar
          openDate={ consultation_dates.open }
          closeDate={ consultation_dates.close }
          notificationEmail={ emails.notification }
          originalReceiverEmail={ emails.original_receiver }
        />
      </div>
      <Container fixed>
        <Formik
          // only need to deep check if it is in consultation builder/preview mode
          enableReinitialize={ preview }
          initialValues={ submissionData }
          onSubmit={ (values, actions) => {
            // MyImaginaryRestApiCall(user.id, values).then(
            //   updatedUser => {
            //     actions.setSubmitting(false);
            //     updateUser(updatedUser);
            //     onClose();
            //   },
            //   error => {
            //     actions.setSubmitting(false);
            //     actions.setErrors(transformMyRestApiErrorsToAnObject(error));
            //     actions.setStatus({ msg: 'Set some arbitrary status or data' });
            //   }
            // );
          } }
          render={ ({
            values,
            errors,
            status,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          }) => {
            if (preview && isReInitialising(submissionData, values)) {
              return <BasicLoader />;
            }
            return (
              <form onSubmit={ handleSubmit } className={ classes.form }>
                <ContactDetails
                  { ...{
                    required_contact_fields,
                    submitter_types,
                    values,
                    handleBlur,
                    handleChange,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    classes,
                    errors,
                  } }
                />
                <Divider className={ classes.divider2 } />
                {section_ids.map((sectionId, index) => (
                  <Section
                    { ...{
                      sectionId,
                      key: `submission-section-${sectionId}`,
                      index,
                      values: values.sections[sectionId],
                      handleBlur,
                      handleChange,
                      isSubmitting,
                      setFieldValue,
                      setFieldTouched,
                      classes,
                      errors,
                      touched,
                    } }
                  />
                ))}
                <div style={ {
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 50,
                  marginBottom: 50,
                } }
                >
                  <CustomButton
                    label='Submit'
                    aria-label='Submit'
                    disabled={ preview || isSubmitting }
                    color='darkBlue'
                    variant='contained'
                    type='submit'
                    fixedWidth={ 100 }
                  />
                </div>
              </form>
            );
          } }
        />
      </Container>
    </div>
  );
});

Submission.propTypes = {
  submissionData: PropTypes.object.isRequired,
  consultationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  preview: PropTypes.bool,
};

const mapStateToProps = (state, { consultationId }) => ({ ...getConsultation(state, consultationId), submissionData: selectSubmissionData(state) });

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Submission));
