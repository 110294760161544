import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers/index';
import thunk from 'redux-thunk';
import React from 'react';
import withReduxEnhancer from 'addon-redux/enhancer';
import { createDevTools } from 'redux-devtools';
import LogMonitor from 'redux-devtools-log-monitor';
import DockMonitor from 'redux-devtools-dock-monitor';
var storeEnhancers;

if (process.env.NODE_ENV !== 'production') {
  storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  storeEnhancers = compose;
}
export const DevTools = createDevTools(
  <DockMonitor toggleVisibilityKey="ctrl-q" changePositionKey="shift-q" defaultIsVisible={false}>
    <LogMonitor />
  </DockMonitor>,
);
const createMiddlewareEnhancer = () => {
  const middleware = [];
  middleware.push(thunk);
  return applyMiddleware(...middleware);
};

const createEnhancer = () => {
  const enhancers = [];
  enhancers.push(createMiddlewareEnhancer());
  enhancers.push(withReduxEnhancer);

  return storeEnhancers(...enhancers);
};
const store = createStore(
  rootReducer,
  {},
  compose(
    createEnhancer(),
    DevTools.instrument(),
  ),
);
export default store;
