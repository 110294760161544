import { green } from '@material-ui/core/colors';

const styles = theme => ({
  snackbar: {
    backgroundColor: theme.palette.error.dark,
  },
  warningIcon: {
    fontSize: 30,
    opacity: 0.9,
    marginRight: theme.spacing(3),
  },
  closeIcon: {
    fontSize: 20,
    opacity: 0.9,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default styles;
