import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import { NavLink, Link, withRouter } from 'react-router-dom';


import Box from '@material-ui/core/Box';
import IconButton from '../IconButton/IconButton';

const styles = theme => ({
  navigationContainer: {
    width: '70px',
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
  menuButton: {
    margin: '8px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': { backgroundColor: theme.palette.secondary.light, color: 'white' },
  },
  sideMenuButton: {
    margin: '8px',
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    '&:hover': { backgroundColor: theme.palette.secondary.light, color: 'white' },
  },
  list: {
    width: 250, height: '100%', color: 'white', backgroundColor: theme.palette.primary.main,
  },
  fullList: { width: 'auto' },
});

const MainNavigation = props => {
  const { classes, theme } = props;

  const [state, setState] = React.useState({
    open: false,
  });

  const withinConsultation = props.locationPath.startsWith('/consultations/');

  const sideList = withRouter((props, open) => {
    const { history } = props;

    const consultation_id = history.location.pathname.split('/')[2];
    const AlwaysThereRoutes = [
      { text: 'All Consultations', path: '/consultations' },
      { text: 'New Consultation', path: '/consultations/generate' },
      { text: 'Public Consultation Page', path: '/public/consultations' },
    ];
    const InsideConsultationRoutes = [
      { text: 'All Submissions', path: `/consultations/${consultation_id}/submissions` },
      { text: 'Create Manual Submission', path: '/consultations' },
      { text: 'Data Discovery', path: '/consultations' },
      { text: 'Reporting', path: '/consultations' },
      { text: 'Large Group ', path: '/consultations' },
      { text: 'Public: Create Submission', path: `/public/consultations/${consultation_id}/make-a-submission` },
    ];
    const UserRoutes = [{ text: 'Edit User', path: '/' }, { text: 'Log out', path: '/login' }];
    const OrganisationRoutes = [
      { text: 'Organisation Settings', path: '/consultations' },
      { text: 'Manage Teams', path: '/consultations' },
    ];
    return (
      <div className={ classes.list } role='presentation' onClick={ toggleDrawer(false) } onKeyDown={ toggleDrawer(false) }>
        <IconButton
          className={ classes.sideMenuButton }
          size='medium'
          icon={ CloseIcon }
          onClick={ toggleDrawer(true) }
          aria-label='Close Menu Button'
        // style={{ width: '40px', height: '40px', color: 'darkBlue', backgroundColor: 'white' }}
        />
        <br />
        {withinConsultation && (
          <>
            <h5 style={ { margin: '5px' } }>Current Consultation Options</h5>
            <List>
              {InsideConsultationRoutes.map(route => (
                <ListItem
                  button
                  key={ route.text }
                  onClick={ () => {
                    history.push(route.path);
                  } }
                >
                  <ListItemText primary={ route.text } />
                </ListItem>
              ))}
            </List>
            <Divider />
          </>
        )}

        <h5 style={ { margin: '5px' } }>Current Consultation Options</h5>
        <List>
          {AlwaysThereRoutes.map(route => (
            <ListItem
              button
              key={ route.text }
              onClick={ () => {
                history.push(route.path);
              } }
            >
              <ListItemText primary={ route.text } />
            </ListItem>
          ))}
        </List>
        <Divider />
        <h5 style={ { margin: '5px' } }>Organisation Settings</h5>
        <List>
          {OrganisationRoutes.map(route => (
            <ListItem
              button
              key={ route.text }
              onClick={ () => {
                history.push(route.path);
              } }
            >
              <ListItemText primary={ route.text } />
            </ListItem>
          ))}
        </List>
        <Divider />
        <h5 style={ { margin: '5px' } }>User settings</h5>
        <List>
          {UserRoutes.map(route => (
            <ListItem
              button
              key={ route.text }
              onClick={ () => {
                history.push(route.path);
              } }
            >
              <ListItemText primary={ route.text } />
            </ListItem>
          ))}
        </List>
      </div>
    );
  });

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ open });
    console.log(state);
  };

  return (
    <Box className={ classes.navigationContainer }>
      <IconButton
        className={ classes.menuButton }
        size='medium'
        icon={ MenuIcon }
        onClick={ toggleDrawer(true) }
        aria-label='Open Menu Button'
      />

      <Drawer anchor='left' open={ state.open } onClose={ toggleDrawer(false) }>
        {sideList('open')}
      </Drawer>
    </Box>
  );
};


export default withStyles(styles, { withTheme: true })(MainNavigation);
