export const fieldIsBlank = value => {
  return value === '' || value === '<p><br></p>';
};

export const emailInvalidFormat = value => {
  return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
};

export const datesAreNonsequential = (firstDate, secondDate) => {
  return firstDate.diff(secondDate) > 0;
};
