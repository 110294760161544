import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Fade, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => {
  return {
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    marginTop: { marginTop: theme.spacing(10) },
  };
};

function SubmissionSuccess(props) {
  const { classes, history, theme } = props;

  const redirectToPublic = () => {
    setTimeout(() => {
      history.push('/public/consultations');
    }, 3500);
  };

  // DidMount
  useEffect(redirectToPublic, []);

  return (
    <Fade in>
      <div className={classes.mainContainer}>
        <img
          className={classes.logoImage}
          src={'/images/logos/' + theme.colouredLogoFileName}
          alt="logo"
          style={{ width: '50%' }}
        />
        <Typography variant="h5" color="primary">
          Thanks you for your submission.
        </Typography>
        <Typography variant="h6" color="secondary" className={classes.marginTop}>
          Redirecting you back..
        </Typography>
      </div>
    </Fade>
  );
}

SubmissionSuccess.propTypes = {};

export default withStyles(styles, { withTheme: true })(SubmissionSuccess);
