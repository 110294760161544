import moment from 'moment';
import { fieldIsBlank, emailInvalidFormat, datesAreNonsequential } from './ValidationFunctions';

export default {
  consultation: {
    title: value => {
      return fieldIsBlank(value) ? "Consultation title can't be blank." : null;
    },
    description: value => {
      return fieldIsBlank(value) ? "Consultation description can't be blank." : null;
    },
    privacy_statement: value => {
      return fieldIsBlank(value) ? "Privacy statement can't be blank." : null;
    },
    grace_period: value => {
      return null;
    },
    consultation_dates: {
      open: (value, consultation) => {
        if (consultation.data.consultation_dates.close) {
          const open = value instanceof moment ? value : moment(value);
          let close = consultation.data.consultation_dates.close;
          close = close instanceof moment ? close : moment(close);
          return datesAreNonsequential(open, close) ? 'The opening date must be before the closing date.' : null;
        } else {
          return value === null ? 'An opening and closing date are required.' : 'A closing date is required.';
        }
      },
      close: (value, consultation) => {
        if (consultation.data.consultation_dates.open) {
          const close = value instanceof moment ? value : moment(value);
          let open = consultation.data.consultation_dates.open;
          open = open instanceof moment ? open : moment(open);
          return datesAreNonsequential(open, close) ? 'The opening date must be before the closing date.' : null;
        } else {
          return value === null ? 'An opening and closnig date are required.' : 'An opening date is required.';
        }
      },
    },
    emails: {
      notification: value => {
        return fieldIsBlank(value)
          ? "Email can't be blank."
          : emailInvalidFormat(value)
            ? 'Not an email address.'
            : null;
      },
      original_receiver: value => {
        return fieldIsBlank(value)
          ? "Email can't be blank."
          : emailInvalidFormat(value)
            ? 'Not an email address.'
            : null;
      },
      visible_public: value => {
        return null; // Does not need validating, so no error message is ever returned.
      },
    },
    required_contact_fields: {
      address: value => {
        return null; // Does not need validating, so no error message is ever returned.
      },
      contact_person: value => {
        return null; // Does not need validating, so no error message is ever returned.
      },
      country: value => {
        return null; // Does not need validating, so no error message is ever returned.
      },
      email: value => {
        return null; // Does not need validating, so no error message is ever returned.
      },
      given_or_company_name: value => {
        return null; // Does not need validating, so no error message is ever returned.
      },
      phone: value => {
        return null; // Does not need validating, so no error message is ever returned.
      },
      region: value => {
        return null; // Does not need validating, so no error message is ever returned.
      },
      surname: value => {
        return null; // Does not need validating, so no error message is ever returned.
      },
    },
    submitter_types: {
      allow_public_selection: value => {
        return null; // Does not need validating, so no error message is ever returned.
      },
      required: value => {
        return null; // Does not need validating, so no error message is ever returned.
      },
      options: value => {
        return null; // Does not need validating, so no error message is ever returned.
      },
    },
    assets: {
      supporting_documents: value => {
        return null; // Does not need validating, so no error message is ever returned.
      },
      logo: value => {
        return null; // Does not need validating, so no error message is ever returned.
      },
    },
  },
  section: {
    title: value => {
      return fieldIsBlank(value) ? "Section name can't be blank." : null;
    },
    description: value => {
      return fieldIsBlank(value) ? "Section description can't be blank." : null;
    },
  },
  question: {
    title: value => {
      return fieldIsBlank(value) ? "Question can't be blank." : null;
    },
    input_type: value => {
      return value === 'none' ? "Input type can't be 'None'." : null;
    },
    description: value => {
      return null; // Does not need validating, so no error message is ever returned.
    },
    helper_text: value => {
      return null; // Does not need validating, so no error message is ever returned.
    },
    input_options: value => {
      return null; // Does not need validating, so no error message is ever returned.
    },
    required: value => {
      return null; // Does not need validating, so no error message is ever returned.
    },
  },
};
