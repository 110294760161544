import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Typography, Container, Grid } from '@material-ui/core';
import moment from 'moment';
import ChangeHighlighter from '../../ChangeHighlighter/ChangeHighlighter';

const styles = theme => ({
  mainContainer: { backgroundColor: theme.palette.primary.main },
  container: { paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4) },
  textColor: { color: theme.palette.primary.contrastText },
});

const DateInfoBar = props => {
  const {
    openDate, closeDate, notificationEmail, originalReceiverEmail, classes,
  } = props;

  return (
    <div id='submission-period-and-email-bar' className={ classes.mainContainer }>
      <Container fixed>
        <Grid container className={ classes.container }>
          <Grid item xs={ 6 }>
            <Typography variant='h6' gutterBottom className={ classes.textColor }>
              Submission period
            </Typography>
            <ChangeHighlighter monitorSingleValue={ openDate }>
              <InfoItem
                title='Opening date'
                content={ openDate ? `${moment(openDate).format('dddd, MMMM Do, YYYY, LT')}` : null }
                classes={ classes }
              />
            </ChangeHighlighter>
            <ChangeHighlighter monitorSingleValue={ closeDate }>
              <InfoItem
                title='Closing date'
                content={ closeDate ? `${moment(closeDate).format('dddd, MMMM Do, YYYY, LT')}` : null }
                classes={ classes }
              />
            </ChangeHighlighter>
          </Grid>
          <Grid item xs={ 6 }>
            <Typography variant='h6' gutterBottom className={ classes.textColor }>
              Emails
            </Typography>
            <ChangeHighlighter monitorSingleValue={ originalReceiverEmail }>
              <InfoItem title='Original receiver email' content={ originalReceiverEmail } classes={ classes } />
            </ChangeHighlighter>
            <ChangeHighlighter monitorSingleValue={ notificationEmail }>
              <InfoItem title='Notification email' content={ notificationEmail } classes={ classes } />
            </ChangeHighlighter>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const InfoItem = props => {
  const { title, content, classes } = props;
  return (
    <div style={ { marginTop: 12 } }>
      <Typography variant='h6' gutterBottom style={ { fontSize: '11pt' } } className={ classes.textColor }>
        {title}
      </Typography>
      <Typography
        variant='body1'
        gutterBottom
        style={ { fontSize: '10pt', marginTop: -8, fontStyle: content ? 'normal' : 'italic' } }
        className={ classes.textColor }
      >
        {content || `${title} placeholder`}
      </Typography>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(DateInfoBar);
