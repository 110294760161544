import { createSelector } from 'reselect';
import { createSubmission } from '../../util/data';

export const selectConsultation = (state, consultationId) => {
  if (!state.consultations.consultations) return {};
  return state.consultations.consultations[consultationId] || {};
};

export const selectConsultations = state => state.consultations.consultations;

// Returns a memorised consultation
export const getConsultation = createSelector(
  selectConsultation,
  consultation => {
    return consultation;
  },
);

// Returns an Sections
const selectSections = state => state.consultations.sections;

// Returns an Section object related to a Section ID
const selectSection = (state, sectionId) => state.consultations.sections[sectionId];

// Returns an Array of Section IDs related to a Consultation ID
const selectSectionIds = (state, consultationId) => {
  const sections = selectSections(state);
  return Object.keys(sections).filter(sectionId => {
    // Using new String(value).valueOf() because === will return false because of type differences
    // eslint-disable-next-line no-new-wrappers
    return new String(sections[sectionId].consultationId).valueOf() === new String(consultationId).valueOf();
  });
};

// Used for creating public Submission data
export const selectSectionObjectsFromConsultationId = (state, consultationId) => {
  const consultation = selectConsultation(state, consultationId);
  let sectionObjects = {};
  consultation.section_ids.map(sectionId => {
    const section = selectSection(state, sectionId);
    return (sectionObjects[section.id] = section);
  });
  return sectionObjects;
};

// Returns a memorised sections from consultation id
export const getSectionsFromConsultations = createSelector(
  selectSectionObjectsFromConsultationId,
  sections => {
    return sections;
  },
);

// Returns an Section object related to a Section ID
export const getSection = createSelector(
  selectSection,
  section => {
    return section;
  },
);

// Returns a memorised selector for getting SectionIds based off of a consultation Id
export const makeGetSectionIds = () => {
  return createSelector(
    selectSectionIds,
    sectionIds => {
      return sectionIds;
    },
  );
};

// Returns an Questions
const selectQuestions = state => state.consultations.questions;

// Returns an Question object related to a Question ID
const selectQuestion = (state, questionId) => state.consultations.questions[questionId];

// Returns an Array of Section IDs related to a Consultation ID
const selectQuestionIds = (state, sectionId) => {
  const question = selectQuestions(state);
  return Object.keys(question).filter(questionId => {
    // Using new String(value).valueOf() because === will return false because of type differences
    // eslint-disable-next-line no-new-wrappers
    return new String(question[questionId].sectionId).valueOf() === new String(sectionId).valueOf();
  });
};

// Used for creating public Submission data
export const selectQuestionObjectsFromConsultationId = (state, consultationId) => {
  const consultation = selectConsultation(state, consultationId);
  let questionObjects = {};
  consultation.section_ids.map(sectionId => {
    return selectSection(state, sectionId).question_ids.map(questionId => {
      const question = selectQuestion(state, questionId);
      return (questionObjects[question.id] = question);
    });
  });
  return questionObjects;
};

// Returns an Question objects related to a consultation
export const getQuestionsFromConsultations = createSelector(
  selectQuestionObjectsFromConsultationId,
  questions => {
    return questions;
  },
);

// Returns an Question object related to a Question ID
export const getQuestion = createSelector(
  selectQuestion,
  question => {
    return question;
  },
);
