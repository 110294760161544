import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import CloudUpload from '@material-ui/icons/CloudUploadOutlined';
import { Typography, withStyles } from '@material-ui/core';
import prettyBytes from 'pretty-bytes';

const styles = theme => ({
  container: { display: 'flex', marginTop: 20 },
  dropzone: {
    minWidth: 100, maxWidth: 175, minHeight: 100, maxHeight: 175,
  },
  list: { marginLeft: theme.spacing(2) },
  selectedFilesLabel: { fontWeight: 500, fontSize: 14, color: 'rgb(51, 51, 51)' },
  filesListLabel: { fontWeight: 400, fontSize: 14, color: 'rgb(51, 51, 51)' },
});

function Dropzone(props) {
  const { onChange, files, classes } = props;
  const onDrop = useCallback(acceptedFiles => {
    const arr = [];
    acceptedFiles.map(file => {
      arr.push({
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        name: file.name,
        path: file.path,
        size: file.size,
        type: file.type,
        webkitRelativePath: file.webkitRelativePath,
      });
    });
    onChange(arr);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
  });

  return (
    <div className={ classes.container }>
      <div
        { ...getRootProps() }
        style={ {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: 20,
          marginBottom: 0,
          borderWidth: 2,
          borderRadius: 4,
          borderStyle: 'dashed',
          backgroundColor: '#fafafa',
          borderColor: isDragActive ? 'rgb(47, 128, 237)' : 'rgb(51, 51, 51)',
          color: isDragActive ? 'rgb(47, 128, 237)' : 'rgb(51, 51, 51)',
          fontWeight: 500,
          outline: 'none',
          transition: 'border .24s ease-in-out',
          width: 125,
          height: 125,
          textAlign: 'center',
          cursor: 'pointer',
        } }
        className={ classes.dropzone }
      >
        <input { ...getInputProps() } />
        <div>
          <CloudUpload style={ { height: 60, width: 60 } } />
        </div>
        <span style={ { fontSize: '10pt', width: 125, maxWidth: 125 } }>
          {isDragActive ? 'Drop the files here...' : 'Drag and drop some files here, or click to select files.'}
        </span>
      </div>
      <div className={ classes.list }>
        <Typography className={ classes.selectedFilesLabel }>
          Selected files to upload:
        </Typography>
        {files.length === 0
          && (
            <Typography className={ classes.filesListLabel }>
            None
            </Typography>
          )}
        <ul>
          {files.length > 0
            && files.map(file => (
              <li key={ file.name }>
                <Typography className={ classes.filesListLabel }>{`${file.name} - ${prettyBytes(file.size)}`}</Typography>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

Dropzone.propTypes = { onChange: PropTypes.func.isRequired };

export default withStyles(styles, { withTheme: true })(Dropzone);
