import React from 'react';
import styled from 'styled-components';
import { Fade } from '@material-ui/core';
import ConsultationForm from '../containers/Forms/ConsultationForm';

const ConsultationBuilderPage = ({ match }) => {
  const { consultationId } = match.params;
  return (
    <Fade in>
      <MainContainer>
        <ConsultationForm consultationId={ consultationId } />
      </MainContainer>
    </Fade>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

ConsultationBuilderPage.propTypes = {};

export default ConsultationBuilderPage;
