import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomCreatableSelect from '../../../../../components/CustomCreatableSelect/CustomCreatableSelect';
import { createOption } from '../../../../../util/data';
import { updateFieldInQuestion } from '../../../../../redux/actions/consultations';

class AddOptions extends Component {
  addOption = value => {
    const { data, updateFieldInQuestion } = this.props;
    const { input_options } = data;
    const option = createOption(value, 0);
    option.value = value;
    updateFieldInQuestion('input_options', input_options ? [...input_options, option] : [option]);
  };

  updateOptions = options => {
    const { data, updateFieldInQuestion } = this.props;
    const { input_options } = data;
    const ids = options ? options.map(option => option.value) : [];
    const newOptions = input_options ? input_options.filter(option => ids.includes(option.id)) : null;
    updateFieldInQuestion('input_options', newOptions);
  }

  render() {
    const { data } = this.props;
    const { input_options } = data;
    const optionLabels = input_options ? input_options.map(option => ({ label: option.value, value: option.id })) : [];
    return (
      <CustomCreatableSelect
        components={ {
          ClearIndicator: () => null,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          Menu: () => null,
        } }
        isMulti
        placeholder='Enter the options here... (press enter after each option)'
        value={ optionLabels }
        onChange={ this.updateOptions }
        onCreateOption={ this.addOption }
      />
    );
  }
}

const mapStateToProps = (state, { questionId }) => state.consultations.questions[questionId];

const mapDispatchToProps = (dispatch, { questionId }) => ({
  updateFieldInQuestion: (field, value) => dispatch(updateFieldInQuestion(questionId, field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddOptions);
