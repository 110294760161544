import React from 'react';
import { Typography } from '@material-ui/core';
import Asterisk from '../Asterisk/Asterisk';

const AsteriskIndicatesRequired = props => (
  <div style={ { marginBottom: 20 } }>
    <Typography variant="caption" gutterBottom>
      <Asterisk />
      {' '}
      <i>Indicates required information</i>
    </Typography>
  </div>
);

export default AsteriskIndicatesRequired;
