/* eslint-disable array-callback-return */
import dotProp from 'dot-prop-immutable';
import {
  SHOW_CONSULTATION,
  GET_CONSULTATIONS,
  UPDATE_FIELD_IN_CONSULTATION,
  UPDATE_FIELD_ERROR_IN_CONSULTATION,
  ADD_SECTION,
  REMOVE_SECTION,
  UPDATE_FIELD_IN_SECTION,
  UPDATE_FIELD_ERROR_IN_SECTION,
  ADD_QUESTION,
  REMOVE_QUESTION,
  UPDATE_FIELD_IN_QUESTION,
  UPDATE_FIELD_ERROR_IN_QUESTION,
  REMOVE_CONSULTATION,
  VALIDATE_ENTIRE_CONSULTATION_FORM,
  SAVE_CONSULTATION,
  CREATE_CONSULTATION,
} from '../constants/action-types';
import { fakeConsultationQuestion } from '../../util/sampleData';
import { removeItemFromArray, addToArray } from '../../util/ImmutableFunctions';

const initialState = {
  consultationQuestions: {
    [fakeConsultationQuestion.id]: fakeConsultationQuestion,
  },
  total: 100,
  current: [],
  selected: [],
  error: false,
  columnSort: {
    column: 'Title',
    ascending: true,
  },
  columnFilters: [],
  columnFilterOptions: [],
  tablePagination: {
    rowsPerPage: 10,
    currentPage: 0,
  },
  currentConsultationId: null, // SHOULD GET ID FROM URL INSTEAD
  // TEMPORARY, DEFAULT CONSULTATION DATA STRUCTURE
  consultations: null,
  // TEMPORARY, DEFAULT CONSULTATION DATA STRUCTURE
  sections: null,
  questions: null,
};

const showConsultation = (state, action) => {
  const { consultation, sections, questions } = action.payload;
  const stateWithConsultation = state.consultations
    ? dotProp.set(state, `consultations.${consultation.id}`, consultation)
    : dotProp.set(state, 'consultations', {
      [consultation.id]: consultation,
    });
  const stateWithSections = dotProp.set(stateWithConsultation, 'sections', sections);
  const stateWithQuestions = dotProp.set(stateWithSections, 'questions', questions);
  return stateWithQuestions;
};

const getConsultations = (state, action) => {
  const { payload } = action;
  return dotProp.set(state, 'consultations', payload);
};

const removeConsultation = (state, action) => {
  const { id } = action;

  return dotProp.delete(state, `consultations.${id}`);
};

const updateFieldInConsultation = (state, action) => {
  const { consultationId, field, value } = action;
  return dotProp.set(state, `consultations.${consultationId}.data.${field}`, value);
};

const updateFieldErrorInConsultation = (state, action) => {
  const { consultationId, field, error } = action;
  return dotProp.set(state, `consultations.${consultationId}.formErrors.${field}`, error);
};

const addSection = (state, action) => {
  const {
    payload, sectionId, consultationId,
  } = action;
  const stateWithSection = dotProp.set(state, `sections.${sectionId}`, payload);
  return dotProp.set(
    stateWithSection,
    `consultations.${consultationId}.section_ids`,
    addToArray(stateWithSection.consultations[consultationId].section_ids, sectionId),
  );
};

const removeSection = (state, action) => {
  const { sectionId, consultationId } = action;
  const consultationSectionIndex = state.consultations[consultationId].section_ids.indexOf(sectionId);
  return consultationSectionIndex > -1
    ? {
      ...state,
      consultations: {
        ...state.consultations,
        [consultationId]: {
          ...state.consultations[consultationId],
          section_ids: removeItemFromArray(
            state.consultations[consultationId].section_ids,
            consultationSectionIndex,
          ),
        },
      },
      sections: updateRemovedSectionOrder(sectionId, state.sections, consultationId),
    }
    : state;
};

const updateRemovedSectionOrder = (targetId, sections, consultationId) => {
  const sectionOrder = sections[targetId].order;
  delete sections[targetId];
  // update order
  Object.keys(sections).forEach(id => {
    const sect = sections[id];
    if (sect.consultation_id === consultationId && sect.order > sectionOrder) {
      console.log('old order', sect.order);
      sect.order--;
      console.log('new order', sect.order);
      console.log('updated sections', sections);
    }
  });
  return sections;
};

const updateFieldInSection = (state, action) => {
  const { sectionId, field, value } = action;
  return dotProp.set(state, `sections.${sectionId}.data.${field}`, value);
};

const updateFieldErrorInSection = (state, action) => {
  const { sectionId, field, error } = action;
  return dotProp.set(state, `sections.${sectionId}.formErrors.${field}`, error);
};

const addQuestion = (state, action) => {
  const { payload, questionId, sectionId } = action;

  return {
    ...state,
    sections: {
      ...state.sections,
      [sectionId]: {
        ...state.sections[sectionId],
        question_ids: addToArray(state.sections[sectionId].question_ids, questionId),
      },
    },
    questions: {
      ...state.questions,
      [questionId]: payload,
    },
  };
};

const removeQuestion = (state, action) => {
  const { questionId, sectionId } = action;
  const sectionQuestionIndex = state.sections[sectionId].question_ids.indexOf(questionId);
  return sectionQuestionIndex > -1
    ? {
      ...state,
      sections: {
        ...state.sections,
        [sectionId]: {
          ...state.sections[sectionId],
          question_ids: removeItemFromArray(state.sections[sectionId].question_ids, sectionQuestionIndex),
        },
      },
      questions: updateRemovedQuestionOrder(questionId, state.questions, sectionId),
    }
    : state;
};

const updateRemovedQuestionOrder = (targetId, questions, sectionId) => {
  const questionOrder = questions[targetId].order;
  delete questions[targetId];
  // update order
  Object.keys(questions).forEach(id => {
    const ques = questions[id];
    if (ques.section_id === sectionId && ques.order > questionOrder) {
      console.log('old order', ques.order);
      ques.order--;
      console.log('new order', ques.order);
      console.log('updated sections', questions);
    }
  });
  return questions;
};


const updateFieldInQuestion = (state, action) => {
  const { questionId, field, value } = action;
  return dotProp.set(state, `questions.${questionId}.data.${field}`, value);
};

const updateFieldErrorInQuestion = (state, action) => {
  const { questionId, field, error } = action;
  return dotProp.set(state, `questions.${questionId}.formErrors.${field}`, error);
};

const createConsultation = (state, action) => {
  const { id, data } = action;
  return dotProp.set(state, `consultations.${id}`, data);
};

const validateEntireConsultationForm = (state, action) => {
  const { consultationId } = action;
  // TODO
  return state;
};

const consultationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CONSULTATION:
      return showConsultation(state, action);
    case GET_CONSULTATIONS:
      return getConsultations(state, action);
    case UPDATE_FIELD_IN_CONSULTATION:
      return updateFieldInConsultation(state, action);
    case UPDATE_FIELD_ERROR_IN_CONSULTATION:
      return updateFieldErrorInConsultation(state, action);
    case ADD_SECTION:
      return addSection(state, action);
    case REMOVE_SECTION:
      return removeSection(state, action);
    case UPDATE_FIELD_IN_SECTION:
      return updateFieldInSection(state, action);
    case UPDATE_FIELD_ERROR_IN_SECTION:
      return updateFieldErrorInSection(state, action);
    case ADD_QUESTION:
      return addQuestion(state, action);
    case REMOVE_QUESTION:
      return removeQuestion(state, action);
    case UPDATE_FIELD_IN_QUESTION:
      return updateFieldInQuestion(state, action);
    case UPDATE_FIELD_ERROR_IN_QUESTION:
      return updateFieldErrorInQuestion(state, action);
    case CREATE_CONSULTATION:
      return createConsultation(state, action);
    case SAVE_CONSULTATION:
      return showConsultation(state, action);
    case REMOVE_CONSULTATION:
      return removeConsultation(state, action);
    case VALIDATE_ENTIRE_CONSULTATION_FORM:
      return validateEntireConsultationForm(state, action);
    default:
      return state;
  }
};

export default consultationReducer;
