import styled from 'styled-components';
import { green } from '@material-ui/core/colors/';

export const SubHeader = styled.p`
  font-size: 20px;
  margin-left: 25px;
  margin-bottom: 30px;
  text-decoration: underline;
`;

export const InputHeader = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin: 0;
`;

export const Header = styled.p`
  font-size: 24px;
`;

export const RequiredIndicatorText = styled.p`
  color: ${green[500]};
  font-style: italic;
  font-size: 12px;
`;

export const CenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
