import React from 'react';
import styled from 'styled-components';
import { Typography, Link } from '@material-ui/core';
import { PictureAsPdf, RemoveRedEye } from '@material-ui/icons';


// Fill the page width if public view, otherwise only fill width of preview area.
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto;
`;

const AttachmentsPanel = props => {
  const {
    preview, setAttachment, attachment,
  } = props;
  return (
    <MainContainer id='assets'>
      <Typography variant='h6' gutterBottom style={ { color: '#333333' } }>
        Supporting documents
      </Typography>
      <AttachmentItem
        { ...{
          preview, setAttachment, attachment,
        } }
        title='Exceptions and Limitations: Exceptions that facilitate particular desriable uses'
        details='PDF, 5.2 MB, 32 pages'
        href='https://arxiv.org/pdf/1708.08021.pdf'
      />
      <AttachmentItem
        { ...{
          preview, setAttachment, attachment,
        } }
        title='Rights: Specific issues with the current right'
        details='PDF, 4.8 MB, 26 pages'
        href='https://www.alejandrodelasota.org/wp-content/uploads/2013/03/demoform1.pdf'
      />
    </MainContainer>
  );
};

const AttachmentItem = props => {
  const {
    title, details, href, preview, setAttachment, attachment,
  } = props;
  // console.log('rendering info item: ' + details); // <-- Can be useful, but commented out due to console spam.
  const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;
  const onLinkClicked = () => {
    preview
      ? window.open(href, '_blank')
      : setAttachment(attachment === href ? null : href);
  };
  return (
    <div style={ {
      marginTop: 12, marginRight: 30, display: 'flex', flexDirection: 'row',
    } }
    >
      <PictureAsPdf color='primary' style={ { marginRight: 10, paddingTop: 2 } } />
      <TextContainer>
        <StyledLink
          gutterBottom
          onClick={ onLinkClicked }
        >
          {title}
        </StyledLink>
        <Typography
          variant='body1'
          gutterBottom
          style={ {
            fontSize: '10pt',
            marginTop: -8,
            fontStyle: details ? 'normal' : 'italic',
          } }
        >
          {details || 'Details placeholder'}
        </Typography>
      </TextContainer>
      {attachment === href && <RemoveRedEye style={ { marginLeft: 5 } } />}
    </div>
  );
};

const StyledLink = styled(Link)`
  font-size: 11pt;
  font-weight: 500;
  color: ${props => props.theme.palette.lightBlue.main} !important;
  cursor: pointer
`;

export default AttachmentsPanel;
