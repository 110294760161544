import React from 'react';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const styles = () => ({
  label: {
    fontWeight: 500, fontSize: '9pt', color: 'rgb(51, 51, 51)', marginRight: -6,
  },
  root: {
    marginTop: -2,
  },
  checked: {},
  switchBase: {
    marginTop: 5,
    marginLeft: 5,
  },
  thumb: {
    width: 14,
    height: 14,
    boxShadow: 'none',
  },
  track: {
    width: 36,
    height: 18,
    borderRadius: 100,
  },
});

const ThinSwitch = props => {
  const {
    classes, label, checked, onChange,
  } = props;
  return (
    <FormControlLabel
      classes={ { label: classes.label } }
      label={ label }
      labelPlacement="start"
      control={ (
        <GreenSwitch
          classes={ {
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
          } }
          checked={ checked }
          onChange={ onChange }
        />
      ) }
    />
  );
};

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: green[700],
    },
    '&$checked + $track': {
      backgroundColor: green[700],
    },
  },
  root: {},
  checked: {},
  track: {},
  thumb: {},
})(Switch);

export default withStyles(styles, { withTheme: true })(ThinSwitch);
