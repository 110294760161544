import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Badge from '@material-ui/core/Badge';

const styles = theme => ({
  groupStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  groupBadgeStyles: { padding: 2 },
  select: {
    width: '100%',
  },
});

function Autocomplete(props) {
  const { classes, theme } = props;
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption || '');
    props.onChange(selectedOption ? selectedOption.value : '');
  };

  const formatGroupLabel = data => (
    <div className={ classes.groupStyles }>
      <span>{data.label}</span>
      <Badge className={ classes.groupBadgeStyles } badgeContent={ data.options.length } max={ props.maxTotalNumber } color='primary' />
    </div>
  );

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      borderWidth: 2,
      borderColor: theme.palette.darkBlue.main,
      '&:hover': {
        backgroundColor: '#F5F5F5',
      },
      fontSize: '11pt',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: theme.palette.darkBlue.main,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: theme.palette.darkBlue.main,
      fontWeight: 500,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? theme.palette.darkBlue.main : 'white',
      color: state.isSelected ? 'white' : theme.palette.darkBlue.main,
      '&:hover': {
        backgroundColor: state.isSelected ? theme.palette.darkBlue.main : '#EEEEEE',
        cursor: 'pointer',
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: theme.palette.darkBlue.main,
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: theme.palette.darkBlue.main,
    }),
  };

  return (
    <Select
      className={ classes.select }
      placeholder={ props.placeholder }
      value={ selectedOption }
      onChange={ handleChange }
      options={ props.options }
      isClearable
      formatGroupLabel={ formatGroupLabel }
      styles={ customSelectStyles }
    />
  );
}

Autocomplete.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  maxTotalNumber: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(Autocomplete);
