import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { show as showSubmission } from '../../redux/actions/submissions';
import { show as showConsultation } from '../../redux/actions/consultations';
import { show as showSubmitter } from '../../redux/actions/submitters';
import PropTypes from 'prop-types';
import Form from './Form';
import Header from './Header';
import CircularProgress from '@material-ui/core/CircularProgress';

const ProcessSubmission = props => {
  const {
    submission,
    consultation,
    submitter,
    submissionId,
    consultationId,
    submitterId,
    showSubmission,
    showConsultation,
    showSubmitter
  } = props;

  useEffect(() => {
    if (!submission) showSubmission(submissionId);

    if (submission && !consultation) showConsultation(consultationId);

    if (submission && !submitter) showSubmitter(submitterId);
  }, []);

  if (!submission || !consultation || !submitter) {
    return (
      <div
        style={{
          height: '80vh',
          display: 'flex',
          justifyContents: 'center'
        }}
      >
        <div style={{ margin: 'auto' }}>
          <p>Please Wait..</p>
          <CircularProgress style={{ margin: 'auto', display: 'block' }} />
        </div>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Header
        builder
        submitterName={submitter.terms}
        submissionDate={submission.created_at}
        submissionSource={submission.submission_source}
        submitterType={submission.submitter_type}
        consultation={consultation}
        maxTotalNumber={99}
        header="Scroll to form item:"
        onChange={value => {}}
      />
      <div style={{ flex: 1, overflowY: 'auto' }}>
        <div
          style={{
            // width: "calc(100% - 100px)",
            margin: 'auto'
          }}
        >
          <Form consultationId={consultationId} submissionId={submissionId} />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state, { submissionId, consultationId, submitterId }) {
  return {
    submission: state.submissions.processSubmissions[submissionId],
    consultation: state.consultations.consultationQuestions[consultationId],
    submitter: state.submitters[submitterId]
  };
}

ProcessSubmission.propTypes = {
  submission: PropTypes.object,
  submissionId: PropTypes.number,
  consultation: PropTypes.object,
  submitter: PropTypes.object
};

export default connect(
  mapStateToProps,
  { showSubmission, showConsultation, showSubmitter }
)(ProcessSubmission);
