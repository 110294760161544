import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router';
import { createConsultation } from '../../redux/actions/consultations';
import BasicLoader from '../../components/BasicLoader/BasicLoader';

const styles = theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
});

function CreateConsultationRedirectPage(props) {
  const { classes, createConsultation, history } = props;
  const createConsultationMount = () => {
    createConsultation();
    // history.push(`/consultations/${consultation.id}/edit/`);
  };

  // DidMount
  useEffect(createConsultationMount, []);

  return (
    <Fade in>
      <div className={ classes.mainContainer }>
        <BasicLoader label="Generating new consultation..." />
      </div>
    </Fade>
  );
}

CreateConsultationRedirectPage.propTypes = {};

const mapDispatchToProps = (dispatch, { history }) => ({ createConsultation: () => dispatch(createConsultation(history)) });

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(withRouter(CreateConsultationRedirectPage)));
