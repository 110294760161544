import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Table from '../../components/Table/Table';
import DeleteConfirmationDialog from '../../components/Dialogs/DeleteConfirmationDialog';

// TODO: Implement this page properly. Currently a template based on the consultations table.

const columns = {
  referenceNumber: { label: 'Reference Number', filterType: 'numeric' },
};

const convertSubmissionsToRows = submissions => (
  submissions ? Object.keys(submissions).map(submissionId => {
    const submission = submissions[submissionId];
    return ({
      id: submission.id,
    });
  }) : []);

const SubmissionsTableInternal = props => {
  const {
    submissions, getSubmissions, history, removeConsultation,
  } = props;

  const [rows, setRows] = useState([]);

  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [submissionToDelete, setSubmissionToDelete] = useState(null);

  const onDeleteClicked = submissionId => {
    setDeleteConfirmationDialogOpen(true);
    setSubmissionToDelete(submissionId);
  };

  const onDeleteConfirmed = () => {
    removeConsultation(submissionToDelete);
    setDeleteConfirmationDialogOpen(false);
    setSubmissionToDelete(null);
  };

  const onCancelDelete = () => {
    setDeleteConfirmationDialogOpen(false);
    setSubmissionToDelete(null);
  };

  // useEffect(() => {
  //   getSubmissions();
  // }, [getSubmissions]);

  // useEffect(() => {
  //   setRows(convertSubmissionsToRows(submissions));
  // }, [submissions]);

  const actions = [
    // {
    //   icon: DeleteIcon,
    //   tooltipText: 'Delete Submission',
    //   onClick: submission => onDeleteClicked(submission.id),
    // },
  ];

  return (
    <MainContainer>
      <Table contextLabel='Submissions' columns={ columns } rows={ [] } actions={ actions } />
      <DeleteConfirmationDialog
        open={ deleteConfirmationDialogOpen }
        context='submission'
        onCancel={ onCancelDelete }
        onConfirm={ onDeleteConfirmed }
      />
    </MainContainer>
  );
};

const MainContainer = styled.div`
  height: 100%;
`;

const mapStateToProps = state => {
  // const submissions = selectSubmissions(state);
  // return {
  //   submissions,
  // };
};

const mapDispatchToProps = dispatch => ({
  // removeSubmission: id => dispatch(removeSubmission(id)),
  // getConsultations: () => dispatch(getSubmissions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SubmissionsTableInternal));
