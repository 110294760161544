import React from 'react';
import FormItemDecorator from '../../../Forms/FormItemDecorator';
import AddOptions from './common/AddOptions';

const DropdownConfiguration = props => {
  const { questionId } = props;
  return (
    <FormItemDecorator
      label="Add options"
      description="Add to the list of dropdown options that the submitter may choose from."
      required
      disableTrailingSpace
    >
      <AddOptions questionId={ questionId } />
    </FormItemDecorator>
  );
};

export default DropdownConfiguration;
