import React, { Component } from 'react';

// Material UI Theme
import { makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';

// App Components
import {
  Route, Switch, BrowserRouter, Redirect,
} from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import Layout from './components/Layout/Layout.jsx';
import LoginPage from './pages/LoginPage.jsx';
import ConsultationsListPage from './pages/ConsultationsListPage/ConsultationsListPage.jsx';
import SubmissionsListPage from './pages/SubmissionsListPage/SubmissionsListPage.jsx';
import ProcessSubmissionPage from './pages/ProcessSubmissionPage/ProcessSubmissionPage.jsx';
import ConsultationBuilderPage from './pages/ConsultationBuilderPage.jsx';
import CreateConsultationRedirectPage from './pages/ConsultationsListPage/CreateConsultationRedirectPage';
import PublicConsultationsListPage from './pages/PublicConsultationsListPage/PublicConsultationsListPage.jsx';
import CreateSubmissionPage from './pages/Submission/CreateSubmissionPage';
import SubmissionSuccessPage from './pages/Submission/SubmissionSuccessPage';
// React Router

// PDFjs
// import { pdfjs } from 'react-pdf';

// MaterialUI Date Picker

// Theme
import themeConfig from './ui/themes/MFE.json'; // <------------ SETS GLOBAL APPLICATION BRANDING
import buildTheme from './ui/themes/theme-builder';

// global snackbar

// import { createMuiTheme } from '@material-ui/core';
import SnackbarNotifier from './components/SnackbarNotifier/SnackbarNotifier.jsx';

const routes = [
  { path: '/login', Component: LoginPage },
  { path: '/consultations', Component: ConsultationsListPage },
  { path: '/consultations/generate', Component: CreateConsultationRedirectPage },
  { path: '/consultations/:consultationId/edit/', Component: ConsultationBuilderPage },
  { path: '/consultations/:consultation_id/submissions', Component: SubmissionsListPage },
  { path: '/consultations/:consultation_id/submissions/:submission_id/process/', Component: ProcessSubmissionPage },
  { path: '/public/consultations', Component: PublicConsultationsListPage },
  { path: '/public/consultations/:consultation_id/make-a-submission', Component: CreateSubmissionPage },
  { path: '/public/consultations/success', Component: SubmissionSuccessPage },
  // { path: '/consultations/:consultation_id/submissions/:submission_id/review/', Component: REVIEW_PAGE_COMPONENT_GOES_HERE },
];

export default () => {
  const theme = createMuiTheme(buildTheme(themeConfig));
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={ theme }>
        <ThemeProvider theme={ theme }>
          <MuiPickersUtilsProvider utils={ MomentUtils }>
            <Layout>
              <SnackbarProvider
                maxSnack={ 5 }
                anchorOrigin={ {
                  vertical: 'top',
                  horizontal: 'center',
                } }
              >
                <SnackbarNotifier />
                <Switch>
                  {routes.map(route => (
                    <Route key={ route } exact path={ route.path } component={ route.Component } />
                  ))}
                  <Redirect from='/' to='/login' />
                </Switch>
              </SnackbarProvider>
            </Layout>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </BrowserRouter>
  );
};
