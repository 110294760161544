import React, { Component } from 'react';
import styles from './ViewToggle.styles';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setProcessSubmissionViewMode } from '../../../redux/actions/submissions';

const viewToggle = props => {
  const { classes } = props;

  const toggleChanged = () => {
    let newMode = null;
    if (props.processSubmissionViewMode === 'view') {
      newMode = 'edit';
    } else if (props.processSubmissionViewMode === 'edit') {
      newMode = 'view';
    }
    props.setProcessSubmissionViewMode(newMode);
  };

  return (
    <div className={classes.mainContainer}>
      <span>View Document</span>
      <Switch color="primary" checked={props.processSubmissionViewMode === 'edit'} onChange={toggleChanged} />
      <span>Edit Document</span>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    processSubmissionViewMode: state.processSubmissionViewMode,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setProcessSubmissionViewMode: mode => dispatch(setProcessSubmissionViewMode(mode)),
  };
};

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(viewToggle)
);
