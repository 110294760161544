import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion, useAnimation } from 'framer-motion';
import styled from 'styled-components';
import yellow from '@material-ui/core/colors/yellow';

const ChangeHighlighter = props => {
  const { children, monitorSingleValue, monitorMultipleValues } = props;

  const [firstRender, setFirstRender] = useState(true);
  const [previous, setPrevious] = useState(null);

  const controls = useAnimation();
  const animation = { opacity: [0, 0.8, 0.2, 0.8, 0] };

  useEffect((() => {
    // Only perform change checks if this is not the first render.
    // Otherwise, all wrapped components would highlight on page load.
    if (!firstRender) {
      // Highlight the children if any of the multiple values to monitor have changed.
      if (monitorMultipleValues) {
        // Ensure that 'previous' has the same number of items as the new values to monitor (avoid index out of bounds).
        const previousInCorrectFormat = Array.isArray(previous) && previous.length === monitorMultipleValues.length;
        if (previousInCorrectFormat !== undefined && monitorMultipleValues.some((value, index) => value !== previous[index])) {
          controls.start(animation);
        }
      }
      // Highlight the children if the single value to monitor has changed.
      if (monitorSingleValue !== undefined && monitorSingleValue !== previous) {
        controls.start(animation);
      }
    } else {
      setFirstRender(false);
    }
    setPrevious(monitorSingleValue || monitorMultipleValues);
  }), [monitorSingleValue, monitorMultipleValues]);

  return (
    <MainContainer>
      <ChildrenContainer>
        {children}
      </ChildrenContainer>
      <FlashArea
        initial={ false }
        animate={ controls }
        transition={ {
          duration: 2,
          ease: 'easeInOut',
          times: [0, 0.15, 0.35, 0.45, 1],
        } }
      />
    </MainContainer>
  );
};

const MainContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
`;

const FlashArea = styled(motion.div)`
    grid-row-start: 1;
    grid-column-start: 1;
    opacity: 0;
    background-color: ${yellow[500]};
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    margin-top: -5px;
    margin-left: -5px;
    border-radius: 4px;
`;

const ChildrenContainer = styled.div`
    grid-row-start: 1;
    grid-column-start: 1;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

ChangeHighlighter.propTypes = {
  monitorSingleValue: PropTypes.any,
  monitorMultipleValues: PropTypes.array,
  children: PropTypes.any.isRequired,
};

export default ChangeHighlighter;
