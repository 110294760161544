import React, { Component } from 'react';
import styles from './WarningSnackbar.styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircleRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/WarningRounded';

class WarningSnackbar extends Component {
  state = {
    open: true,
  };

  render() {
    const { classes } = this.props;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.open}
        onClose={(_, reason) => {
          if (reason !== 'clickaway') {
            this.setState({ open: false });
          }
        }}
      >
        <SnackbarContent
          className={classes.snackbar}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.content}>
              <WarningIcon className={classes.warningIcon} />
              <span>
                <strong>WARNING: </strong>
                {this.props.message}
              </span>
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={() => this.setState({ open: false })}
              >
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </span>
          }
        />
      </Snackbar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(WarningSnackbar);
