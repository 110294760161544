import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import numberToWords from 'number-to-words';
import { getSection } from '../../redux/selectors/consultationSelectors';
import Question from './Question';
import ChangeHighlighter from '../ChangeHighlighter/ChangeHighlighter';

const Section = memo(props => {
  const {
    sectionId,
    index,
    classes,
    data,
    question_ids,
    values,
    handleBlur,
    handleChange,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = props;
  const { title, description } = data;

  function replaceAll(str, find, replace) {
    function escapeRegExp(str) {
      return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    }
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
  }

  const renderTitle = title => (
    <ChangeHighlighter monitorSingleValue={ title }>
      <div id={ `section-${sectionId}-field-title` }>
        <Typography style={ { color: '#333333', fontSize: 22, marginRight: 20 } } display='inline'>
          {`Section ${numberToWords.toWords(index + 1)}`}
        </Typography>
        <Typography
          style={ { fontSize: 18, fontStyle: title ? 'normal' : 'italic' } }
          display='inline'
          color={ title ? 'textPrimary' : 'textSecondary' }
        >
          {title || 'Section title placeholder'}
        </Typography>
      </div>
    </ChangeHighlighter>
  );

  const renderDescription = description => {
    /**
     * Due to a 'quirk' with react-quill, occasionally additional spacing is added
     * which results in inconsistencies. For example, when react-quill mounts, the
     * contents of the text area is 'null', however if something is typed into the
     * text area and then removed, the contents becomes '<p><br></p>'. Therefore
     * this section of code removes this to avoid unexpected spaces in the form.
    */
    const cleanedDescription = description ? replaceAll(description, '</p><p><br></p><p>', '</p><p>') : description;
    return (
      <ChangeHighlighter monitorSingleValue={ cleanedDescription }>
        <Typography
          id={ `section-${sectionId}-field-description` }
          variant='body1'
          color={ cleanedDescription && cleanedDescription !== '<p><br></p>' ? 'textPrimary' : 'textSecondary' }
          dangerouslySetInnerHTML={ {
            __html:
          cleanedDescription && cleanedDescription !== '<p><br></p>'
            ? cleanedDescription
            : '<p><em>Section description placeholder</em></p>',
          } }
        />
      </ChangeHighlighter>
    );
  };

  return (
    <div id={ `section-${sectionId}` }>
      {renderTitle(title)}
      {renderDescription(description)}
      {question_ids.map((questionId, index) => (
        <div key={ `submission-question-${questionId}` }>
          <Question
            { ...{
              questionId,
              index,
              values: values[questionId],
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              classes,
              errors,
              touched,
            } }
          />
          {index !== question_ids.length - 1 && <div style={ { height: 30 } } />}
        </div>
      ))}
    </div>
  );
});

Section.propTypes = {
  question_ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

const mapStateToProps = (state, { sectionId }) => getSection(state, sectionId);

export default connect(mapStateToProps)(Section);
