import React from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { ClipLoader } from 'react-spinners';

const BasicLoader = props => {
  const { componentAbove, theme, label } = props;
  return (
    <MainContainer>
      {componentAbove}
      <ClipLoader
        sizeUnit="px"
        size={ 60 }
        loading
        color={ theme.palette.primary.main }
      />
      {label && <Label style={ { color: theme.palette.primary.main } }>{label}</Label>}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Label = styled.span`
  margin-top: 20px;
  font-weight: 500;
  font-size: 14pt;
`;

export default withTheme(BasicLoader);
