import React from 'react';
import PropTypes from 'prop-types';
import BaseDialog from './BaseDialog';

// ===================================== DELETE CONFIRMATION DIALOG COMPONENT ==========================================
const DeleteConfirmationDialog = props => {
  const {
    context, onCancel, onConfirm, open,
  } = props;
  const title = `Are you sure you want to delete this ${context}?`;
  const message = `Deleting this ${context} will be permanent and it will not be possible to reverse this action. Please confirm this deletion is intended.`;
  const buttons = [
    {
      label: 'Cancel', variant: 'outlined', color: 'darkBlue', onClick: onCancel,
    },
    {
      label: `Yes, delete ${context}`, variant: 'contained', color: 'darkBlue', onClick: onConfirm,
    },
  ];
  return (
    <BaseDialog title={ title } message={ message } buttons={ buttons } open={ open } />
  );
};

// ======================================== DELETE CONFIRMATION PROP TYPES =============================================
DeleteConfirmationDialog.propTypes = {
  context: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DeleteConfirmationDialog;
