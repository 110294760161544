import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/MailRounded';
import KeyIcon from '@material-ui/icons/VpnKeyRounded';
import Paper from '@material-ui/core/Paper';
import BasicLoader from '../components/BasicLoader/BasicLoader';
import CustomButton from '../components/CustomButton/CustomButton';

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const theme = useTheme();

  const handleChange = fieldName => {
    console.log(fieldName);
  };

  const onSubmit = () => {
    setLoading(true);
    // The delay is for demoing purposes to show loading when logging in,
    // delete the timeout below when authentication is implemented.
    setTimeout(() => {
      setRedirectTo('/consultations');
    }, 2000);
  };


  if (redirectTo) {
    return <Redirect to={ redirectTo } />;
  }

  return (
    <Fade in>
      <MainContainer>
        <Grow in>
          <StyledCard>
            <StyledCardContent>
              <LogoImage src={ `/images/logos/${theme.colouredLogoFileName}` } />
              <Title style={ { fontFamily: 'Roboto' } }>Citizen Engagement Platform</Title>
              <div>
                <LoaderContainer style={ loading ? { visibility: 'visible' } : { visibility: 'hidden' } }>
                  <BasicLoader />
                </LoaderContainer>
                <StyledForm
                  noValidate
                  autoComplete='off'
                  style={ loading ? { opacity: 0.4 } : {} }
                >
                  <StyledTextField
                    disabled={ loading }
                    label='Email'
                    onChange={ () => handleChange('email') }
                    margin='normal'
                    variant='outlined'
                    InputProps={ {
                      startAdornment: (
                        <InputAdornment position='start'>
                          <EmailIcon color='primary' />
                        </InputAdornment>
                      ),
                    } }
                  />
                  <StyledTextField
                    disabled={ loading }
                    label='Password'
                    onChange={ () => handleChange('password') }
                    margin='normal'
                    variant='outlined'
                    type='password'
                    autoComplete='current-password'
                    InputProps={ {
                      startAdornment: (
                        <InputAdornment position='start'>
                          <KeyIcon color='primary' />
                        </InputAdornment>
                      ),
                    } }
                  />
                  <LoginButtonContainer>
                    <CustomButton
                      color='darkBlue'
                      variant='contained'
                      fixedWidth='100%'
                      disabled={ loading }
                      label='Login'
                      onClick={ onSubmit }
                    />
                  </LoginButtonContainer>
                </StyledForm>
              </div>
            </StyledCardContent>
          </StyledCard>
        </Grow>
        <DatacomBadge>
          Powered by
          {' '}
          <DatacomLogoImage src='/images/logos/datacom.png' />
        </DatacomBadge>
      </MainContainer>
    </Fade>
  );
};

const LoginButtonContainer = styled.div`
  margin-top: 20px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.darkBlue.main};
`;

const StyledCard = styled(Card)`
  width: 400px;
  border-radius: 20px !important;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px !important;
  padding-bottom: 40px !important;
`;

const LogoImage = styled.img`
  max-height: 80px;
  max-width: 300px;
`;

const StyledForm = styled.form`
  margin-bottom: 20px;
`;
const LoaderContainer = styled.div`
  width: 100%;
  height: 40%;
  position: absolute;
  left: 0;
`;
const StyledTextField = styled(TextField)`
  width: 100%;
`;
const Title = styled.h3`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: 30px;
`;
const DatacomBadge = styled(Paper)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 20px 0 0 0 !important;
  padding-left: 18px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 10pt;
`;

const DatacomLogoImage = styled.img`
  padding-left: 8px;
  height: 20px;
`;

export default LoginPage;
