import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core/';
import CustomButton from '../CustomButton/CustomButton';

// ============================================ BASE DIALOG COMPONENT ==================================================
const BaseDialog = props => {
  const {
    title, message, open, buttons,
  } = props;
  return (
    <Dialog open={ open } PaperProps={ { style: { borderRadius: 10 } } }>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <StyledDialogActions>
        {buttons.map(button => (
          <CustomButton
            key={ button.label }
            label={ button.label }
            icon={ button.icon }
            color={ button.color }
            variant={ button.variant }
            onClick={ button.onClick }
          />
        ))}
      </StyledDialogActions>
    </Dialog>
  );
};

// ============================================== BASE DIALOG STYLING ==================================================
const StyledDialogActions = styled(DialogActions)`
    padding: 15px !important;
    padding-top: 0px !important;
`;

// ============================================ BASE DIALOG PROP TYPES =================================================
BaseDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.elementType,
      variant: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default BaseDialog;
