/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import Submission from '../Submission/Submission';

function Preview(props) {
  const { consultationId } = props;

  return <Submission consultationId={ consultationId } preview routeSuccess={ () => {} } />;
}

Preview.propTypes = {
  consultationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const mapStateToProps = (state, { consultationId }) => {
  const consultation = state.consultations.consultations[consultationId];
  const sections = {};
  const questions = {};
  consultation.sectionIds.map(sectionId => {
    const section = state.consultations.sections[sectionId];
    sections[sectionId] = section;
    section.questionIds.map(questionId => {
      questions[questionId] = state.consultations.questions[questionId];
    });
  });
  return {
    consultation,
    sections,
    questions,
  };
};

export default Preview;
