import React, { memo } from 'react';
import MUIIconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { omit } from 'lodash';
import { useTheme } from '@material-ui/core/styles';

const IconButton = memo(props => {
  const {
    icon, size, color, tooltipText,
  } = props;
  const theme = useTheme();
  const Icon = icon;
  let content = (
    <Icon style={ { color: color ? theme.palette[color].main : 'default', fontSize: '18pt', margin: theme.iconButtonSizeMap[size] } } />
  );
  content = tooltipText ? (<Tooltip title={ tooltipText } aria-label={ tooltipText }>{content}</Tooltip>) : content;
  return (
    <MUIIconButton
      { ...omit(props, ['tooltipText', 'color']) } // Exclude tooltipText from transferred props.
      size='small'
      role='button'
    >
      {content}
    </MUIIconButton>
  );
});

IconButton.defaultProps = {
  color: null,
  tooltipText: null,
};

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.elementType.isRequired,
  size: PropTypes.string.isRequired,
  color: PropTypes.string,
  tooltipText: PropTypes.string,
};

export default IconButton;
