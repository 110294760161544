import React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';
import Asterisk from '../../components/Common/Asterisk/Asterisk';
import { ENABLE_OPTIONAL_FIELD_LABELS } from '../../UserTestingFlags';

const styles = theme => ({
  label: {
    fontWeight: 500, fontSize: '11pt', color: 'rgb(51, 51, 51)',
  },
  required: { fontWeight: 400, fontSize: 18, color: 'rgb(47, 128, 237)' },
  description: {
    fontWeight: 400, fontSize: 13, fontStyle: 'italic', marginTop: '-30px',
  },
  optionalLabel: {
    fontWeight: 500, fontSize: '9pt', color: 'rgb(51, 51, 51)', marginLeft: 6,
  },
});

function FormItemDecorator(props) {
  const { classes, disableTrailingSpace } = props;

  let label = (
    <Typography className={ classes.label } display="inline">
      {props.required && !ENABLE_OPTIONAL_FIELD_LABELS && (
        <span>
          <Asterisk />
          {' '}
        </span>
      )}
      {props.label}
      {!props.required && ENABLE_OPTIONAL_FIELD_LABELS && (
        <span className={ classes.optionalLabel }>
          {' '}
          (Optional)
        </span>
      )}
    </Typography>

  );

  // Conditionally apply the tooltip if a description prop exists.
  label = props.description ? <Tooltip title={ props.description }>{label}</Tooltip> : label;

  return (
    <div id={ props.id } style={ { marginBottom: disableTrailingSpace ? 0 : 25 } }>
      <TopRow>
        {label}
        {props.labelSuffixComponent}
        {props.error && (
          <InvalidIndicator key={ props.error }>
            Invalid:
            {' '}
            {props.error}
          </InvalidIndicator>
        )}
        {props.invalid && (
          <InvalidIndicator>
            Invalid:
            {' '}
            {props.invalidReason}
          </InvalidIndicator>
        )}

      </TopRow>
      {props.children}
    </div>
  );
}

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
  max-height: 24px;
  height: 24px;
`;

const InvalidIndicator = styled.div`
  background-color: #f44336;
  border-radius: 4px;
  font-size: 9pt;
  font-weight: 600;
  color: white;
  padding: 2px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 10px;
  margin-top: 3px;
  align-self: flex-start;
`;

FormItemDecorator.propTypes = {};

export default withStyles(styles, { withTheme: true })(FormItemDecorator);
