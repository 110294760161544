import React, { Component } from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { DateTimePicker } from '@material-ui/pickers';

class DatePickerField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateWhileBeingChanged: props.date,
    };
  }

  onAccept = () => {
    const { onDateCommitted, date } = this.props;
    const { dateWhileBeingChanged } = this.state;
    onDateCommitted(dateWhileBeingChanged || date);
    this.setState({ dateWhileBeingChanged: null });
  };

  // onChange is required by the DateTimePicker, but for performance, only onAccept is needed for the field element.
  // This function allows the requirement to be satisfied without any effects.
  doNothing = () => { };

  onDatePickerChanged = value => this.setState({ dateWhileBeingChanged: value });

  render() {
    const {
      error,
      label,
      enableFuture,
      inputVariant,
      style,
      date,
      inputRef,
    } = this.props;
    const { dateWhileBeingChanged } = this.state;

    const baseConfig = {
      error,
      label,
      disableFuture: !enableFuture,
      format: 'ddd, MMM Do, YYYY, LT',
      margin: 'dense',
      inputVariant: inputVariant || 'outlined',
      inputProps: { style: { cursor: 'pointer' } },
      style: { ...style },
    };

    const DialogTitle = styled.div`
      padding-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18pt;
      font-weight: 600;
      color: white;
      background-color: ${this.props.theme.palette.primary.main};
    `;

    // In order to add a title to the date picker dialog, a workaround involving using two DateTimePicker components was required.
    // This involves using one DateTimePicker for the inline field component within the form, and another as the static component within the dialog.
    return (
      <DateTimePicker
        { ...baseConfig }
        value={ date }
        onChange={ this.doNothing }
        onAccept={ this.onAccept }
        InputProps={ {
          ref: inputRef || null,
          style: { fontSize: '11pt' },
        } }
        DialogProps={ {
          children: [
            <DialogTitle key='date-picker-dialog-title'>{label}</DialogTitle>,
            <DateTimePicker
              { ...baseConfig }
              value={ dateWhileBeingChanged || date }
              key='date-picker-dialog-content'
              variant='static'
              onChange={ this.onDatePickerChanged }
            />,
          ],
        } }
      />
    );
  }
}

export default withTheme(DatePickerField);
