import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import MaterialPopover from '@material-ui/core/Popover';
import Color from 'color';


// =========================================== CUSTOM BUTTON COMPONENT =================================================
const CustomButton = ({
  label, color, variant, route, icon, iconPosition, popover, fixedWidth, disabled, onClick, style, extraLarge,
}) => {
  const history = useHistory();

  const Icon = icon;

  // The anchor element defines where the popover is positioned on the page.
  const [anchorElement, setAnchorElement] = useState(null);

  // If popover content was supplied, update the anchor element for the popover upon button click before invoking the supplied onClick function.
  // Otherwise, just invoke the supplied onClick function as per usual.
  const onButtonClick = event => {
    if (popover) {
      setAnchorElement(event.currentTarget);
    }
    if (route) {
      history.push(route);
    }
    if (onClick) {
      onClick();
    }
  };

  // Clear the anchor element upon closing the popover.
  const onPopoverClose = () => setAnchorElement(null);

  // Only show the popover if an anchor element has been set.
  const showPopover = Boolean(anchorElement);

  const renderIcon = () => (Icon ? <Icon fontSize='small' style={ (iconPosition === 'left' ? { paddingRight: 6 } : { paddingLeft: 6 }) } /> : null);

  // Create the button itself.
  let button = (
    <StyledButton
      size='medium'
      style={ fixedWidth ? { ...style, width: fixedWidth, minWidth: fixedWidth } : style }
      customcolor={ color }
      disabled={ disabled }
      variant={ variant }
      onClick={ onButtonClick }
    >
      {iconPosition === 'left' && renderIcon()}
      {<ButtonText extraLarge={ extraLarge }>{label}</ButtonText>}
      {iconPosition === 'right' && renderIcon()}
    </StyledButton>
  );

  // Add a popover to the button if popover content was supplied.
  button = popover ? (
    <>
      {button}
      <Popover content={ popover } open={ showPopover } anchorEl={ anchorElement } onClose={ onPopoverClose } />
    </>
  ) : button;

  return button;
};
export default CustomButton;

// ============================================= CUSTOM BUTTON STYLING =================================================
const StyledButton = styled(Button)`
  &.MuiButton-root {
    box-shadow: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-family: Roboto;
    white-space: nowrap;
    text-transform: none;
    padding-left: 25px;
    padding-right: 25px;
  }
  &.MuiButton-root:hover {
    box-shadow: none;
  }
  &.MuiButton-text {
    background-color: transparent;
    color: ${props => props.theme.palette[props.customcolor].main};
    border-style: none;
  }
  &.MuiButton-text:hover {
    background-color: ${props => props.theme.palette[props.customcolor].faded};
    color: ${props => props.theme.palette[props.customcolor].main};
    border-style: none;
  }
  &.MuiButton-outlined {
    background-color: transparent;
    border-color: ${props => props.theme.palette[props.customcolor].main};
    color: ${props => props.theme.palette[props.customcolor].main};
    border-style: solid;
    border-width: 2px;
  }
  &.MuiButton-outlined:hover {
    background-color: ${props => props.theme.palette[props.customcolor].faded};
    border-color: ${props => props.theme.palette[props.customcolor].main};
    color: ${props => props.theme.palette[props.customcolor].main};
    border-style: solid;
  }
  &.MuiButton-contained {
    background-color: ${props => props.theme.palette[props.customcolor].main};
    color: ${props => props.theme.palette[props.customcolor].contrastText};
    border-style: none;
  }
  &.MuiButton-contained:hover {
    background-color: ${props => Color(props.theme.palette[props.customcolor].main).lighten(0.2).toString()};
    color: ${props => props.theme.palette[props.customcolor].contrastText};
    border-style: none;
  }
`;

const ButtonText = styled.span`
  font-size: ${props => (props.extraLarge ? '15pt' : '10pt')};
  font-weight: ${props => (props.extraLarge ? '600' : 'default')};
`;

// =========================================== CUSTOM BUTTON PROP TYPES ================================================
CustomButton.defaultProps = {
  route: null,
  icon: null,
  iconPosition: 'left',
  fixedWidth: null,
  disabled: false,
  onClick: null,
  style: {},
  extraLarge: false,
};

CustomButton.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['red', 'green', 'darkBlue', 'lightBlue']).isRequired,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']).isRequired,
  route: PropTypes.string,
  icon: PropTypes.elementType,
  iconPosition: PropTypes.oneOf(['left', 'right']).isRequired,
  popover: PropTypes.node,
  fixedWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  extraLarge: PropTypes.bool,
};

// ============================================== POPOVER COMPONENT ====================================================
const Popover = ({
  content, open, anchorEl, onClose,
}) => (
  <MaterialPopover
    open={ open }
    anchorEl={ anchorEl }
    onClose={ onClose }
    anchorOrigin={ {
      vertical: 'bottom',
      horizontal: 'center',
    } }
    transformOrigin={ {
      vertical: 'top',
      horizontal: 'center',
    } }
    PaperProps={ { style: { borderRadius: 15 } } }
  >
    {content}

  </MaterialPopover>
);

// ============================================= POPOVER PROP TYPES ====================================================
Popover.defaultProps = {
  anchorEl: null,
};

Popover.propTypes = {
  content: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.instanceOf(Element),
  onClose: PropTypes.func.isRequired,
};
