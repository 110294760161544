import React, { Component } from 'react';
import styles from './ProcessSubmissionPage.styles';
import { withStyles } from '@material-ui/core/styles';
import PageTitle from '../../components/PageTitle/PageTitle.jsx';
import WarningSnackbar from '../../components/Common/WarningSnackbar/WarningSnackbar.jsx';
import ProcessSubmissionPageContent from './ProcessSubmissionPageContent/ProcessSubmissionPageContent.jsx';
import ViewToggle from './ViewToggle/ViewToggle.jsx';

// const inProgress = false;

class ProcessSubmissionPage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.mainContainer}>
        {/* {inProgress ? (
          <WarningSnackbar message="This submission is currently being processed by Lowri Jones. By continuing, you may overwrite their changes." />
        ) : null}
        <div className={classes.headerArea}>
          <PageTitle
            primaryText="PROCESSING SUBMISSION:"
            secondaryText=" Individual submission created 1st Jan 2019 by John Doe via web form."
          />
          <ViewToggle />
        </div> */}
        <div className={classes.content}>
          <ProcessSubmissionPageContent id={1} />
        </div>
      </div>
    );
  }
}

// { inProgress ? <WarningSnackbar message="This submission is currently being processed by Lowri Jones. By continuing, you may overwrite their changes." /> : null}

export default withStyles(styles, { withTheme: true })(ProcessSubmissionPage);
