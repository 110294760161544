import React from 'react';
import styled from 'styled-components';
import PageTitle from '../../components/PageTitle/PageTitle';
import ConsultationsTablePublic from './ConsultationsTablePublic';

const PublicConsultationsListPage = () => (
  <MainContainer>
    <HeaderArea>
      <PageTitle primaryText='Open Consultations' />
    </HeaderArea>
    <ContentContainer>
      <ConsultationsTablePublic />
    </ContentContainer>
  </MainContainer>
);

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const HeaderArea = styled.div`
  width: calc(100% - 25px);
  padding-left: 25px;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ContentContainer = styled.div`
  height: calc(100% - 70px);
`;

export default PublicConsultationsListPage;
