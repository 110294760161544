import { createSubmission } from '../../util/data';
import { getSectionsFromConsultations, getQuestionsFromConsultations } from '../selectors/consultationSelectors';
import { UPDATE_SUBMISSION_DATA } from '../constants/action-types';
import store from '../store';

export const updateSubmissionData = (id, dismount) => dispatch => {
  return dispatch({
    type: UPDATE_SUBMISSION_DATA,
    payload: !dismount
      ? createSubmission(
          id,
          getSectionsFromConsultations(store.getState(), id),
          getQuestionsFromConsultations(store.getState(), id),
        )
      : null,
  });
};
