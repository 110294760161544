import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const NoContentMessage = props => {
  const { context } = props;

  if (context !== 'section' && context !== 'question') {
    return null;
  }

  return (
    <div style={ {
      margin: 20, marginBottom: 20, textAlign: 'center', color: 'rgb(51, 51, 51)',
    } }
    >
      <Typography style={ { fontWeight: 500, fontSize: 14 } }>
        {`This ${context === 'question' ? 'section' : 'consultation'} currently has no ${context}s.`}
      </Typography>
      <Typography style={ { fontWeight: 500, fontSize: 14 } }>
        {`Click the button below to add the first ${context}.`}
      </Typography>
    </div>
  );
};

NoContentMessage.propTypes = {
  context: PropTypes.string.isRequired,
};

export default NoContentMessage;
