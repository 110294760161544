import {
  grey, red, green,
} from '@material-ui/core/colors';
import Color from 'color';

const RED = {
  main: red[600],
  lighter: red[400],
  darker: red[700],
  faded: Color(red[400]).fade(0.9).toString(),
  contrastText: '#FFFFFF',
};

const GREEN = {
  main: green[600],
  lighter: green[400],
  darker: green[700],
  faded: Color(green[600]).fade(0.9).toString(),
  contrastText: '#FFFFFF',
};

const LIGHT_BLUE = {
  main: '#42a5f5',
  lighter: Color('#42a5f5').lighten(0.2).toString(),
  darker: Color('#42a5f5').darken(0.2).toString(),
  faded: Color('#42a5f5').fade(0.9).toString(),
  contrastText: '#FFFFFF',
};

const DARK_BLUE = {
  main: '#0D2142',
  lighter: '#15366a',
  darker: '#040b15',
  faded: Color('#0D2142').fade(0.9).toString(),
  contrastText: '#FFFFFF',
};

const buildTheme = themeConfig => {
  const theme = {
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: '#2F80ED',
          '&$error': {
            color: '#2F80ED',
          },
        },
      },
      MUIDataTable: {
        root: {},
        paper: {
          boxShadow: 'none',
        },
      },
      MUIDataTableHeadCell: {
        root: {
          fontSize: 18,
          color: DARK_BLUE.main,
        },
      },
    },
    themeName: themeConfig.themeName,
    logoFileName: themeConfig.logoFileName,
    colouredLogoFileName: themeConfig.colouredLogoFileName,
    logoHeight: themeConfig.logoHeight,
    palette: {
      primary: DARK_BLUE,
      secondary: DARK_BLUE,
      red: RED,
      green: GREEN,
      darkBlue: DARK_BLUE,
      lightBlue: LIGHT_BLUE,
      text: {
        primary: '#000000',
        secondary: grey[700],
      },
    },
    typography: {
      useNextVariants: true,
    },
    iconButtonSizeMap: {
      small: 4,
      medium: 8,
      large: 12,
    },
  };
  return theme;
};

export default buildTheme;
