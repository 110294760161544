import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, TextField } from '@material-ui/core';

function TabContainer(props) {
  return (
    <div style={{ height: 'auto' }}>
      <div style={{ padding: 24 }}>{props.children}</div>
    </div>
  );
}

function TabsBar(props) {
  const [adminNotes, setAdminNotes] = useState(props.adminNotes === undefined ? '' : props.adminNotes);
  const [submitterNotes, setSubmitterNotes] = useState(props.submitterNotes === undefined ? '' : props.submitterNotes);
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState(expanded ? (submitterNotes != '' ? 0 : 1) : null);

  const { classes } = props;
  const submitterHeading = "Submitter's Notes";
  const adminHeading = "Admin's Notes";

  const handleChange = (event, val) => {
    if (expanded === false) {
      setExpanded(true);
      setValue(val);
    } else if (expanded === true) {
      if (val === value) {
        setExpanded(false);
        setValue(null);
      } else setValue(val);
    }
  };
  const getTabLabel = name => {
    if (name === submitterHeading) {
      return submitterNotes === '' ? submitterHeading : submitterHeading.concat(' (1)');
    }
    return adminNotes === '' ? adminHeading : adminHeading.concat(' (1)');
  };

  const checkModified = (tabProps, newSubmitterNote, newAdminNote) => {
    if (tabProps.adminNotes === undefined && newAdminNote != '') {
      tabProps.modify(true);
      return;
    } else if (tabProps.adminNotes !== undefined && tabProps.adminNotes != newAdminNote) {
      tabProps.modify(true);
      return;
    }
    if (tabProps.submitterNotes === undefined && newSubmitterNote != '') {
      tabProps.modify(true);
      return;
    } else if (tabProps.submitterNotes !== undefined && tabProps.submitterNotes != newSubmitterNote) {
      tabProps.modify(true);
      return;
    }
    tabProps.modify(false);
  };

  return (
    <div>
      <Tabs value={value} onChange={handleChange} centered>
        <Tab classes={{ labelIcon: classes.fontSize }} label={getTabLabel(submitterHeading)} />
        <Tab classes={{ labelIcon: classes.fontSize }} label={getTabLabel(adminHeading)} />
      </Tabs>
      {expanded && value === 0 && (
        <TabContainer>
          <TextField
            InputProps={{
              className: classes.fontSize,
            }}
            placeholder='Insert Text'
            aria-label='Insert Text'
            multiline
            fullWidth={true}
            value={submitterNotes}
            type='text'
            rows={6}
            rowsMax={6}
            onChange={event => {
              setSubmitterNotes(event.target.value);
              checkModified(props, event.target.value, adminNotes);
            }}
          />
        </TabContainer>
      )}
      {expanded && value === 1 && (
        <TabContainer>
          <TextField
            InputProps={{
              className: classes.fontSize,
            }}
            placeholder='Insert Text'
            aria-label='Insert Text'
            multiline
            fullWidth={true}
            value={adminNotes}
            type='text'
            rows={6}
            rowsMax={6}
            onChange={event => {
              setAdminNotes(event.target.value);
              checkModified(props, submitterNotes, event.target.value);
            }}
          />
        </TabContainer>
      )}
    </div>
  );
}

TabsBar.propTypes = {
  adminNotes: PropTypes.string,
  submitterNotes: PropTypes.string,
  modify: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles, { withTheme: true })(TabsBar);
