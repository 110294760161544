import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Fade } from '@material-ui/core';
import { connect } from 'react-redux';
import Submission from '../../containers/Submission/Submission';
import BasicLoader from '../../components/BasicLoader/BasicLoader';
import { fetchConsultation } from '../../redux/actions/consultations';

const CreateSubmissionPage = ({
  classes, getConsultation, match, history,
}) => {
  const [initialised, setInitialised] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const routeSuccess = () => {
    history.push('/public/consultations/success');
  };

  useEffect(() => {
    getConsultation(setInitialised);
  }, []);

  if (!initialised) return <BasicLoader label='Loading submission form...' />;

  return (
    <Fade in>
      <MainContainer>
        <SubmissionContainer>
          <Submission { ...{ routeSuccess, attachment, setAttachment } } consultationId={ match.params.consultation_id } />
        </SubmissionContainer>
        {attachment && (
          <SubmissionContainer>
            <Iframe { ...{ attachment } } />
          </SubmissionContainer>
        )}
      </MainContainer>
    </Fade>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const SubmissionContainer = styled.div`
  flex: 1;
`;

const Iframe = ({ attachment }) => (
  <iframe title='supporting documents' src={ attachment } style={ { width: '100%', height: '100%', border: 'none' } } />
);

CreateSubmissionPage.propTypes = {};
const mapDispatchToProps = (dispatch, { id, match }) => ({
  getConsultation: setInitialised => dispatch(fetchConsultation(match.params.consultation_id, setInitialised)),
});
export default connect(null, mapDispatchToProps)(CreateSubmissionPage);
