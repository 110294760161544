import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import moment from 'moment';
import { Typography, InputAdornment, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import uuid from 'uuid';
import Paper from '@material-ui/core/Paper';
import CustomCreatableSelect from '../../components/CustomCreatableSelect/CustomCreatableSelect';
import Dropzone from '../../components/Dropzone/Dropzone';
import QuillFormItem from './QuillFormItem.jsx';
import FormBuilder from '../FormBuilder/FormBuilder';
import { updateFieldInConsultation } from '../../redux/actions/consultations';
import FormItemDecorator from './FormItemDecorator';
import { addToArray } from '../../util/ImmutableFunctions';
import { getConsultation } from '../../redux/selectors/consultationSelectors.js';
import { selectSubmitterTypes } from '../../redux/selectors/organisationSelectors.js';

import DatePickerField from '../../components/DatePickerField/DatePickerField.jsx';
import AsteriskIndicatesRequired from '../../components/Common/AsteriskIndicatesRequired/AsteriskIndicatesRequired';
import MemoTextField from '../../components/Common/MemoTextField/MemoTextField';
import { ENABLE_OPTIONAL_FIELD_LABELS } from '../../UserTestingFlags';

const styles = theme => ({
  container: {
    paddingBottom: theme.spacing(4), marginTop: 0, paddingLeft: 20, paddingRight: 20,
  },
  mainContainer: {
    width: '100%', height: '100%', overflowY: 'auto',
  },
  title: {
    color: '#333333',
    fontWeight: 600,
    fontSize: '18pt',
    marginBottom: 10,
  },
  innerContainer: {
    marginTop: 10, marginLeft: 10, marginRight: 10, padding: 20,
  },
  contactDetailsGroup: { display: 'flex', flexDirection: 'row' },
  contactDetailsGroupContent: { flex: 1, display: 'flex', flexDirection: 'column' },
  controlGroup: { width: '100%' },
  checkboxLabel: { fontSize: 14, fontWeight: 400 },
  expansionPanelSummary: { height: 60 },
});

function ConsultationBuilder(props) {
  // Create a reference to the close date picker so that it can be programatically opened.
  const closeDatePickerRef = React.createRef();

  const {
    consultationId, data, formErrors, updateFieldInConsultation, classes, submitterTypes,
  } = props;

  const [expanded, setExpanded] = useState({
    consultationDetails: true,
    submitterDetails: true,
    sections: true,
  });

  const checkConsultationStatusAndUpdateField = (field, value, data) => {
    // Will also need another paramter - isConsultationValid to ensure the rest of the consultation is valid at the time this status is checked.
    const current_date = moment();
    const open_date = field == 'consultation_dates.open' ? value : data.consultation_dates.open;
    const close_date = field == 'consultation_dates.close' ? value : data.consultation_dates.close;
    const isValid = open_date != null && close_date != null; // Update this once Leyton has finished consultation validation
    const grace_period = field == 'grace_period' ? value : data.grace_period;
    // let close_date_and_grace_period = field == 'grace_period' ? value : data.grace_period;

    if (!isValid) {
      // DRAFT: Consultation Dates are invalid
      updateFieldInConsultation('status', 'Draft');
    } else if (open_date > current_date) {
      // PENDING: Valid but start date is in the future
      updateFieldInConsultation('status', 'Pending');
    } else if (open_date < current_date && current_date < close_date) {
      // ACTIVE: Consultation is live
      updateFieldInConsultation('status', 'Active');
    } else if (current_date > close_date && current_date < close_date.clone().add(grace_period, 'days')) {
      // SubmittAble: Current
      updateFieldInConsultation('status', 'Closed - Submittable');
    } else if (current_date > close_date) {
      // CLOSED: Current
      updateFieldInConsultation('status', 'Closed');
    }
    // else if (archived) {
    // ARCHIVED: Once all reporting is done, Admin user will set consultation to this status.
    //   updateFieldInConsultation('status', 'Archived');
    // }

    updateFieldInConsultation(field, value);
  };

  const onExpansionPanelClicked = key => {
    setExpanded(oldExpanded => ({
      ...oldExpanded,
      [key]: !oldExpanded[key],
    }));
  };

  return (
    <div className={ classes.mainContainer } id='consultation-builder-scroll-area'>
      <div className={ classes.innerContainer }>
        <Typography className={ classes.title }>
          Consultation Details
        </Typography>
        { !ENABLE_OPTIONAL_FIELD_LABELS && <AsteriskIndicatesRequired /> }
        <FormItemDecorator
          label='Title'
          description='The name of the consultation.'
          error={ formErrors.title }
          required
          id='title-builder'
        >
          <MemoTextField
            required
            multiline
            initialValue={ data.title }
            onDoneEditing={ value => {
              updateFieldInConsultation('title', value);
            } }
            rowsMax={ 3 }
            placeholder='Enter the title of the consultation here...'
          />
        </FormItemDecorator>
        <FormItemDecorator
          label='Description'
          description='A short explanation about what the consultation entails.'
          error={ formErrors.description }
          required
          id='description-builder'
        >
          <QuillFormItem
            initialValue={ data.description }
            onDoneEditing={ value => {
              updateFieldInConsultation('description', value);
            } }
            placeholder='Enter a description of the consultation here...'
          />
        </FormItemDecorator>
        <FormItemDecorator
          label='Privacy statement'
          description='Information to display to the submitters regarding the disclosure of their data collected through the consultation.'
          error={ formErrors.privacy_statement }
          required
          id='privacy-builder'
        >
          <QuillFormItem
            initialValue={ data.privacy_statement }
            onDoneEditing={ value => {
              updateFieldInConsultation('privacy_statement', value);
            } }
            placeholder='Enter the privacy statement here...'
          />
        </FormItemDecorator>
        <FormItemDecorator
          label='Submission period'
          description='The timeframe defining the duration of the consultation.'
          error={
            formErrors.consultation_dates
              ? formErrors.consultation_dates.open || formErrors.consultation_dates.close
              : null
          }
          required
          id='submission-period-builder'
        >
          <div style={ { display: 'flex', flexDirection: 'row', marginTop: 10 } }>
            <DatePickerField
              label='Opening date'
              date={ data.consultation_dates.open }
              onDateCommitted={ value => {
                updateFieldInConsultation('consultation_dates.open', value);
                // Automatically open the 'close date' picker.
                setTimeout(e => e.click(), 800, closeDatePickerRef.current);
              } }
              enableFuture
              style={ { width: '100%', marginTop: 0, marginRight: 50 } }
            />
            <DatePickerField
              inputRef={ closeDatePickerRef }
              label='Closing date'
              date={ data.consultation_dates.close }
              onDateCommitted={ value => updateFieldInConsultation('consultation_dates.close', value) }
              enableFuture
              name='closeDate'
              style={ { width: '100%', marginTop: 0 } }
            />
          </div>
        </FormItemDecorator>
        <FormItemDecorator
          label='Grace period'
          description='Time in which submitters who have created draft submissions are able to complete their submission after consultation closes.'
          id='grace-builder'
        >
          <TextField
            style={ { marginTop: 0 } }
            type='number'
            variant='outlined'
            margin='dense'
            inputProps={ { min: 0, max: 7 } }
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={ { endAdornment: <InputAdornment position='end'>days</InputAdornment> } }
            value={ data.grace_period }
            onChange={ event => {
              if (event.target.value >= 0 && event.target.value <= 7) { updateFieldInConsultation('grace_period', event.target.value); }
            } }
          />
        </FormItemDecorator>
        <FormItemDecorator
          label='Original receiver email'
          description='This is the official email address that emailed submissions should be sent or forwarded to for processing for this consultation. Note: For the application to receive these email submissions, please create a redirect (not forward) rule to @demo.datacomcep.co.nz on this official email address.'
          error={ formErrors.emails ? formErrors.emails.original_receiver : null }
          required
          id='original-email-builder'
        >
          <MemoTextField
            required
            autoComplete='email'
            initialValue={ data.emails.original_receiver }
            onDoneEditing={ value => updateFieldInConsultation('emails.original_receiver', value) }
            placeholder='Enter the original receiver email here...'
          />
        </FormItemDecorator>
        <FormItemDecorator
          label='Notification receiver email'
          description='This is the email address that notifications about rejected emails will be sent to.'
          error={ formErrors.emails ? formErrors.emails.notification : null }
          required
          id='notification-email-builder'
        >
          <MemoTextField
            autoComplete='email'
            initialValue={ data.emails.notification }
            onDoneEditing={ value => updateFieldInConsultation('emails.notification', value) }
            placeholder='Enter the notification receiver email here...'
          />
        </FormItemDecorator>
        <FormItemDecorator
          label='Supporting documents'
          description='Additional documents of relevance to the consultation that submitters may need in order to make a submission.'
          // todo error
          // error={formErrors.assets}
          required
          id='assets'
        >
          <Dropzone
            onChange={ acceptedFiles => {
              updateFieldInConsultation('assets.supporting_documents', acceptedFiles);
            } }
            files={ data.assets.supporting_documents }
          />
        </FormItemDecorator>
      </div>
      <div className={ classes.innerContainer }>
        <Typography className={ classes.title } style={ { marginBottom: 20 } }>
          Submitter Details
        </Typography>
        <FormItemDecorator
          label='Required submitter contact details'
          description='Selected fields will require submitters to provide this information in order to complete a submission.'
          id='required-contact-details-builder'
        >
          <FormControl className={ classes.controlGroup }>
            <FormGroup className={ classes.contactDetailsGroup }>
              <div className={ classes.contactDetailsGroupContent }>
                <FormControlLabel
                  classes={ {
                    label: classes.checkboxLabel,
                  } }
                  control={ (
                    <Checkbox
                      checked={ data.required_contact_fields.given_or_company_name }
                      onChange={ event => {
                        updateFieldInConsultation(
                          'required_contact_fields.given_or_company_name',
                          event.target.checked,
                        );
                      } }
                      value='name'
                    />
                  ) }
                  label='Given name(s) or company name'
                />
                <FormControlLabel
                  classes={ {
                    label: classes.checkboxLabel,
                  } }
                  control={ (
                    <Checkbox
                      checked={ data.required_contact_fields.surname }
                      onChange={ event => {
                        updateFieldInConsultation('required_contact_fields.surname', event.target.checked);
                      } }
                      value='surname'
                    />
                  ) }
                  label='Surname'
                />
                <FormControlLabel
                  classes={ {
                    label: classes.checkboxLabel,
                  } }
                  control={ (
                    <Checkbox
                      checked={ data.required_contact_fields.address }
                      onChange={ event => {
                        updateFieldInConsultation('required_contact_fields.address', event.target.checked);
                      } }
                      value='address'
                    />
                  ) }
                  label='Address'
                />
                <FormControlLabel
                  classes={ {
                    label: classes.checkboxLabel,
                  } }
                  control={ (
                    <Checkbox
                      checked={ data.required_contact_fields.region }
                      onChange={ event => {
                        updateFieldInConsultation('required_contact_fields.region', event.target.checked);
                      } }
                      value='region'
                    />
                  ) }
                  label='Region'
                />
              </div>
              <div className={ classes.contactDetailsGroupContent }>
                <FormControlLabel
                  classes={ {
                    label: classes.checkboxLabel,
                  } }
                  control={ (
                    <Checkbox
                      checked={ data.required_contact_fields.country }
                      onChange={ event => {
                        updateFieldInConsultation('required_contact_fields.country', event.target.checked);
                      } }
                      value='country'
                    />
                  ) }
                  label='Country'
                />
                <FormControlLabel
                  classes={ {
                    label: classes.checkboxLabel,
                  } }
                  control={ (
                    <Checkbox
                      checked={ data.required_contact_fields.phone }
                      onChange={ event => {
                        updateFieldInConsultation('required_contact_fields.phone', event.target.checked);
                      } }
                      value='phone'
                    />
                  ) }
                  label='Phone'
                />
                <FormControlLabel
                  classes={ {
                    label: classes.checkboxLabel,
                  } }
                  control={ (
                    <Checkbox
                      checked={ data.required_contact_fields.email }
                      onChange={ event => {
                        updateFieldInConsultation('required_contact_fields.email', event.target.checked);
                      } }
                      value='email'
                    />
                  ) }
                  label='Email'
                />
                <FormControlLabel
                  classes={ {
                    label: classes.checkboxLabel,
                  } }
                  control={ (
                    <Checkbox
                      checked={ data.required_contact_fields.contact_person }
                      onChange={ event => {
                        updateFieldInConsultation('required_contact_fields.contact_person', event.target.checked);
                      } }
                      value='contact'
                    />
                  ) }
                  label='Contact person'
                />
              </div>
            </FormGroup>
          </FormControl>
        </FormItemDecorator>
        <FormItemDecorator
          label='Submitter types'
          description='Specify which submitter types are available for this consultation. You can choose from the orgainisations existing types or add new ones.'
          id='submitter-types-builder'
        >
          <CustomCreatableSelect
            isMulti
            isClearable
            placeholder='Click here to select one or more submitter types to allow...'
            options={ submitterTypes }
            value={ data.submitter_types.options || '' }
            onChange={ values => {
              updateFieldInConsultation('submitter_types.options', values || []);
            } }
            onCreateOption={ value => {
              updateFieldInConsultation(
                'submitter_types.options',
                addToArray(data.submitter_types.options, {
                  label: value,
                  value: uuid.v4(),
                  __isNew__: true,
                }),
              );
            } }
          />
          <FormControl>
            <FormGroup className={ classes.contactDetailsGroup }>
              <FormControlLabel
                classes={ {
                  label: classes.checkboxLabel,
                } }
                control={ (
                  <Checkbox
                    checked={ data.submitter_types.allow_public_selection }
                    onChange={ event => {
                      updateFieldInConsultation('submitter_types.allow_public_selection', event.target.checked);
                    } }
                    value='name'
                  />
                ) }
                label='Allow public selection'
              />
              <FormControlLabel
                classes={ {
                  label: classes.checkboxLabel,
                } }
                control={ (
                  <Checkbox
                    checked={ data.submitter_types.required }
                    onChange={ event => {
                      updateFieldInConsultation('submitter_types.required', event.target.checked);
                    } }
                    value='name'
                  />
                ) }
                label='Required'
              />
            </FormGroup>
          </FormControl>
        </FormItemDecorator>
      </div>
      <div className={ classes.innerContainer }>
        <Typography className={ classes.title }>
          Sections and Questions
        </Typography>
        <FormBuilder consultationId={ consultationId } />
      </div>
    </div>
  );
}

ConsultationBuilder.propTypes = {
  consultationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  data: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  section_ids: PropTypes.array,
  updateFieldInConsultation: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { consultationId }) => ({ ...getConsultation(state, consultationId), submitterTypes: selectSubmitterTypes(state) });

const mapDispatchToProps = (dispatch, { consultationId }) => ({
  updateFieldInConsultation: (field, value) => dispatch(updateFieldInConsultation(consultationId, field, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(ConsultationBuilder));
