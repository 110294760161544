const styles = theme => {
  return {
    mainContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: '10pt',
      color: theme.palette.primary.main,
      width: 'auto',
      height: 50,
      flexShrink: 0,
      marginRight: 20,
    },
  };
};

export default styles;
