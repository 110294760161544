import axios from 'axios';
import { get as lodashGet } from 'lodash';
import deepKeys from 'deep-keys';
import {
  SHOW_CONSULTATION,
  UPDATE_CONSULTATION,
  GET_CONSULTATIONS,
  GET_CONSULTATIONS_ERROR,
  SET_SELECTED_CONSULTATIONS,
  ADD_CONSULTATION_FILTER,
  REMOVE_CONSULTATION_FILTER,
  REMOVE_ALL_CONSULTATION_FILTERS,
  SET_CONSULTATION_SORT,
  UPDATE_CONSULTATION_PAGINATION,
  GET_CONSULTATION_FILTER_OPTIONS,
  UPDATE_FIELD_IN_CONSULTATION,
  UPDATE_FIELD_ERROR_IN_CONSULTATION,
  ADD_SECTION,
  REMOVE_SECTION,
  UPDATE_FIELD_IN_SECTION,
  UPDATE_FIELD_ERROR_IN_SECTION,
  ADD_QUESTION,
  REMOVE_QUESTION,
  UPDATE_FIELD_IN_QUESTION,
  UPDATE_FIELD_ERROR_IN_QUESTION,
  SAVE_CONSULTATION,
  REMOVE_CONSULTATION,
  VALIDATE_ENTIRE_CONSULTATION_FORM,
  ENQUEUE_SNACKBAR,
  CREATE_CONSULTATION,
  SAVE_SUBMITTER_TYPES,
  REMOVE_SNACKBAR,
  CLOSE_SNACKBAR,
  UPDATE_SUBMISSION_DATA,
} from '../constants/action-types';
import store from '../store';
import api from '../../api/index';
import consultationBuilderValidationMap from '../../util/form_validation/ConsultationBuilderValidationMap';
import {
  selectConsultation,
  selectSectionObjectsFromConsultationId,
  selectQuestionObjectsFromConsultationId,
  getSectionsFromConsultations,
  getQuestionsFromConsultations,
} from '../selectors/consultationSelectors';
import { createSubmission } from '../../util/data';

export const show = id => dispatch => {
  // const url = `//${window.location.hostname}:3000/api/v1/consultations/${id}`;
  // return axios
  //   .get(url)
  //   .then(response => {
  //     dispatch({ type: CONSULTATIONS_SHOW, payload: response.data, id: response.data.id });
  //   })
  //   .catch(error => {
  //     // todo
  //   });
};

export const getConsultations = () => dispatch => {
  console.log(`${process.env.REACT_APP_API_URL}/api/v1/consultations`);
  return axios
    .get(`${process.env.REACT_APP_API_URL}/api/v1/consultations`)
    .then(response => {
      dispatch({ type: GET_CONSULTATIONS, payload: response.data });
    })
    .catch(error => {
      // todo
    });
};

// The field mappings below convert the supplied field into the appropriate id of the element it relates to when the field is not equal to the id.
const consultationFieldMappings = {
  privacy_statement: 'privacy',
  'consultation_dates.open': 'dates',
  'consultation_dates.close': 'dates',
  'emails.original_receiver': 'dates',
  'emails.notification': 'dates',
  'submitter_types.required': 'submitter-types',
  'submitter_types.options': 'submitter-types',
};
export const updateFieldInConsultation = (consultationId, field, value) => dispatch => {
  dispatch({
    type: UPDATE_FIELD_IN_CONSULTATION, consultationId, field, value,
  });
  dispatch(validateFieldInConsultation(consultationId, field, value));
  const mappedField = Object.keys(consultationFieldMappings).includes(field) ? consultationFieldMappings[field] : field;
  dispatch(scrollTo(`${mappedField}`, 'submission', true));
};

const validateFieldInConsultation = (consultationId, field, value) => (dispatch, getState) => {
  const consultation = getState().consultations.consultations[consultationId];
  const error = lodashGet(consultationBuilderValidationMap.consultation, field)(value, consultation);
  dispatch({
    type: UPDATE_FIELD_ERROR_IN_CONSULTATION, consultationId, field, error,
  });

  // Special case where both dates must be validated when a one of the dates changes.
  if (field === 'consultation_dates.open' || field === 'consultation_dates.close') {
    field = field === 'consultation_dates.open' ? 'consultation_dates.close' : 'consultation_dates.open';
    dispatch({
      type: UPDATE_FIELD_ERROR_IN_CONSULTATION, consultationId, field, error,
    });
  }
};

export const addSection = (section, sectionId) => dispatch => {
  dispatch({
    type: ADD_SECTION, payload: section, sectionId, consultationId: section.consultation_id,
  });
};

export const removeSection = (sectionId, consultationId) => dispatch => {
  dispatch({ type: REMOVE_SECTION, sectionId, consultationId });
};

// The field mappings below convert the supplied field into the appropriate id of the element it relates to when the field is not equal to the id.
const sectionFieldMappings = {
};
export const updateFieldInSection = (sectionId, field, value) => dispatch => {
  dispatch({
    type: UPDATE_FIELD_IN_SECTION, sectionId, field, value,
  });
  dispatch(validateFieldInSection(sectionId, field, value));
  const mappedField = Object.keys(sectionFieldMappings).includes(field) ? sectionFieldMappings[field] : field;
  dispatch(scrollTo(`section-${sectionId}-field-${field}`, 'submission', true));
};

const validateFieldInSection = (sectionId, field, value) => (dispatch, getState) => {
  const section = getState().consultations.sections[sectionId];
  const error = lodashGet(consultationBuilderValidationMap.section, field)(value, section);
  dispatch({
    type: UPDATE_FIELD_ERROR_IN_SECTION, sectionId, field, error,
  });
};

export const addQuestion = (question, questionId) => dispatch => {
  dispatch({
    type: ADD_QUESTION, payload: question, questionId, sectionId: question.section_id,
  });
};

export const removeQuestion = (questionId, sectionId) => dispatch => {
  dispatch({ type: REMOVE_QUESTION, questionId, sectionId });
};

// The field mappings below convert the supplied field into the appropriate id of the element it relates to when the field is not equal to the id.
const questionFieldMappings = {
  input_type: 'input_options',
};
export const updateFieldInQuestion = (questionId, field, value) => dispatch => {
  dispatch({
    type: UPDATE_FIELD_IN_QUESTION, questionId, field, value,
  });
  dispatch(validateFieldInQuestion(questionId, field, value));
  const mappedField = Object.keys(questionFieldMappings).includes(field) ? questionFieldMappings[field] : field;
  dispatch(scrollTo(`question-${questionId}-field-${mappedField}`, 'submission', true));
};

const validateFieldInQuestion = (questionId, field, value) => (dispatch, getState) => {
  const question = getState().consultations.questions[questionId];
  const error = lodashGet(consultationBuilderValidationMap.question, field)(value, question);
  dispatch({
    type: UPDATE_FIELD_ERROR_IN_QUESTION, questionId, field, error,
  });
};

export const createConsultation = history => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/consultations/`;
  axios
    .post(url)
    .then(response => {
      dispatch({ type: CREATE_CONSULTATION, id: response.data.id, data: response.data });
      history.push(`/consultations/${response.data.id}/edit`);
    })
    .catch(error => {
      console.error(error);
    });
};

export const saveConsultation = id => dispatch => {
  const consultationUrl = `${process.env.REACT_APP_API_URL}/api/v1/consultations/${id}`;
  const submitterTypesUrl = `${process.env.REACT_APP_API_URL}/api/v1/submitter_types`;

  const data = {
    consultation: selectConsultation(store.getState(), id),
    sections: selectSectionObjectsFromConsultationId(store.getState(), id),
    questions: selectQuestionObjectsFromConsultationId(store.getState(), id),
  };

  dispatch({
    type: ENQUEUE_SNACKBAR,
    notification: {
      message: 'Saving...',
      options: {
        variant: 'info',
        persist: true,
      },
    },
  });

  axios({
    method: 'put',
    url: consultationUrl,
    data,
  })
    .then(response => {
      dispatch({ type: SAVE_CONSULTATION, id: response.data.id, payload: response.data });

      axios
        .get(submitterTypesUrl)
        .then(response => {
          dispatch({ type: SAVE_SUBMITTER_TYPES, payload: response.data });
          dispatch({ type: CLOSE_SNACKBAR });
          dispatch({
            type: ENQUEUE_SNACKBAR,
            notification: {
              message: 'Consultation saved.',
              options: {
                variant: 'success',
                duration: 800,
              },
            },
          });
        })
        .catch(error => {
          console.error(error);
          dispatch({ type: CLOSE_SNACKBAR });
          dispatch({
            type: ENQUEUE_SNACKBAR,
            notification: {
              message: `Error getting submitter types. ${error}`,
              options: {
                variant: 'error',
                duration: 800,
              },
            },
          });
        });
    })
    .catch(error => {
      console.error(error);
      dispatch({ type: CLOSE_SNACKBAR });
      dispatch({
        type: ENQUEUE_SNACKBAR,
        notification: {
          message: `Error saving consultations. ${error}`,
          options: {
            variant: 'error',
            duration: 800,
          },
        },
      });
    });

  // // ==================== VALIDATE ENTIRE CONSULTATION FORM ====================
  // // Find the consultation from the ID.
  // const consultation = getState().consultations.consultations[consultationId];

  // // Get all the field keys (alternative to Object.keys as this allows for getting keys for nested properties also).
  // const consultationFields = deepKeys(consultation.data);

  // consultationFields.forEach(field => {
  //   const value = lodashGet(consultation.data, field);
  //   dispatch(validateFieldInConsultation(consultation.id, field, value));
  // });

  // // Find all the sections that belong to the consultation.
  // const sections = [];
  // consultation.sectionIds.forEach(sectionId => {
  //   sections.push(getState().consultations.sections[sectionId]);
  // });
  // const questions = [];
  // // Validate the section fields.
  // sections.forEach(section => {
  //   // Get all the field keys (alternative to Object.keys as this allows for getting keys for nested properties also).
  //   const sectionFields = deepKeys(section.data);
  //   sectionFields.forEach(field => {
  //     const value = lodashGet(section.data, field);
  //     dispatch(validateFieldInSection(section.id, field, value));
  //   });
  //   // Find all the questions that belong to the sections.
  //   section.questionIds.forEach(questionId => {
  //     questions.push(getState().consultations.questions[questionId]);
  //   });
  // });

  // // Validate the question fields.
  // questions.forEach(question => {
  //   // Get all the field keys (alternative to Object.keys as this allows for getting keys for nested properties also).
  //   const questionFields = deepKeys(question.data);
  //   questionFields.forEach(field => {
  //     const value = lodashGet(question.data, field);
  //     dispatch(validateFieldInQuestion(question.id, field, value));
  //   });
  // });
  // // ===========================================================================
};

export const removeConsultation = consultationId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/consultations/${consultationId}`;
  axios
    .delete(url)
    .then(response => {
      const { message, id } = response.data;
      if (message === 'Successfully deleted consultation.' && Number(id) === consultationId) {
        dispatch({ type: REMOVE_CONSULTATION, id });
        dispatch({
          type: ENQUEUE_SNACKBAR,
          notification: {
            message: 'Consultation permanently deleted.',
            options: {
              variant: 'success',
              duration: 800,
            },
          },
        });
      } else {
        const error = 'Error: Consultation ID mismatch.';
        dispatch({
          type: ENQUEUE_SNACKBAR,
          notification: {
            message: error,
            options: {
              variant: 'error',
              duration: 800,
            },
          },
        });
        console.error(error);
      }
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchConsultation = (id, setInitialised) => dispatch => {
  const a0 = performance.now();


  const consultationUrl = `${process.env.REACT_APP_API_URL}/api/v1/consultations/${id}`;
  const submitterTypesUrl = `${process.env.REACT_APP_API_URL}/api/v1/submitter_types`;
  axios
    .all([axios.get(consultationUrl), axios.get(submitterTypesUrl)])
    .then(
      axios.spread((consultation, submitterTypes) => {
        const b0 = performance.now();
        dispatch({ type: SHOW_CONSULTATION, payload: consultation.data });
        const b1 = performance.now();
        console.log(`SHOW_CONSULTATION took ${b1 - b0} milliseconds.`);
        const c0 = performance.now();
        dispatch({ type: SAVE_SUBMITTER_TYPES, payload: submitterTypes.data });
        const c1 = performance.now();
        console.log(`SAVE_SUBMITTER_TYPES took ${c1 - c0} milliseconds.`);


        setInitialised(true);


        const a1 = performance.now();
        console.log(`fetchConsultation took ${a1 - a0} milliseconds.`);
      }),
    )
    .catch(error => {
      console.error(error);
    });
};

// Special cases where certain elements on one side correlate to multiple on the other side.
// (e.g. emails and dates in the builder correlate to only one element in the preview).
const builderToSubmissionMappings = {
  emails: 'submission-period-and-email-bar',
  'submission-period': 'submission-period-and-email-bar',
  'original-email': 'submission-period-and-email-bar',
  'notification-email': 'submission-period-and-email-bar',
};
const submissionToBuilderMappings = {
  emails: 'original-email',
};
export const scrollTo = (value, source, onlyScrollSource) => () => {
  // Get the elements to scroll to
  let mappedValue = Object.keys(submissionToBuilderMappings).includes(value) ? submissionToBuilderMappings[value] : value;
  const builderElement = document.getElementById(`${mappedValue}-builder`);

  mappedValue = Object.keys(builderToSubmissionMappings).includes(value) ? builderToSubmissionMappings[value] : value;
  const submissionElement = document.getElementById(mappedValue);

  // Get the scrollable area element for the consultation builder.
  const builderScrollableArea = document.getElementById('consultation-builder-scroll-area');
  // Get the scrollable area element for the submission view.
  const submissionScrollableArea = document.getElementById('submission-scroll-area');

  const padding = 20;

  const smoothScroll = (scrollableArea, element) => {
    if (element) {
      // The preferred 'scrollTo' function is unsupported by several browsers.
      // In these cases, the 'scrollIntoView' function should instead be used.
      // 'scrollTo' is preferred as it allows for more precise control of the position to scroll to.
      if (typeof scrollableArea.scrollTo !== 'undefined') {
        const headerHeight = scrollableArea.offsetTop;
        const scrollOffset = scrollableArea.scrollTop;
        const bounds = element.getBoundingClientRect();
        const yCoordinate = bounds.top + scrollOffset - headerHeight - padding;
        scrollableArea.scrollTo({
          top: yCoordinate,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  let scrollTimeout;

  const onBuilderScrolling = () => {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(
      () => {
        // When finished scrolling the builder view, then scroll the submission view.
        if (submissionElement && !onlyScrollSource) {
          smoothScroll(submissionScrollableArea, submissionElement);
        }
        // Remove the listener to avoid a memory leak.
        builderScrollableArea.removeEventListener('scroll', onBuilderScrolling);
      },
      100,
    );
  };

  const onSubmissionScrolling = () => {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(
      () => {
        // When finished scrolling the submission view, then scroll the builder view.
        if (builderElement && !onlyScrollSource) {
          smoothScroll(builderScrollableArea, builderElement);
        }
        // Remove the listener to avoid a memory leak.
        submissionScrollableArea.removeEventListener('scroll', onSubmissionScrolling);
      },
      100,
    );
  };

  switch (source) {
    case 'builder':
      if (!builderScrollableArea) {
        break;
      }
      builderScrollableArea.addEventListener('scroll', onBuilderScrolling);
      if (builderElement) {
        smoothScroll(builderScrollableArea, builderElement);
      }
      break;

    case 'submission':
      if (!submissionScrollableArea) {
        break;
      }
      submissionScrollableArea.addEventListener('scroll', onSubmissionScrolling);
      if (submissionElement) {
        smoothScroll(submissionScrollableArea, submissionElement);
      }
      break;
    default:
      console.error('Requested scrollTo but source was unknown.');
  }
};
