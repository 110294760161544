import React from 'react';
import FormItemDecorator from '../../../Forms/FormItemDecorator';

const SliderConfiguration = () => (
  <>
    <FormItemDecorator
      label="Minimum value"
      description="TODO"
      required
    >
        number selector goes here
    </FormItemDecorator>
    <FormItemDecorator
      label="Maximum value"
      description="TODO"
      required
    >
      number selector goes here
    </FormItemDecorator>
    <FormItemDecorator
      label="Number of steps"
      description="TODO"
      required
      disableTrailingSpace
    >
      number selector goes here
    </FormItemDecorator>
  </>
);

export default SliderConfiguration;
