import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import SubmissionListIcon from '@material-ui/icons/List';
import EditIcon from '@material-ui/icons/Edit';
import MakeSubmissionIcon from '@material-ui/icons/HowToVote';
import Table from '../../components/Table/Table';
import { removeConsultation, getConsultations } from '../../redux/actions/consultations';
import { selectConsultations } from '../../redux/selectors/consultationSelectors';
import DeleteConfirmationDialog from '../../components/Dialogs/DeleteConfirmationDialog';

const columns = {
  title: { label: 'Title', filterType: 'search' },
  openDate: { label: 'Open Date', filterType: 'date' },
  closeDate: { label: 'Close Date', filterType: 'date' },
  submissions: { label: 'Submissions', filterType: 'numeric' },
  status: { label: 'Status', filterType: 'select' },
  primaryContact: { label: 'Primary Contact', filterType: 'search' },
};

const convertConsultationsToRows = consultations => (
  consultations ? Object.keys(consultations).map(consultationId => {
    const consultation = consultations[consultationId];
    const openDate = moment(consultation.data.consultation_dates.open);
    const closeDate = moment(consultation.data.consultation_dates.close);
    return ({
      id: consultation.id,
      title: consultation.data.title || 'Untitled consultation',
      openDate: openDate.isValid() ? openDate.format('DD/MM/YYYY LT') : 'No open date set',
      closeDate: closeDate.isValid() ? closeDate.format('DD/MM/YYYY LT') : 'No close date set',
      submissions: consultation.data.submission_count || 0,
      status: consultation.data.status.charAt(0).toUpperCase() + consultation.data.status.slice(1),
      primaryContact: consultation.primaryContact || 'No primary contact',
    });
  }) : []);

const ConsultationsTableInternal = props => {
  const {
    consultations, getConsultations, removeConsultation,
  } = props;
  const [rows, setRows] = useState([]);

  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [consultationToDelete, setConsultationToDelete] = useState(null);

  const history = useHistory();

  const onDeleteClicked = consultationId => {
    setDeleteConfirmationDialogOpen(true);
    setConsultationToDelete(consultationId);
  };

  const onDeleteConfirmed = () => {
    removeConsultation(consultationToDelete);
    setDeleteConfirmationDialogOpen(false);
    setConsultationToDelete(null);
  };

  const onCancelDelete = () => {
    setDeleteConfirmationDialogOpen(false);
    setConsultationToDelete(null);
  };

  useEffect(() => {
    getConsultations();
  }, []);

  useEffect(() => {
    setRows(convertConsultationsToRows(consultations));
  }, [consultations]);

  const actions = [
    {
      icon: SubmissionListIcon,
      tooltipText: 'View Submissions Made',
      onClick: consultation => history.push(`/consultations/${consultation.id}/submissions`),
    },
    {
      icon: MakeSubmissionIcon,
      tooltipText: 'View Submission Form',
      onClick: consultation => history.push(`/public/consultations/${consultation.id}/make-a-submission`),
    },
    {
      icon: EditIcon,
      tooltipText: 'Edit Consultation',
      onClick: consultation => history.push(`/consultations/${consultation.id}/edit/`),
    },
    {
      icon: DeleteIcon,
      tooltipText: 'Delete Consultation',
      onClick: consultation => onDeleteClicked(consultation.id),
    },
  ];

  return (
    <MainContainer>
      <Table contextLabel='Consultations' columns={ columns } rows={ rows } actions={ actions } />
      <DeleteConfirmationDialog
        open={ deleteConfirmationDialogOpen }
        context='consultation'
        onCancel={ onCancelDelete }
        onConfirm={ onDeleteConfirmed }
      />
    </MainContainer>
  );
};

const MainContainer = styled.div`
  height: 100%;
`;

const mapStateToProps = state => {
  const consultations = selectConsultations(state);
  return {
    consultations,
  };
};

const mapDispatchToProps = dispatch => ({
  removeConsultation: id => dispatch(removeConsultation(id)),
  getConsultations: () => dispatch(getConsultations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationsTableInternal);
