import { combineReducers } from 'redux';
import submissions from './submissions';
import consultations from './consultations';
import submitters from './submitters';
import submissionForm from './submissionForm';
import organisation from './organisation';
import general from './general';

export default combineReducers({
  submissions,
  consultations,
  submitters,
  submissionForm,
  general,
  organisation,
});
