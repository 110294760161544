import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';

const PageTitle = props => {
  const { primaryText, secondaryText, tooltip } = props;
  let content = (
    <TextContainer>
      <PrimaryText>{primaryText}</PrimaryText>
      {secondaryText ? <SecondaryText>{secondaryText}</SecondaryText> : null}
    </TextContainer>
  );
  content = tooltip ? (<Tooltip title={ tooltip } aria-label={ tooltip }>{content}</Tooltip>) : content;
  return (
    <MainContainer>
      {content}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  && {
    width: auto;
    height: 70px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
  }
`;

const TextContainer = styled.div`
  && {
    font-family: Roboto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 12pt;
  }
`;

const PrimaryText = styled.span`
  && {
    color: ${props => props.theme.palette.darkBlue.main};
    font-weight: 600;
    font-size: 20pt;
  }
`;

const SecondaryText = styled.span`
  && {
    color: #444444;
    font-size: 11pt;
  }
`;

export default PageTitle;
