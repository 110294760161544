import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR } from '../constants/action-types';

// This is only for components, if you wanna use it in redux actions, use the code below as templates.

// Example notficiation object format:
// notification = {
//     id: 1234,
//     message: "This is a message.",
//     options: {
//          variant: "warning",
//          duration: 1000 //milliseconds
//     }
// }
export const enqueueSnackbar = notification => {
  return {
    type: ENQUEUE_SNACKBAR,
    notification: notification,
  };
};

export const closeSnackbar = id => {
  return {
    type: CLOSE_SNACKBAR,
    dismissAll: !id, // dismiss all if no id has been defined
    id,
  };
};

export const removeSnackbar = id => dispatch => {
  dispatch({
    type: REMOVE_SNACKBAR,
    id,
  });
};
