import { SUBMITTERS_SHOW } from '../constants/action-types';
import axios from 'axios';

export const show = id => dispatch => {
  const url = `//${window.location.hostname}:3000/api/v1/submitters/${id}`;
  return axios
    .get(url)
    .then(response => {
      dispatch({ type: SUBMITTERS_SHOW, payload: response.data, id: response.data.id });
    })
    .catch(error => {
      // todo
    });
};
