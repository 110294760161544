import {
  SUBMISSIONS_SHOW,
  GET_SUBMISSIONS,
  GET_SUBMISSIONS_ERROR,
  SET_SELECTED_SUBMISSIONS,
  ADD_SUBMISSION_FILTER,
  REMOVE_SUBMISSION_FILTER,
  REMOVE_ALL_SUBMISSION_FILTERS,
  SET_SUBMISSION_SORT,
  UPDATE_SUBMISSION_PAGINATION,
  GET_SUBMISSION_FILTER_OPTIONS,
  SET_PROCESS_SUBMISSION_VIEW_MODE,
} from '../constants/action-types';
import { fakeSubmission } from '../../util/sampleData';

const initialState = {
  processSubmissions: { [fakeSubmission.id]: fakeSubmission },
  currentSubmissionId: null,
  submissions: {},
  total: 100,
  numberProcessed: 80,
  current: [],
  selected: [],
  error: false,
  columnSort: { column: 'Ref#', ascending: true },
  columnFilters: [],
  columnFilterOptions: [],
  tablePagination: {
    rowsPerPage: 10,
    currentPage: 0,
  },
};

const show = (state, action) => {
  return {
    ...state,
    processSubmissions: {
      ...state.processSubmissions,
      [action.id]: action.payload,
    },
    currentSubmissionId: action.id,
  };
};

const newStateGetSubmissions = (state, action) => {
  console.log('new state get submissions', action.payload);
  return {
    ...state,
    current: action.payload,
    error: false,
  };
};

const newStateGetFilterOptions = (state, action) => {
  console.log('GETTING FILTER LIST WOO!', action.payload);
  return {
    ...state,
    columnFilterOptions: action.payload,
    error: false,
  };
};

const newStateGetSubmissionsError = (state, action) => {
  return { ...state, submissions: { ...state.submissions, error: true } };
};

const newStateSetSelectedSubmissions = (state, action) => {
  return {
    ...state,
    selected: action.payload,
  };
};

const newStateAddSubmissionFilter = (state, action) => {
  const filterToAdd = action.payload;
  let updatedFilters = [...state.columnFilters];
  if (state.columnFilters.find(filter => filter.column === filterToAdd.column)) {
    updatedFilters = state.columnFilters.filter(filter => filter.column !== filterToAdd.column);
  }
  updatedFilters.push(filterToAdd);
  return { ...state, columnFilters: updatedFilters };
};

const newStateRemoveSubmissionFilter = (state, action) => {
  const filterToRemove = action.payload;
  return {
    ...state,
    columnFilters: state.columnFilters.filter(filter => filter.column !== filterToRemove.column),
  };
};

const newStateRemoveAllSubmissionFilters = (state, action) => {
  return { ...state, columnFilters: [] };
};

const newStateSetSubmissionSort = (state, action) => {
  return { ...state, columnSort: action.payload };
};

const newStateUpdateSubmissionPagination = (state, action) => {
  return { ...state, tablePagination: action.payload };
};

const newStateSetProcessSubmissionViewMode = (state, action) => {
  return { ...state, processSubmissionViewMode: action.payload };
};

const submissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMISSIONS_SHOW:
      return show(state, action);
    case GET_SUBMISSIONS:
      return newStateGetSubmissions(state, action);
    case GET_SUBMISSION_FILTER_OPTIONS:
      return newStateGetFilterOptions(state, action);
    case GET_SUBMISSIONS_ERROR:
      return newStateGetSubmissionsError(state, action);
    case SET_SELECTED_SUBMISSIONS:
      return newStateSetSelectedSubmissions(state, action);
    case ADD_SUBMISSION_FILTER:
      return newStateAddSubmissionFilter(state, action);
    case REMOVE_SUBMISSION_FILTER:
      return newStateRemoveSubmissionFilter(state, action);
    case REMOVE_ALL_SUBMISSION_FILTERS:
      return newStateRemoveAllSubmissionFilters(state, action);
    case SET_SUBMISSION_SORT:
      return newStateSetSubmissionSort(state, action);
    case UPDATE_SUBMISSION_PAGINATION:
      return newStateUpdateSubmissionPagination(state, action);
    case SET_PROCESS_SUBMISSION_VIEW_MODE:
      return newStateSetProcessSubmissionViewMode(state, action);
    default:
      return state;
  }
};

export default submissionReducer;
