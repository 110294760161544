import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import SubmissionForm from '../../components/Submission/Submission';
import Header from '../Forms/Header';
import { saveSubmission } from '../../redux/actions/submissionList';
import BasicLoader from '../../components/BasicLoader/BasicLoader';
import { getConsultation } from '../../redux/selectors/consultationSelectors';
import { scrollTo } from '../../redux/actions/consultations';
import { updateSubmissionData } from '../../redux/actions/submissionForm';
import { selectSubmissionData } from '../../redux/selectors/submissionFormSelectors';

const styles = theme => ({
  mainContainer: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

function Submission(props) {
  const {
    consultationId, classes, preview, submissionData, scrollTo, updateSubmissionData, setAttachment, attachment,
  } = props;

  useEffect(() => {
    if (!submissionData) updateSubmissionData();
    return () => {
      updateSubmissionData(true);
    };
  }, []);

  return (
    <div style={ { ...props.style } } className={ classes.mainContainer }>
      {submissionData ? (
        <>
          <Header builder={ false } onChange={ scrollTo } maxTotalNumber={ 99 } consultationId={ consultationId } />
          <SubmissionForm { ...{
            consultationId, preview, setAttachment, attachment,
          } }
          />
        </>
      ) : (
        <BasicLoader />
      )}
    </div>
  );
}

Submission.propTypes = {
  consultationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  saveSubmission: PropTypes.func.isRequired,
  routeSuccess: PropTypes.func.isRequired,
  preview: PropTypes.bool,
};

// const makeMapStateToProps = () => {
//   const createSubmissionData = makeCreateSubmission();
const mapStateToProps = (state, { consultationId }) => ({
  submissionData: selectSubmissionData(state),
});
//   return mapStateToProps;
// };

const mapDispatchToProps = (dispatch, { consultationId, routeSuccess }) => ({
  saveSubmission: data => {
    dispatch(saveSubmission(null, data));
    routeSuccess();
  },
  scrollTo: value => dispatch(scrollTo(value, 'submission')),
  updateSubmissionData: dismount => dispatch(updateSubmissionData(consultationId, dismount)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(Submission));
