import React from 'react';
import styled from 'styled-components';
import AddIcon from '@material-ui/icons/AddRounded';
import CustomButton from '../../components/CustomButton/CustomButton';
import PageTitle from '../../components/PageTitle/PageTitle';
import ConsultationsTableInternal from './ConsultationsTableInternal';

const ConsultationsListPage = () => (
  <MainContainer>
    <HeaderArea>
      <PageTitle primaryText='Consultations' />
      <CreateConsultationButtonContainer>
        <CustomButton
          color='darkBlue'
          variant='contained'
          route='/consultations/generate'
          label='Create New Consultation'
          icon={ AddIcon }
        />
      </CreateConsultationButtonContainer>
    </HeaderArea>
    <ContentContainer>
      <ConsultationsTableInternal />
    </ContentContainer>
  </MainContainer>
);

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const CreateConsultationButtonContainer = styled.div`
  margin-left: 8px;
`;

const HeaderArea = styled.div`
  width: calc(100% - 70px - 20px);
  padding-left: 70px;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ContentContainer = styled.div`
  height: calc(100% - 70px);
`;

export default ConsultationsListPage;
