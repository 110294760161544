import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Add from '@material-ui/icons/Add';
import { addSection, removeSection, scrollTo } from '../../redux/actions/consultations';
import Section from './Section';
import { createSection } from '../../util/data';
import CustomButton from '../../components/CustomButton/CustomButton';
import { selectConsultation } from '../../redux/selectors/consultationSelectors';
import NoContentMessage from '../../components/NoContentMessage/NoContentMessage';

let scrollToNewSection = false;

function FormBuilder(props) {
  const {
    consultationId, addSection, removeSection, section_ids, scrollTo, style,
  } = props;

  // The hook below simulates componentDidMount, to scroll to a new section if one has been added.
  useEffect(() => {
    if (scrollToNewSection) {
      setTimeout(() => scrollTo(`section-${section_ids[section_ids.length - 1]}`), 100);
      scrollToNewSection = false;
    }
  }, [section_ids]);

  const addSectionToConsultation = () => {
    const section = createSection(consultationId, null, section_ids.length);
    addSection(section, section.id);
    scrollToNewSection = true;
  };

  const removeSectionFromConsultation = sectionId => {
    removeSection(sectionId);
  };

  return (
    <div style={ style }>
      {section_ids && section_ids.length > 0 && section_ids.map((sectionId, index) => (
        <Section
          key={ sectionId }
          sectionId={ sectionId }
          removeSection={ removeSectionFromConsultation }
          index={ index }
        />
      ))}
      <div style={ {
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
      } }
      >
        {section_ids.length === 0 && <NoContentMessage context='section' />}
        <CustomButton
          color='darkBlue'
          variant='text'
          onClick={ addSectionToConsultation }
          label='Add section to consultation'
          aria-label='Add section to consultation'
          icon={ Add }
        />
      </div>
    </div>
  );
}

FormBuilder.propTypes = {
  consultationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  data: PropTypes.object.isRequired,
  section_ids: PropTypes.array.isRequired,
  addSection: PropTypes.func.isRequired,
  removeSection: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { consultationId }) => selectConsultation(state, consultationId);

const mapDispatchToProps = (dispatch, { consultationId }) => ({
  addSection: (section, sectionId) => dispatch(addSection(section, sectionId)),
  removeSection: sectionId => dispatch(removeSection(sectionId, consultationId)),
  scrollTo: value => dispatch(scrollTo(value, 'builder')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormBuilder);
