import { SUBMITTERS_SHOW } from '../constants/action-types';
import { fakeSubmitter } from '../../util/sampleData';

const initialState = { [fakeSubmitter.id]: fakeSubmitter };

const show = (state, action) => {
  return {
    ...state,
    [action.id]: action.payload,
  };
};

const submitterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMITTERS_SHOW:
      return show(state, action);
    default:
      return state;
  }
};

export default submitterReducer;
