import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import Tabs from './Tabs';
import CustomButton from '../../components/CustomButton/CustomButton';
import styles from './styles';

function Question(props) {
  const [associatedPositions, setAssociatedPositions] = useState(props.associatedPositions || []);
  const [adminNotes, setAdminNotes] = useState(props.adminNotes || '');
  const [submitterNotes, setSubmitterNotes] = useState(props.submitterNotes || '');

  const [textModified, setTextModified] = useState(false);
  const [positionModified, setPositionModified] = useState(false);

  const {
    isMulti, questionNumber, question, positions, consultation, classes,
  } = props;

  const handlePositionChange = event => {
    let value;
    if (props.isMulti) {
      if (event.target.checked) {
        value = associatedPositions.concat(event.target.value);
      } else {
        value = [...associatedPositions];
        value.splice(value.indexOf(event.target.value), 1);
      }
    } else {
      value = [].concat(event.target.value);
    }
    setAssociatedPositions(value);
    checkPositionChange(value);
  };

  // Checks whether the position has changed from its original value.
  const checkPositionChange = value => {
    let bool = false;
    if (props.isMulti) {
      if (props.associatedPositions != undefined) {
        value.forEach(val => {
          if (props.associatedPositions.indexOf(val) < 0) {
            return (bool = true);
          }
        });
        if (value.length != props.associatedPositions.length) bool = true;
      } else if (value.length > 0) bool = true;
    } else if (props.associatedPositions == undefined && associatedPositions != value) {
      bool = true;
    } else if (props.associatedPositions != undefined && props.associatedPositions.indexOf(value[0]) < 0) {
      bool = true;
    }
    setPositionModified(bool);
  };

  const handleTextChange = name => event => {
    name == 'submitterNotes' ? setSubmitterNotes(event.target.value) : setAdminNotes(event.target.value);
  };

  const save = () => {
    // enqueueSnackbar is adding the following snackbar to the snackbar provider queue. Notistack can only show
    // a maximum number of snackbars (refer to SnackbarProvider in App.jsx for exact number)
    props.enqueueSnackbar('Add save to redux and dispatch', {
      variant: 'warning',
      preventDuplicate: true,
    });
  };

  return (
    <div>
      <b style={ { fontSize: 18 } }>
Question
        {' '}
        {questionNumber}
      </b>
      <div
        style={ {
          display: 'flex',
          marginTop: 20,
        } }
      >
        <div
          style={ {
            width: '100%',
            maxWidth: 600,
          } }
        >
          <p className={ classes.fontSize }>{question}</p>
          {positions.length > 0 && (
            <FormControl>
              <FormLabel className={ classes.fontSize }>Position</FormLabel>
              <FormGroup aria-label='Position' name='Position' onChange={ handlePositionChange }>
                {positions.map((position, index) => {
                  const Input = isMulti ? Checkbox : Radio;
                  return (
                    <FormControlLabel
                      key={ position }
                      value={ `${position}` }
                      onClick={ event => {
                        // isMulti does not require deselecting itself
                        if (!props.isMulti && associatedPositions[0] == event.target.value) {
                          setAssociatedPositions([]);
                          if (event.target.value != undefined) checkPositionChange(event.target.value);
                        }
                      } }
                      control={ <Input checked={ associatedPositions.indexOf(position) > -1 } /> }
                      label={ position }
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          )}
        </div>
        <div
          style={ {
            flex: 1,
          } }
        >
          <Tabs
            submitterNotes={ submitterNotes }
            adminNotes={ adminNotes }
            modify={ bool => {
              setTextModified(bool);
            } }
          />
          <CustomButton
            aria-label='Save'
            label='Save'
            color='green'
            onClick={ save }
            disabled={ !(textModified || positionModified) }
            variant='outlined'
            style={ { marginLeft: 24 } }
          />
        </div>
      </div>
    </div>
  );
}

Question.propTypes = {
  positions: PropTypes.array.isRequired,
  adminNotes: PropTypes.string,
  submitterNotes: PropTypes.string,
  questionNumber: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  isMulti: PropTypes.bool.isRequired,
  associatedPositions: PropTypes.array,
  consultation: PropTypes.object,
};

export default withSnackbar(withStyles(styles, { withTheme: true })(Question));
