import React, { useReducer, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import {
  Button,
  makeStyles,
  Radio,
  Fade,
  Collapse,
  Paper,
} from '@material-ui/core';
import DropdownConfiguration from './configuration/DropdownConfiguration';
import RadioButtonsConfiguration from './configuration/RadioButtonsConfiguration';
import CheckboxesConfiguration from './configuration/CheckboxesConfiguration';
import SliderConfiguration from './configuration/SliderConfiguration';

const useStyles = makeStyles(theme => (
  {
    disabledButton: {
      color: theme.palette.text.disabled,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      height: '100%',
      alignItems: 'center',
    },
    buttonLabel: {
      flex: 1,
      textTransform: 'none',
      textAlign: 'left',
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: theme.spacing(3),
      whiteSpace: 'nowrap',
    },
  }
));

const types = {
  text: {
    label: 'Text',
    demoFile: 'textfield.gif',
    helpText: 'Allows the submitter to provide a text-based response by typing their answer freely.',
    configurationComponent: null,
    enabled: true,
  },
  radio: {
    label: 'Single choice (radio)',
    demoFile: 'radio.gif',
    // eslint-disable-next-line max-len
    helpText: 'Allows the submitter to select ONE option from a list of predefined options. Preferred over dropdown for FEWER than 5 options.',
    configurationComponent: RadioButtonsConfiguration,
    enabled: true,
  },
  dropdown: {
    label: 'Single choice (dropdown)',
    demoFile: 'dropdown.gif',
    // eslint-disable-next-line max-len
    helpText: 'Allows the submitter to select ONE option from a list of predefined options. Preferred over radio for 5 or MORE options.',
    configurationComponent: DropdownConfiguration,
    enabled: false,
  },
  checkbox: {
    label: 'Multi choice',
    demoFile: 'checkbox.gif',
    helpText: 'Allows the submitter to select MULTIPLE options from a list of predefined options.',
    configurationComponent: CheckboxesConfiguration,
    enabled: true,
  },
  slider: {
    label: 'Slider',
    demoFile: 'slider.gif',
    helpText: 'Allows the submitter to select a numeric value from a predefined range.',
    configurationComponent: SliderConfiguration,
    enabled: false,
  },
};

const initialState = {
  lastHoveredType: Object.keys(types)[0],
  currentHoveringType: null,
  helpAreaMarginTop: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'onMouseEnterRadio':
      return {
        ...state,
        currentHoveringType: action.payload,
        lastHoveredType: action.payload,
      };
    case 'onMouseLeaveRadio':
      return {
        ...state,
        currentHoveringType: null,
      };
    case 'setHelpAreaMarginTop': {
      return {
        ...state,
        helpAreaMarginTop: (42 * Object.keys(types).findIndex(type => type === action.payload)),
      };
    }
    default:
      return state;
  }
};

const InputTypeSelector = props => {
  const {
    selectedType,
    onInputTypeChange,
    questionId,
    unknown,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    lastHoveredType,
    currentHoveringType,
    helpAreaMarginTop,
  } = state;

  const classes = useStyles();

  const onMouseEnterRadio = type => (dispatch({ type: 'onMouseEnterRadio', payload: type }));

  const onMouseLeaveRadio = () => (dispatch({ type: 'onMouseLeaveRadio' }));

  useEffect(() => {
    dispatch({ type: 'setHelpAreaMarginTop', payload: lastHoveredType });
  }, [lastHoveredType]);

  useEffect(() => {
    // Existing questions that use the deprecated 'empty' input type must be converted to the default 'text' input type.
    if (unknown) onInputTypeChange('text');
  }, []);

  const handleInputChange = type => {
    onInputTypeChange(type);
  };

  return (
    <>
      <SelectAnswerMethod>
        <div className={ classes.inputContainer }>
          {Object.keys(types).map(type => (
            <Button
              onClick={ () => types[type].enabled && handleInputChange(type) }
              onMouseEnter={ () => onMouseEnterRadio(type) }
              onMouseLeave={ onMouseLeaveRadio }
              className={ !types[type].enabled ? classes.disabledButton : null }
              key={ `question-${questionId}-input-type-${type}` }
            >
              <div className={ classes.buttonContainer }>
                <Radio
                  checked={ selectedType === type }
                  disableRipple
                  style={ { backgroundColor: 'transparent' } }
                  disabled={ !types[type].enabled }
                />
                <div className={ classes.buttonLabel }>
                  {types[type].label}
                </div>
                <span />
              </div>
            </Button>
          ))}
        </div>
        <HelpAreaContainer>
          <Fade in={ currentHoveringType !== null }>
            <HelpArea
              animate={
              { marginTop: helpAreaMarginTop }
            }
            >
              <PreviewContainer elevation={ 2 } style={ { borderRadius: 10 } }>
                <Preview src={ `/images/input_demos/${types[lastHoveredType].demoFile}` } draggable='false' />
                <HelpText>{types[lastHoveredType].helpText}</HelpText>
              </PreviewContainer>
            </HelpArea>
          </Fade>
        </HelpAreaContainer>
      </SelectAnswerMethod>
      <Collapse in={ types[selectedType].configurationComponent !== null }>
        <ConditionalSpacing />
      </Collapse>
      {Object.keys(types).map(type => {
        const ConfigurationComponent = types[type].configurationComponent;
        return (
          ConfigurationComponent
            ? (
              <Collapse key={ `${type}-configuration-container` } in={ type === selectedType }>
                {ConfigurationComponent ? <ConfigurationComponent questionId={ questionId } /> : <span />}
              </Collapse>
            ) : null
        );
      })}
    </>
  );
};

InputTypeSelector.defaultProps = {
  selectedType: 'text',
};

InputTypeSelector.propTypes = {
  selectedType: PropTypes.string,
  onInputTypeChange: PropTypes.func.isRequired,
  questionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  unknown: PropTypes.bool.isRequired,
};

export default InputTypeSelector;

const SelectAnswerMethod = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const HelpAreaContainer = styled.div`
  position: relative;
  width: 100%;
`;

const HelpArea = styled(motion.div)`
    position: absolute;
    flex-grow: 1;
    height: 100%;
    z-index: 100;
`;

const PreviewContainer = styled(Paper)`
    width: 100%;
    min-height: 112px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

const Preview = styled.img`
    border-radius: 10px;
    width: 160px;
    height: 112px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
`;

const HelpText = styled.div`
  font-size: 10pt;
  font-weight: 500;
  fontSize: 11pt;
  max-width: 400px;
  color: rgb(51, 51, 51);
  margin: 15px;
  margin-top: 0px;
`;

const ConditionalSpacing = styled.div`
  height: 15px;
`;
