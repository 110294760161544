import React from 'react';
import styled from 'styled-components';
import PageTitle from '../../components/PageTitle/PageTitle';
import SubmissionsTableInternal from './SubmissionsTableInternal';

const ConsultationsListPage = () => (
  <MainContainer>
    <HeaderArea>
      <PageTitle primaryText='Submissions for' secondaryText='Intelligently incentivizing our wholesale customer-focused ice cream action points' />
    </HeaderArea>
    <ContentContainer>
      <SubmissionsTableInternal />
    </ContentContainer>
  </MainContainer>
);

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const HeaderArea = styled.div`
  width: calc(100% - 70px - 20px);
  padding-left:70px;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ContentContainer = styled.div`
  height: calc(100% - 70px);
`;

export default ConsultationsListPage;
