import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import './quill.css';
import FormatSizeIcon from '@material-ui/icons/FormatSize';
import { Tooltip } from '@material-ui/core';

let timeout = null;

const styles = theme => ({
  quill: { maxWidth: '100%' },
  quillContainer: {
    position: 'relative',
    borderRadius: '4px 4px 4px 4px',
    boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.25)',
    padding: 10,
    paddingBottom: 6,
    boxSizing: 'border-box',
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.main}`,
    },
    '&:focus-within': {
      boxShadow: `inset 0px 0px 0px 2px ${theme.palette.primary.main}`,
    },
  },
  button: {
    minWidth: 40,
    width: 40,
    borderRadius: '4px 4px 0px 0px',
  },
  buttonContainer: {
    position: 'absolute',
    top: -28,
    right: 0,
    borderRadius: '4px 4px 0px 0px',
  },
  icon: { fontSize: '15pt', color: 'rgb(51, 51, 51)' },
});

class QuillFormItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.initialValue,
      hideToolbar: true,
    };
  }

  toggleToolbar = () => {
    this.setState(previousState => ({ hideToolbar: !previousState.hideToolbar }));
  };

  onDoneEditing = () => {
    this.props.onDoneEditing(this.state.text);
  };

  setTypingTimeout = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      this.props.onDoneEditing(this.state.text);
    }, 1000);
  };

  onChange = value => {
    this.setTypingTimeout();
    this.setState({ text: value });
  };

  render() {
    const {
      id, classes, theme, placeholder,
    } = this.props;
    const { text, hideToolbar } = this.state;

    const containerClass = classes.quillContainer + (hideToolbar ? ' hide-toolbar' : '');

    return (
      <div className={ containerClass }>

        <div className={ classes.buttonContainer } style={ { backgroundColor: hideToolbar ? 'transparent' : '#E0E0E0' } }>
          <Tooltip title={ `${hideToolbar ? 'Show' : 'Hide'} rich text editing tools.` }>
            <Button className={ classes.button } size="small" onClick={ this.toggleToolbar }>
              <FormatSizeIcon className={ classes.icon } />
            </Button>
          </Tooltip>
        </div>

        <ReactQuill
          theme="snow"
          className={ classes.quill }
          placeholder={ placeholder || 'Enter text here...' }
          onChange={ this.onChange }
          onBlur={ this.onDoneEditing }
          value={ text || '' }
          id={ id }
        />
      </div>
    );
  }
}

QuillFormItem.propTypes = {};

export default withStyles(styles, { withTheme: true })(QuillFormItem);
