import uuid from 'uuid';
import faker from 'faker';
import moment from 'moment';

// Consultation

export const createConsultation = id => ({
  id: id || uuid.v4(),
  primary_contact: '',
  data: {
    title: '',
    description: '',
    status: 'Draft',
    privacy_statement: '',
    consultation_dates: {
      open: null,
      close: null,
    },
    grace_period: 0,
    emails: {
      original_receiver: '',
      notification: '',
    },
    assets: {
      supporting_documents: [],
      logo: '',
    },
    required_contact_fields: {
      given_or_company_name: false,
      surname: false,
      contact_person: false,
      address: false,
      region: false,
      country: false,
      phone: false,
      email: false,
    },
    submitter_types: {
      allow_public_selection: false,
      required: false,
      options: [],
    },
  },
  formErrors: {},
  section_ids: [],
});

export const createSection = (consultationId, sectionId, order) => ({
  id: sectionId || uuid.v4(),
  data: { title: '', description: '' },
  formErrors: {},
  question_ids: [],
  consultation_id: consultationId || null,
  order,
});

export const createQuestion = (sectionId, questionId, order) => ({
  id: questionId || uuid.v4(),
  data: {
    title: '',
    description: '',
    helper_text: '',
    input_type: 'text',
    required: false,
    input_options: null,
  },
  formErrors: {},
  section_id: sectionId || null,
  order,
});

export const createOption = (value, order) => ({
  id: uuid.v4(),
  value: null,
  order: -1,
});

// Submission
export const createSubmission = (id, sections, questions) => {
  let answersData = {};
  if (sections) {
    Object.keys(sections).map(sectionId => (answersData = {
      ...answersData,
      [sectionId]: {},
    }));
  }
  if (questions) {
    Object.keys(questions).map(questionId => {
      const question = questions[questionId];
      return (answersData = {
        ...answersData,
        [question.section_id]: {
          ...answersData[question.section_id],
          [question.id]: {
            question_id: question.id,
            answer: null,
            text: '',
            type: question.data.input_type,
            admin_notes: '',
          },
        },
      });
    });
  }
  return {
    consultation_id: id || uuid.v4(),
    contact_details: {
      given_or_company_name: '',
      surname: '',
      contact_person: '',
      address: '',
      region: '',
      country: '',
      phone: '',
      email: '',
    },
    submitter_types: [],
    sections: answersData,
    submission_source: 'form',
    status: 'Submitted',
    labels: [],
    assignee: null,
  };
};

const builderStaticHeaderData = [
  { value: 'title', label: 'Title' },
  { value: 'description', label: 'Description' },
  { value: 'privacy', label: 'Privacy Statement' },
  { value: 'submission-period', label: 'Submission Period' },
  { value: 'grace', label: 'Grace Period' },
  { value: 'original-email', label: 'Original Receiver Email' },
  { value: 'notification-email', label: 'Notification Receiver Email' },
  { value: 'assets', label: 'Organisation Assets' },
];

const liveStaticHeaderData = [
  { value: 'title', label: 'Title' },
  { value: 'description', label: 'Description' },
  { value: 'using-this-form', label: 'Using this Form' },
  { value: 'privacy', label: 'Privacy Statement' },
  { value: 'submission-period', label: 'Submission Period' },
  { value: 'emails', label: 'Emails' },
];

export const createStaticHeader = builder => {
  const data = [];

  const submitterDetails = [];
  submitterDetails.push({ value: 'required-contact-details', label: 'Required submitter contact details' });
  submitterDetails.push({ value: 'submitter-types', label: 'Submitter types' });
  data.push({ label: 'Consultation Details', options: builder ? builderStaticHeaderData : liveStaticHeaderData });
  data.push({ label: 'Submitter Details', options: submitterDetails });

  return data;
};

export const createFakeConsultationData = id => {
  const section_ids = createFakeArrayIds();
  const consultationId = id || uuid.v4();
  const sections = {};
  section_ids.map(id => {
    sections[id] = createFakeSection(consultationId, id);
  });
  const questions = {};
  Object.keys(sections).map(key => {
    sections[key].question_ids.map(id => {
      questions[id] = createFakeQuestion(key, id);
    });
  });

  return {
    consultation: {
      id: consultationId,
      primary_contact: faker.name.findName(),
      data: {
        title: faker.random.words(),
        description: `<p>${faker.lorem.paragraphs()}</p>`,
        status: 'Draft',
        privacy_statement: `<p>${faker.lorem.paragraph()}</p>`,
        consultation_dates: {
          open: faker.date.past(),
          close: faker.date.future(),
        },
        grace_period: Math.floor(Math.random() * 8),
        emails: {
          original_receiver: faker.internet.exampleEmail(),
          notification: faker.internet.exampleEmail(),
        },
        assets: {
          supporting_documents: [...Array(Math.floor(Math.random() * 8))].map(_ => ({
            lastModified: moment(Date.now()).unix(),
            lastModifiedDate: Date.now(),
            name: faker.system.fileName(),
            path: faker.system.filePath(),
            size: faker.random.number(),
            type: faker.system.mimeType,
            webkitRelativePath: '',
          })),
          logo: {
            lastModified: moment(Date.now()).unix(),
            lastModifiedDate: Date.now(),
            name: faker.system.fileName(),
            path: faker.system.filePath(),
            size: faker.random.number(),
            type: faker.system.mimeType,
            webkitRelativePath: '',
          },
        },
        required_contact_fields: {
          given_or_company_name: faker.random.boolean(),
          surname: faker.random.boolean(),
          contact_person: faker.random.boolean(),
          address: faker.random.boolean(),
          region: faker.random.boolean(),
          country: faker.random.boolean(),
          phone: faker.random.boolean(),
          email: faker.random.boolean(),
        },
        submitter_types: {
          allow_public_selection: faker.random.boolean(),
          required: faker.random.boolean(),
          options: createFakeSubmitterTypesOptions(),
        },
      },
      formErrors: {},
      section_ids,
    },
    sections,
    questions,
  };
};

const createFakeSubmitterTypesOptions = () => [...Array(Math.floor(Math.random() * 8))].map(_ => {
  const name = faker.random.words();
  return { label: name, value: uuid.v4() };
});

const createFakeArrayIds = () => [...Array(Math.floor(Math.random() * 7 + 1))].map(_ => uuid.v4());

const createFakeSection = (consultationId, sectionId) => ({
  id: sectionId,
  data: { title: faker.random.words(), description: faker.lorem.paragraph() },
  formErrors: {},
  question_ids: createFakeArrayIds(),
  consultation_id: consultationId || null,
});

const createFakeQuestion = (sectionId, questionId) => ({
  id: questionId,
  data: {
    title: faker.random.words(),
    description: faker.lorem.paragraph(),
    helper_text: faker.random.words(),
    input_type: Math.random() < 0.5 ? 'Multiple Choice' : 'Checkboxes',
    required: faker.random.boolean(),
    input_options: createFakeInputOptions(),
  },
  formErrors: {},
  section_id: sectionId || null,
});

const createFakeInputOptions = () => [...Array(Math.floor(Math.random() * 6 + 2))].map(_ => ({ id: uuid.v4(), value: faker.random.words(), order: -1 }));
