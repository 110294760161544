import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MaterialTable from '@material-ui/core/Table';
import MaterialTableBody from '@material-ui/core/TableBody';
import MaterialTableCell from '@material-ui/core/TableCell';
import MaterialTableHead from '@material-ui/core/TableHead';
import MaterialTablePagination from '@material-ui/core/TablePagination';
import MaterialTableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import Chip from '@material-ui/core/Chip';
import { startCase } from 'lodash';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grow from '@material-ui/core/Grow';
import moment from 'moment';
import chrono from 'chrono-node';
import DropdownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import SortAscendingIcon from '@material-ui/icons/ArrowUpward';
import SortDescendingIcon from '@material-ui/icons/ArrowDownward';
import TextField from '@material-ui/core/TextField';
import CustomButton from '../CustomButton/CustomButton';
import IconButton from '../IconButton/IconButton';
import SearchBar from '../SearchBar/SearchBar';
import DatePickerField from '../DatePickerField/DatePickerField';

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100, { value: 99999, label: 'All' }];
const DEFAULT_ROWS_PER_PAGE = ROWS_PER_PAGE_OPTIONS[2];

/**
 * COLUMN_TYPES define types of filters that may be used for a column.
 * These are assigned to a column via 'filterType' in the required column data supplied to the table.
 * E.g:
 *  const columns = {
 *    title: { label: 'Title', filterType: 'search' },
 *  };
 */
let COLUMN_TYPES = {
  search: {
    generateChipLabel: (columnKey, configuration) => `${startCase(columnKey)} contains '${configuration.searchTerm}'`,
    checkValueMatchesFilterCriteria: (value, configuration) => (value.toLowerCase().includes(configuration.searchTerm.toLowerCase())),
    sortComparator: (value1, value2) => (value1 < value2 ? -1 : 1),
  },
  select: {
    generateChipLabel: (columnKey, configuration) => `${startCase(columnKey)} is '${configuration.selected}'`,
    checkValueMatchesFilterCriteria: (value, configuration) => (value.toLowerCase() === configuration.selected.toLowerCase()),
    currentSelectableValues: [],
    sortComparator: (value1, value2) => (value1 < value2 ? -1 : 1),
  },
  date: {
    dateFilterTypes: {
      specificDate: {
        generateDropdownLabel: columnKey => `${startCase(columnKey)} is the date specified below`,
      },
      beforeDate: {
        generateDropdownLabel: columnKey => `${startCase(columnKey)} is before the date specified below`,
      },
      afterDate: {
        generateDropdownLabel: columnKey => `${startCase(columnKey)} is after the date specified below`,
      },
      withinRange: {
        generateDropdownLabel: columnKey => `${startCase(columnKey)} is between the dates specified below`,
      },
    },
    generateChipLabel: (columnKey, configuration) => {
      const { dateFilterTypeKey, primaryDate, secondaryDate } = configuration;
      const chipLabels = {
        specificDate: `${startCase(columnKey)} is ${moment(primaryDate).format('DD/MM/YYYY LT')}`,
        beforeDate: `${startCase(columnKey)} is before ${moment(primaryDate).format('DD/MM/YYYY LT')}`,
        afterDate: `${startCase(columnKey)} is after ${moment(primaryDate).format('DD/MM/YYYY LT')}`,
        withinRange: `${startCase(columnKey)} is between ${moment(primaryDate).format('DD/MM/YYYY LT')} and ${moment(secondaryDate).format('DD/MM/YYYY LT')}`,
      };
      return chipLabels[dateFilterTypeKey];
    },
    checkValueMatchesFilterCriteria: (value, configuration) => {
      const { dateFilterTypeKey, primaryDate, secondaryDate } = configuration;
      const rowDate = moment(chrono.parseDate(value));
      const tests = {
        specificDate: () => rowDate.isSame(moment(primaryDate)),
        beforeDate: () => rowDate.isBefore(moment(primaryDate)),
        afterDate: () => rowDate.isAfter(moment(primaryDate)),
        withinRange: () => rowDate.isBetween(moment(primaryDate), moment(secondaryDate)),
      };
      return tests[dateFilterTypeKey]();
    },
    sortComparator: (value1, value2) => {
      const date1 = moment(chrono.parseDate(value1));
      const date2 = moment(chrono.parseDate(value2));
      return (moment(date1).isBefore(moment(date2)) ? -1 : 1);
    },
  },
  numeric: {
    numericFilterTypes: {
      equalTo: {
        generateDropdownLabel: columnKey => `${startCase(columnKey)} is equal to the value specified below`,
      },
      lessThan: {
        generateDropdownLabel: columnKey => `${startCase(columnKey)} is less than the value specified below`,
      },
      greaterThan: {
        generateDropdownLabel: columnKey => `${startCase(columnKey)} is greater than the value specified below`,
      },
    },
    generateChipLabel: (columnKey, configuration) => {
      const { numericFilterTypeKey, number } = configuration;
      const labels = {
        equalTo: `${startCase(columnKey)} is equal to ${number}`,
        lessThan: `${startCase(columnKey)} is less than ${number}`,
        greaterThan: `${startCase(columnKey)} is greater than ${number}`,
      };
      return labels[numericFilterTypeKey];
    },
    checkValueMatchesFilterCriteria: (value, configuration) => {
      const { numericFilterTypeKey, number } = configuration;
      // Ensure that the value from the row is actually a numeric value.
      if (typeof value !== 'number') {
        return false;
      }
      const tests = {
        equalTo: () => Number(value) === Number(number),
        lessThan: () => Number(value) < Number(number),
        greaterThan: () => Number(value) > Number(number),
      };
      return tests[numericFilterTypeKey]();
    },
    sortComparator: (value1, value2) => (value1 < value2 ? -1 : 1),
  },
};
// TODO:
// Validate in PropTypes that the column filterTypes are contained within Object.keys(COLUMN_TYPES).
// Validate in PropTypes that there is at least one column.

// =============================================== UTILITY FUNCTIONS ===================================================
const filterRows = (columns, rows, filters) => {
  let filteredRows = [...rows];
  filters.forEach(filter => {
    filteredRows = filteredRows.filter(row => COLUMN_TYPES[columns[filter.columnKey].filterType].checkValueMatchesFilterCriteria(row[filter.columnKey], filter.configuration));
  });
  return filteredRows;
};

const sortRows = (columns, rows, sortOptions) => {
  const sortedRows = [...rows];
  sortedRows.sort((row1, row2) => COLUMN_TYPES[columns[sortOptions.columnKey].filterType].sortComparator(row1[sortOptions.columnKey], row2[sortOptions.columnKey]));
  if (sortOptions.direction === 'descending') {
    sortedRows.reverse();
  }
  return sortedRows;
};

const findPossibleSelectFilterOptions = (columns, rows) => {
  // First clear the currentSelectableValues.
  COLUMN_TYPES = { ...COLUMN_TYPES, select: { ...COLUMN_TYPES.select, currentSelectableValues: [] } };

  // Find the columns that have the 'select' filter type.
  const columnsWithSelect = Object.keys(columns).filter(columnKey => columns[columnKey].filterType === 'select');

  // Create the sets to add possible option types to.
  const selectableValueSets = {};
  for (let i = 0; i < columnsWithSelect.length; i += 1) {
    selectableValueSets[columnsWithSelect[i]] = new Set();
  }

  // Add the value from each row for the 'select' columns to their respective sets.
  rows.forEach(row => {
    columnsWithSelect.forEach(columnKey => selectableValueSets[columnKey].add(row[columnKey]));
  });

  // Convert the sets to arrays and add these to the object using the columnKey as the key for the arrays.
  const selectableValues = {};
  columnsWithSelect.forEach(columnKey => { selectableValues[columnKey] = Array.from(selectableValueSets[columnKey]); });

  // Set these selectable types in COLUMN_TYPES.
  COLUMN_TYPES.select.currentSelectableValues = selectableValues;
};

// ================================================ TABLE COMPONENT ====================================================
const Table = props => {
  const {
    contextLabel, columns, rows, actions, primaryAction,
  } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [sortOptions, setSortOptions] = useState({ columnKey: Object.keys(columns)[0], direction: 'ascending' });
  const [filters, setFilters] = useState([]);
  const [processedRows, setProcessedRows] = useState(rows);

  // Filter and sort the rows whenever rows, sortOptions, or filters change.
  useEffect(() => {
    setCurrentPage(0);
    const filtered = filterRows(columns, rows, filters);
    const sorted = sortRows(columns, filtered, sortOptions);
    setProcessedRows(sorted);
  }, [rows, sortOptions, filters]);

  // For the columns with the 'select' filter type, find the possible options to select from.
  useEffect(() => {
    findPossibleSelectFilterOptions(columns, rows);
  }, [rows]);

  const onSortAscending = columnKey => setSortOptions({ columnKey, direction: 'ascending' });
  const onSortDescending = columnKey => setSortOptions({ columnKey, direction: 'descending' });
  const onAddFilter = newFilter => setFilters([...filters.filter(filter => filter.columnKey !== newFilter.columnKey), newFilter]);
  const onRemoveFilter = columnKey => setFilters(filters.filter(filter => filter.columnKey !== columnKey));
  const onClearAllFilters = () => setFilters([]);
  const onChangePage = (event, newPage) => setCurrentPage(newPage);
  const onChangeRowsPerPage = event => {
    setCurrentPage(0);
    setRowsPerPage(event.target.value);
  };

  const noRowsToDisplay = processedRows.length === 0;

  chrono.parseDate();

  return (
    <MainContainer>
      <SortAndFiltersPanel
        sortOptions={ sortOptions }
        filters={ filters }
        onRemoveFilter={ onRemoveFilter }
        columns={ columns }
      />
      <TableContainer>

        <MaterialTable stickyHeader>
          <Head
            columns={ columns }
            filters={ filters }
            contextLabel={ contextLabel }
            onAddFilter={ onAddFilter }
            onRemoveFilter={ onRemoveFilter }
            onSortAscending={ onSortAscending }
            onSortDescending={ onSortDescending }
          />
          { !noRowsToDisplay && (
            <Body
              contextLabel={ contextLabel }
              columns={ columns }
              rows={ processedRows }
              currentPage={ currentPage }
              rowsPerPage={ rowsPerPage }
              actions={ actions }
              primaryAction={ primaryAction }
            />
          )}
        </MaterialTable>

        { noRowsToDisplay && (
          <NoRowsMessageContainer>
            <NoRowsMessage
              contextLabel={ contextLabel }
              rows={ rows }
              filters={ filters }
              onClearAllFilters={ onClearAllFilters }
            />
          </NoRowsMessageContainer>
        )}

      </TableContainer>
      <Pagination
        contextLabel={ contextLabel }
        currentPage={ currentPage }
        rowsPerPage={ rowsPerPage }
        totalRows={ processedRows.length }
        onChangePage={ onChangePage }
        onChangeRowsPerPage={ onChangeRowsPerPage }
      />
    </MainContainer>
  );
};
export default Table;

Table.defaultProps = {
  actions: [],
};

Table.propTypes = {
  actions: PropTypes.array,
};

// ================================================= TABLE STYLING =====================================================
const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TableContainer = styled.div`
  height: 100%;
  overflow-x: auto;
`;

const NoRowsMessageContainer = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

// ================================================ HEAD COMPONENT =====================================================
const Head = props => {
  const {
    columns, filters, contextLabel, onAddFilter, onRemoveFilter, onSortAscending, onSortDescending,
  } = props;
  return (
    <StyledMaterialTableHead>
      <MaterialTableRow>
        {Object.keys(columns).map((columnKey, index) => (
          <MaterialTableCellHead key={ columns[columnKey].label } style={ index === 0 ? { width: 600 } : {} }>
            <CustomButton
              extraLarge
              color='lightBlue'
              variant='text'
              label={ columns[columnKey].label }
              icon={ DropdownArrowIcon }
              iconPosition='right'
              popover={ (
                <FilterPopover
                  filterType={ columns[columnKey].filterType }
                  columnKey={ columnKey }
                  contextLabel={ contextLabel }
                  currentFilter={ filters.find(filter => filter.columnKey === columnKey) }
                  onAddFilter={ onAddFilter }
                  onRemoveFilter={ onRemoveFilter }
                  onSortAscending={ onSortAscending }
                  onSortDescending={ onSortDescending }
                />
              ) }
            />
          </MaterialTableCellHead>
        ))}
      </MaterialTableRow>
    </StyledMaterialTableHead>
  );
};

// ================================================= HEAD STYLING ======================================================
const StyledMaterialTableHead = styled(MaterialTableHead)`
  flex-shrink: 0 !important;
`;

const MaterialTableCellHead = styled(MaterialTableCell)`
  background-color: white !important;
  color: ${props => props.theme.palette.darkBlue.main} !important;
  border-style: none !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
`;

// ================================================ BODY COMPONENT =====================================================
const Body = ({
  columns, rows, currentPage, rowsPerPage, actions, primaryAction,
}) => {
  const [hoveredRow, setHoveredRow] = useState(-1);
  const rowsOnCurrentPage = rows.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
  return (
    <MaterialTableBody>
      {rowsOnCurrentPage.map(row => (
        <MaterialTableRow
          key={ row.id }
          hover
          tabIndex={ -1 }
          onMouseEnter={ () => setHoveredRow(row.id) }
          onMouseLeave={ () => setHoveredRow(-1) }
        >
          {Object.keys(columns).map((columnKey, index) => (
            <MaterialTableCellBody key={ columnKey } align='left'>
              <MaterialTableCellBodyContent>
                {index === 0 && primaryAction ? (
                  <ClickableRowLabel onClick={ () => primaryAction(row) }>{row[columnKey]}</ClickableRowLabel>
                ) : (
                  row[columnKey]
                )}
                {index === 0 && hoveredRow === row.id && actions.length > 0 && (
                  <ActionsOverlay>
                    <TextFade />
                    <ActionsContainer>
                      {actions.map(action => (
                        <ActionButtonContainer key={ action.tooltipText }>
                          <IconButton
                            size='small'
                            icon={ action.icon }
                            color='lightBlue'
                            tooltipText={ action.tooltipText }
                            aria-label={ action.tooltipText }
                            onClick={ () => action.onClick(row) }
                          />
                        </ActionButtonContainer>
                      ))}
                    </ActionsContainer>
                  </ActionsOverlay>
                )}
              </MaterialTableCellBodyContent>
            </MaterialTableCellBody>
          ))}
        </MaterialTableRow>
      ))}
    </MaterialTableBody>
  );
};

// ================================================== BODY STYLING =====================================================
const MaterialTableCellBody = styled(MaterialTableCell)`
  padding-left: 42px !important;
  white-space: nowrap;
`;

const MaterialTableCellBodyContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const ActionsOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: -50px;
  pointer-events: none;    
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  pointer-events: auto;
  background-color: rgb(237, 237, 237);
`;

const ActionButtonContainer = styled.div`
  margin-left: 10px;
`;

const TextFade = styled.div`
  background-image: linear-gradient(to right, rgba(237, 237, 237, 0), rgba(237, 237, 237, 1));
  width: 80px;
  height: 100%;
`;

const ClickableRowLabel = styled.span`
  color: ${props => props.theme.palette.lightBlue.main};
  font-weight: 500;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

// ============================================== PAGINATION COMPONENT =================================================
const Pagination = ({
  contextLabel, currentPage, rowsPerPage, totalRows, onChangePage, onChangeRowsPerPage,
}) => (
  <StyledMaterialTablePagination
    rowsPerPageOptions={ ROWS_PER_PAGE_OPTIONS }
    component='div'
    count={ totalRows }
    rowsPerPage={ rowsPerPage }
    page={ currentPage }
    onChangePage={ onChangePage }
    onChangeRowsPerPage={ onChangeRowsPerPage }
    labelRowsPerPage={ `${contextLabel} per page:` }
    labelDisplayedRows={ ({ from, to, count }) => `Showing ${contextLabel.toLowerCase()} ${from} to ${to} of ${count}` }
  />
);

// =============================================== PAGINATION STYLING ==================================================
const StyledMaterialTablePagination = styled(MaterialTablePagination)`
  background-color: white !important;
  color: ${props => props.theme.palette.darkBlue.main} !important;
  border-style: none !important;
  font-size: 12pt !important;
  padding-right: 20px;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

// ========================================== NO ROWS MESSAGE COMPONENT ================================================
const NoRowsMessage = ({
  contextLabel, rows, filters, onClearAllFilters,
}) => {
  const rowsExist = rows.length !== 0;
  const filtersExist = filters.length > 0;
  const showClearFilters = rowsExist && filtersExist;
  const message = rowsExist ? (`No ${contextLabel.toLowerCase()} match the current filter criteria`) : (`No ${contextLabel.toLowerCase()} currently exist`);
  return (
    <NoRowsMessageContent>
      <NoRowsMainText>{message}</NoRowsMainText>
      {showClearFilters && (
        <ClearButtonsContainer>
          <ClearButton label='Clear All Filters' variant='text' color='lightBlue' onClick={ onClearAllFilters } />
        </ClearButtonsContainer>
      )}
    </NoRowsMessageContent>
  );
};

// =========================================== NO ROWS MESSAGE STYLING =================================================
const NoRowsMessageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NoRowsMainText = styled.div`
  font-size: 16pt;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
`;

const ClearButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 25px;
`;

const ClearButton = styled(CustomButton)`
  margin-left: 10px;
  margin-right: 10px;
`;

// ======================================== FILTER POPOVER CONTENT COMPONENT ===========================================
const FilterPopover = ({
  filterType, columnKey, contextLabel, currentFilter, onAddFilter, onRemoveFilter, onSortAscending, onSortDescending,
}) => {
  let filterFields;
  switch (filterType) {
    /**
     * Creates a filter based on a search bar.
     * Allows filtering rows by only showing rows where the search term is contained within the column value.
     */
    case 'search': {
      const onSearchTermChanged = newSearchTerm => {
        if (newSearchTerm === '') {
          onRemoveFilter(columnKey);
        } else {
          const filter = { columnKey, configuration: { searchTerm: newSearchTerm } };
          onAddFilter(filter);
        }
      };
      const currentSearchTerm = currentFilter ? currentFilter.configuration.searchTerm : '';
      filterFields = (
        <>
          <PopoverFieldLabel>{`Show ${contextLabel.toLowerCase()} where ${startCase(columnKey)} contains:`}</PopoverFieldLabel>
          <SearchBar
            placeholder='Enter a search term...'
            currentSearchTerm={ currentSearchTerm }
            onSearchTermChanged={ onSearchTermChanged }
          />
        </>
      );
      break;
    }

    /**
     * Creates a filter based on a select input.
     * Allows filtering rows by only showing rows where the column value matches the selected value.
     */
    case 'select': {
      const onSelectionChanged = event => {
        const newSelection = event.target.value;
        if (newSelection === 'Any') {
          onRemoveFilter(columnKey);
        } else {
          const filter = { columnKey, configuration: { selected: newSelection } };
          onAddFilter(filter);
        }
      };
      const selectableValues = COLUMN_TYPES.select.currentSelectableValues[columnKey];
      const currentSelection = currentFilter ? currentFilter.configuration.selected : 'Any';
      filterFields = (
        <>
          <PopoverFieldLabel>{`Show ${contextLabel.toLowerCase()} where ${startCase(columnKey)} is:`}</PopoverFieldLabel>
          <StyledSelect variant='outlined' margin='dense' value={ currentSelection } onChange={ onSelectionChanged }>
            <MenuItem value='Any'>
              {currentFilter ? <em>Any</em> : <span style={ { color: 'rgba(0, 0, 0, 0.54)' } }>Select an option...</span>}
            </MenuItem>
            {selectableValues.map(value => <MenuItem key={ value } value={ value }>{value}</MenuItem>)}
          </StyledSelect>
        </>
      );
      break;
    }

    /**
     * Creates a filter based on 'date ranges', 'date of', 'dates before', or 'dates after'.
     * Allows filtering rows by only showing rows where the date column value fits the aforementioned criteria.
     */
    case 'date': {
      const onDateFilterTypeChanged = event => {
        const newDateFilterType = event.target.value;
        if (newDateFilterType === 'none') {
          onRemoveFilter(columnKey);
        } else {
          const filter = {
            columnKey,
            configuration: {
              dateFilterTypeKey: newDateFilterType,
              primaryDate: currentFilter ? currentFilter.configuration.primaryDate : moment(),
              secondaryDate: currentFilter ? currentFilter.configuration.secondaryDate : moment().add(1, 'months'),
            },
          };
          onAddFilter(filter);
        }
      };
      const { dateFilterTypes } = COLUMN_TYPES.date;
      const currentDateFilterTypeKey = currentFilter ? currentFilter.configuration.dateFilterTypeKey : 'none';

      const onPrimaryDateChanged = date => {
        const newFilter = currentFilter;
        newFilter.configuration.primaryDate = date;
        onAddFilter(newFilter);
      };
      const onSecondaryDateChanged = date => {
        const newFilter = currentFilter;
        newFilter.configuration.secondaryDate = date;
        onAddFilter(newFilter);
      };
      const showPrimaryDatePicker = currentDateFilterTypeKey !== 'none';
      const showSecondaryDatePicker = showPrimaryDatePicker && currentDateFilterTypeKey === 'withinRange';

      filterFields = (
        <>
          <PopoverFieldLabel>{`Show ${contextLabel.toLowerCase()} where:`}</PopoverFieldLabel>
          <StyledSelect
            variant='outlined'
            margin='dense'
            value={ currentDateFilterTypeKey }
            onChange={ onDateFilterTypeChanged }
          >
            <MenuItem value='none'>
              {currentFilter ? <em>No filter</em> : <span style={ { color: 'rgba(0, 0, 0, 0.54)' } }>Select an option...</span>}
            </MenuItem>
            {Object.keys(dateFilterTypes).map(key => <MenuItem key={ key } value={ key }>{dateFilterTypes[key].generateDropdownLabel(columnKey)}</MenuItem>)}
          </StyledSelect>
          <Collapse in={ showPrimaryDatePicker }>
            <CollapseContents>
              <DatePickerField
                style={ { width: '100%' } }
                label={ showSecondaryDatePicker ? 'Start Date' : null }
                date={ currentFilter ? currentFilter.configuration.primaryDate : moment() }
                onDateCommitted={ onPrimaryDateChanged }
                enableFuture
              />
            </CollapseContents>
          </Collapse>
          <Collapse in={ showSecondaryDatePicker }>
            <CollapseContents>
              <DatePickerField
                style={ { width: '100%' } }
                label='End Date'
                date={ currentFilter ? currentFilter.configuration.secondaryDate : moment().add(1, 'months') }
                onDateCommitted={ onSecondaryDateChanged }
                enableFuture
              />

            </CollapseContents>
          </Collapse>
        </>
      );
      break;
    }

    /**
     * Creates a filter based on 'number equal to', 'number less than', or 'number greater than'.
     * Allows filtering rows by only showing rows where the numeric column value fits the aforementioned criteria.
     */
    case 'numeric': {
      const onNumericFilterTypeChanged = event => {
        const newNumericFilterTypeKey = event.target.value;
        if (newNumericFilterTypeKey === 'none') {
          onRemoveFilter(columnKey);
        } else {
          const filter = {
            columnKey,
            configuration: {
              numericFilterTypeKey: newNumericFilterTypeKey,
              number: currentFilter ? currentFilter.configuration.number : 0,
            },
          };
          onAddFilter(filter);
        }
      };
      const { numericFilterTypes } = COLUMN_TYPES.numeric;
      const currentNumericFilterTypeKey = currentFilter ? currentFilter.configuration.numericFilterTypeKey : 'none';

      const onNumberChanged = event => {
        const newNumber = event.target.value;
        if (newNumber === '') {
          onRemoveFilter(columnKey);
        } else {
          const newFilter = currentFilter;
          newFilter.configuration.number = Number(newNumber);
          onAddFilter(newFilter);
        }
      };
      const showNumberInput = currentNumericFilterTypeKey !== 'None';
      const currentNumber = currentFilter ? currentFilter.configuration.number : 0;

      filterFields = (
        <>
          <PopoverFieldLabel>{`Show ${contextLabel.toLowerCase()} where:`}</PopoverFieldLabel>
          <StyledSelect
            variant='outlined'
            margin='dense'
            value={ currentNumericFilterTypeKey }
            onChange={ onNumericFilterTypeChanged }
          >
            <MenuItem value='none'>
              {currentFilter ? <em>No filter</em> : <span style={ { color: 'rgba(0, 0, 0, 0.54)' } }>Select an option...</span>}
            </MenuItem>
            {Object.keys(numericFilterTypes).map(key => <MenuItem key={ key } value={ key }>{numericFilterTypes[key].generateDropdownLabel(columnKey)}</MenuItem>)}
          </StyledSelect>
          <Collapse in={ showNumberInput }>
            <CollapseContents>
              <TextField
                value={ currentNumber }
                placeholder='Enter a number...'
                onChange={ onNumberChanged }
                margin='dense'
                variant='outlined'
                style={ { width: '100%', margin: 0 } }
                type='number'
              />
            </CollapseContents>
          </Collapse>
        </>
      );
      break;
    }

    default:
      filterFields = <NoFilterOptionsMessage>No filter options are available for this column.</NoFilterOptionsMessage>;
  }


  const sortButtons = (
    <SortButtonContainer>
      <CustomButton
        label='Sort Ascending'
        variant='text'
        color='lightBlue'
        icon={ SortAscendingIcon }
        onClick={ () => onSortAscending(columnKey) }
      />
      <CustomButton
        label='Sort Descending'
        variant='text'
        color='lightBlue'
        icon={ SortDescendingIcon }
        onClick={ () => onSortDescending(columnKey) }
      />
    </SortButtonContainer>
  );

  return (
    <FilterPopoverContent>
      {filterFields}
      {sortButtons}
    </FilterPopoverContent>
  );
};

// ========================================= FILTER POPOVER CONTENT STYLING ============================================
const FilterPopoverContent = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  width: 400px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const NoFilterOptionsMessage = styled.div`
  color: rgba(0, 0, 0, 0.54);
`;

const CollapseContents = styled.div`
  width: 100%;
  height: 50px;
  margin-top: 10px;
`;

const PopoverFieldLabel = styled.div`
  font-size: 11pt;
  color: ${props => props.theme.palette.darkBlue.main};
  font-weight: 500;
  margin-bottom: 10px;
`;

const SortButtonContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

// =========================================== SORT AND FILTERS COMPONENT ==============================================
const SortAndFiltersPanel = ({
  sortOptions, filters, onRemoveFilter, columns,
}) => (
  <SortAndFiltersContentContainer>
    <SortingByContent>
      <SortAndFiltersLabel>Sorting by:</SortAndFiltersLabel>
      <FilterChipContainer>
        <FilterChip
          label={ `${startCase(sortOptions.columnKey)} (${startCase(sortOptions.direction)})` }
        />
      </FilterChipContainer>
    </SortingByContent>
    <FilterCriteriaContent>
      <SortAndFiltersLabel>Filter criteria:</SortAndFiltersLabel>
      {filters.length === 0 && (
        <FilterChipContainer>
          <NoFiltersAppliedLabel>No filters active, click a column title to add a filter</NoFiltersAppliedLabel>
        </FilterChipContainer>
      )}
      {filters.map(filter => (
        <FilterChipContainer key={ filter.columnKey }>
          <Grow in>
            <FilterChip
              label={ COLUMN_TYPES[columns[filter.columnKey].filterType].generateChipLabel(filter.columnKey, filter.configuration) }
              onDelete={ () => onRemoveFilter(filter.columnKey) }
            />
          </Grow>
        </FilterChipContainer>
      ))}
    </FilterCriteriaContent>
  </SortAndFiltersContentContainer>
);

// ============================================ SORT AND FILTERS STYLING ===============================================
const SortAndFiltersContentContainer = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;

const SortingByContent = styled.div`
  height: 100%;
  padding-left: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FilterCriteriaContent = styled.div`
  height: 100%;
  padding-left: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SortAndFiltersLabel = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 12pt;
  font-weight: 600;
`;

const NoFiltersAppliedLabel = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 11pt;
  font-style: italic;
`;

const FilterChip = styled(Chip)`
  font-weight: 500 !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
`;

const FilterChipContainer = styled.div`
  margin-left: 15px;
`;
