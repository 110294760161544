import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

export default ({
  placeholder, currentSearchTerm, onSearchTermChanged, width,
}) => (
  <TextField
    value={ currentSearchTerm }
    placeholder={ placeholder }
    onChange={ event => onSearchTermChanged(event.target.value) }
    margin='dense'
    variant='outlined'
    style={ { width: width || '100%', margin: 0 } }
    InputProps={ {
      startAdornment: (
        <InputAdornment position='start'>
          <SearchIcon color='primary' />
        </InputAdornment>
      ),
    } }
  />
);
