const styles = theme => {
  return {
    mainContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'stretch',
      height: 'calc(100vh - 60px)',
      width: '100%',
    },
    jumpNavigationContainer: {
      width: 210,
      height: '100%',
    },
    formsContainer: {
      flex: 3,
      // display: "flex",
      // flexDirection: "row"
    },
    formsContent: {
      height: '100%',
      width: '100%',
      // margin: "auto",
      // overflowY: "auto"
    },
    pdfContainer: {
      flex: 2,
      // Comment this for pdf to display
      display: 'flex',
      // display: 'none'
    },
    menu: { width: 88 },
  };
};

export default styles;
