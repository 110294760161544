import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { green } from '@material-ui/core/colors';
import ConsultationBuilder from './ConsultationBuilder';
import Preview from './Preview';
import Header from './Header';
import { saveConsultation, scrollTo, fetchConsultation } from '../../redux/actions/consultations';
import BasicLoader from '../../components/BasicLoader/BasicLoader';
import { getConsultation } from '../../redux/selectors/consultationSelectors';
import CustomButton from '../../components/CustomButton/CustomButton';
import PageTitle from '../../components/PageTitle/PageTitle';

const styles = () => ({
  titleContainer: {
    height: 70, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start',
  },
  dateCreatedContainer: { width: 'auto', marginRight: 10 },
  title: { height: '100%', textAlign: 'bottom' },
  bold: { fontWeight: 500, fontSize: 12 },
  light: { fontWeight: 300, fontSize: 12 },
});

function ConsultationForm(props) {
  const {
    consultationId, saveConsultation, scrollTo, classes, fetchConsultation, consultation,
  } = props;
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    console.log('loading consultation data');
    fetchConsultation(setInitialised);
  }, [fetchConsultation]);

  if (!initialised) return <BasicLoader label='Loading consultation builder...' />;

  return (
    <>
      <div style={ { display: 'flex', width: '100%', height: '100%' } }>
        <div style={ { display: 'flex', flexDirection: 'column', width: '50%' } }>
          <div className={ classes.titleContainer } style={ { paddingLeft: 70 } }>
            <PageTitle
              primaryText='Consultation Builder for'
              secondaryText={ (consultation.data.title ? consultation.data.title : 'Unnamed Consultation') }
              tooltip={ `Date Created: ${moment(consultation.created_at).format('LLLL')}` }
            />
            <CustomButton variant='contained' color='green' label='Save' onClick={ saveConsultation } style={ { marginLeft: 'auto', marginRight: 15 } } />
          </div>
          <Header builder onChange={ scrollTo } maxTotalNumber={ 99 } consultationId={ consultationId } />
          <ConsultationBuilder consultationId={ consultationId } />
        </div>

        <div style={ { width: '50%', display: 'flex', flexDirection: 'column' } }>
          <div className={ classes.titleContainer } style={ { paddingLeft: 15 } }>
            <PageTitle primaryText='Consultation Preview for' secondaryText={ (consultation.data.title ? consultation.data.title : 'Unnamed Consultation') } />
          </div>
          <Preview consultationId={ consultationId } />
        </div>
      </div>
    </>
  );
}

{ /* <div className={classes.dateCreatedContainer}>
<Typography variant="body1" className={classes.bold}>
  Date Created
</Typography>
<Typography variant="body1" className={classes.light}>
  {moment(consultation.created_at).format('LLLL')}
</Typography>
</div> */ }

ConsultationForm.propTypes = {
  created_at: PropTypes.string,
  saveConsultation: PropTypes.func.isRequired,
  consultationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const mapStateToProps = (state, { consultationId }) => ({ consultation: getConsultation(state, consultationId) });

const mapDispatchToProps = (dispatch, { consultationId }) => ({
  saveConsultation: () => dispatch(saveConsultation(consultationId)),
  scrollTo: value => dispatch(scrollTo(value, 'builder')),
  fetchConsultation: setInitialised => dispatch(fetchConsultation(consultationId, setInitialised)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(ConsultationForm));
