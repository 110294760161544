import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Menu from '@material-ui/core/Menu';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import { Typography, withStyles, Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import numberToWords from 'number-to-words';
import ThinSwitch from '../../components/ThinSwitch/ThinSwitch';
import CustomButton from '../../components/CustomButton/CustomButton';
import IconButton from '../../components/IconButton/IconButton';
import { updateFieldInQuestion } from '../../redux/actions/consultations';
import TypingTimeoutTextField from '../Forms/TypingTimeoutTextField.jsx';
import FormItemDecorator from '../Forms/FormItemDecorator';
import { updateSubmissionData } from '../../redux/actions/submissionForm';
import QuillFormItem from '../Forms/QuillFormItem';
import InputTypeSelector from './InputTypeSelector/InputTypeSelector';
import DeleteConfirmationDialog from '../../components/Dialogs/DeleteConfirmationDialog';

const width = 150;
const marginBottom = 75;

const styles = theme => ({
  label: { fontSize: 18 },
  divider: {
    height: 1,
    width: 'calc(100% + 40px)',
    marginLeft: -20,
    marginBottom: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },
  iconButton: {
    width: '32px', height: '32px',
  },
  icon: { color: 'rgb(51, 51, 51)', fontSize: '18pt' },
  switchContainer: {

  },
  deleteButtonContainer: {
    display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginRight: -10, marginBottom: -20,
  },
});

const types = {
  empty: 'None',
  radio: 'Single Choice',
  checkbox: 'Multiple Choice',
  // dropdown: 'Dropdown',
  // slider: 'Slider',
  // file: 'File upload',
};

function Question(props) {
  const {
    questionId,
    removeQuestion,
    updateFieldInQuestion,
    data,
    formErrors,
    classes,
    updateSubmissionData,
    sectionIndex,
    index,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setDialog(false);
  };

  const toggleDialog = () => {
    setAnchorEl(null);
    setDialog(true);
  };

  const remove = () => {
    removeQuestion(questionId);
    handleClose();
  };

  // Submission form data effect, this requires a update on
  useEffect(
    () => {
      updateSubmissionData();
      return () => {
        updateSubmissionData();
      };
    },
    // only update if input_type is different.
    [data.input_type, updateSubmissionData],
  );

  return (
    <div id={ `question-${questionId}-builder` }>
      <div className={ classes.divider } />
      <div className={ classes.deleteButtonContainer }>

        <Tooltip title={ `Delete question ${numberToWords.toWords(index + 1)}` }>
          <div style={ {
            display: 'flex', justifyContent: 'center', alignItems: 'center',
          } }
          >
            <CustomButton color='red' variant='text' label='Delete' onClick={ toggleDialog } aria-label='Delete Question Button' />
          </div>
        </Tooltip>

      </div>
      <DeleteConfirmationDialog
        open={ dialog }
        context='question'
        onCancel={ handleClose }
        onConfirm={ remove }
      />
      <FormItemDecorator
        label={ `Question ${numberToWords.toWords(index + 1)}` }
        description='The question to ask the submitter.'
        required
        error={ formErrors.title }
        labelSuffixComponent={ (
          <ThinSwitch
            label='Mandatory'
            checked={ data.required }
            onChange={ event => updateFieldInQuestion('required', event.target.checked) }
          />
        ) }
      >
        <TypingTimeoutTextField
          multiline
          initialValue={ data.title || '' }
          onDoneEditing={ value => updateFieldInQuestion('title', value) }
          rowsMax={ 3 }
          placeholder='Enter the question here...'
        />
      </FormItemDecorator>
      <FormItemDecorator label={ `Question ${numberToWords.toWords(index + 1)} description` } description='The description of the question.'>
        <QuillFormItem
          initialValue={ data.description || '' }
          onDoneEditing={ value => {
            updateFieldInQuestion('description', value);
          } }
          placeholder='Enter a description of the question here...'
        />
      </FormItemDecorator>
      <FormItemDecorator
        label='Select an answer method'
        description='The method of input the submitter will use to answer the question.'
        required
        error={ formErrors.input_type }
      >
        <InputTypeSelector questionId={ questionId } selectedType={ data.input_type || undefined } unknown={ !data.input_type } onInputTypeChange={ type => updateFieldInQuestion('input_type', type) } />
      </FormItemDecorator>
    </div>
  );
}

Question.propTypes = {
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  removeQuestion: PropTypes.func.isRequired,
  updateFieldInQuestion: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  section_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const mapStateToProps = (state, { questionId }) => state.consultations.questions[questionId];

const mapDispatchToProps = (dispatch, { questionId, consultationId }) => ({
  updateFieldInQuestion: (field, value) => dispatch(updateFieldInQuestion(questionId, field, value)),
  updateSubmissionData: () => dispatch(updateSubmissionData(consultationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Question));
