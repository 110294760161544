import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import MainNavigation from '../MainNavigation/MainNavigation.jsx';


const styles = theme => ({
  layoutWrapper: {
    width: '100%', height: '100%', display: 'flex', flexDirection: 'row',
  },
  child: { flex: 1, minHeight: 0, width: '100%' },
  page: {
    height: '100%', maxHeight: '100%', overflowY: 'auto', 'padding-left': '0',
  },
});

function Layout(props) {
  const { classes, children } = props;
  const pageIsPublic = props.history.location.pathname.startsWith('/public');
  return props.location.pathname !== '/login' ? (
    <div className={ classes.layoutWrapper }>
      {!pageIsPublic && <MainNavigation locationPath={ props.history.location.pathname } />}
      <div className={ classes.child }>
        <div className={ classes.page }>
          {children}
          {/* <div style={{ minHeight: 10000, backgroundColor: 'green' }}>123</div> */}
        </div>
      </div>
    </div>
  ) : (
    children
  );
}

export default withStyles(styles, { withTheme: true })(withRouter(Layout));
