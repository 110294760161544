// For offline processing page demos.

export const fakeSubmission = {
  id: 1,
  consultation_id: 1,
  created_at: '2019-07-30T12:18:06.622+12:00',
  submitter_id: 1,
  submission_source: 'Web Form',
  overall_position: 'Support in Part',
  submitter_type: 'Individual',
  attachments: [],
  answers: {
    '1': {
      topic_id: 1,
      notes: 'Without a shadow of a doubt',
      internal_notes: null,
      good_quote: false,
      id: 1,
      submission_clause_id: 1,
      associated_positions: ['Yes'],
    },
    '2': {
      topic_id: 1,
      notes: 'All of them',
      internal_notes: null,
      good_quote: false,
      id: 2,
      submission_clause_id: 2,
      associated_positions: [null],
    },
    '3': {
      topic_id: 1,
      notes: null,
      internal_notes: null,
      good_quote: false,
      id: 3,
      submission_clause_id: 3,
      associated_positions: ['It should be done soon'],
    },
    '4': {
      topic_id: 1,
      notes: 'Nothing to add',
      internal_notes: null,
      good_quote: false,
      id: 4,
      submission_clause_id: 4,
      associated_positions: [null],
    },
  },
};
export const fakeConsultationQuestion = {
  id: 1,
  title: 'Example Consultation',
  positions: {
    '2': {
      id: 2,
      text: 'Yes',
    },
    '3': {
      id: 3,
      text: 'No',
    },
    '16': {
      id: 16,
      text: 'It should be done soon',
    },
    '17': {
      id: 17,
      text: 'Wait until all options are explored',
    },
    '19': {
      id: 19,
      text: 'Unspecified / unsure',
    },
    '20': {
      id: 20,
      text: 'No comment',
    },
  },
  topics: {
    '1': {
      id: 1,
      description: 'General',
      order: 0,
      clauses: {
        '1': {
          id: 1,
          text: '2a(i) Think Minister should declare any ...',
          order: 0,
          is_multi: null,
          clause_positions: [2, 3, 19, 20],
        },
        '2': {
          id: 2,
          text: '2a(ii) Which ones?',
          order: 1,
          is_multi: null,
          clause_positions: [],
        },
        '3': {
          id: 3,
          text: '2b What timing do you think is appropr ...',
          order: 2,
          is_multi: null,
          clause_positions: [16, 17, 19, 20],
        },
        '4': {
          id: 4,
          text: '2c Information to improve this assessm ...',
          order: 3,
          is_multi: null,
          clause_positions: [],
        },
      },
    },
    '2': {
      id: 2,
      description: '1. Priority waste streams',
      order: 1,
      clauses: {
        '5': {
          id: 5,
          text: 'How do you feel about X?',
          order: null,
          is_multi: null,
          clause_positions: [],
        },
      },
    },
    '3': {
      id: 3,
      description: '2. Priority product declaration',
      order: 2,
      clauses: {
        '6': {
          id: 6,
          text: 'How can we improve X?',
          order: null,
          is_multi: null,
          clause_positions: [],
        },
      },
    },
    '4': {
      id: 4,
      description: '3. Scope of declaration: Electrical',
      order: 3,
      clauses: {
        '7': {
          id: 7,
          text: 'What can we do to ensure Y?',
          order: null,
          is_multi: null,
          clause_positions: [],
        },
      },
    },
  },
};

export const fakeSubmitter = {
  id: 1,
  address: '15 Ngatitoa Street, Tawa, Wellington 5028',
  phone: '04-555-1234',
  email: 'george.dewar@gmail.com',
  contact_person: null,
  organisation_id: 1,
  country_code: null,
  terms: 'George Dewar',
  given_names: 'George',
  surname: 'Dewar',
  company_name: null,
  region: null,
};

export const fakeConsultation1 = {
  consultation: {
    id: 1,
    primary_contact: 'Shaylee Bogisich PhD',
    data: {
      title: 'monetize Virginia Buckinghamshire',
      description:
        '<p>Et soluta qui et laborum deleniti ut voluptates in. Provident voluptatem doloremque veritatis est libero iste voluptas. Voluptas quibusdam et. Mollitia minus dolore magni in fuga. Quisquam porro possimus magnam id omnis. Est ipsam nostrum optio quas quaerat laborum.\n \rAperiam deserunt fugit ut beatae. Repellendus velit magni consequuntur dolorem aperiam officia illum magni est. Voluptatem harum iure quia.\n \rQuod voluptatum sit odio autem dolores inventore alias. Quaerat illo rerum ut voluptatem. Et odio at et placeat consectetur corrupti fugit. Illum aliquam aspernatur magnam ut est nam. Aspernatur sit quod qui sit eius amet aliquid in. Ipsam a repellat error sunt in autem.</p>',
      status: 'Draft',
      privacy_statement:
        '<p>Voluptas enim recusandae illo est qui aut rerum sunt voluptatem. Doloremque voluptatem accusamus soluta cumque excepturi quis facilis voluptas odit. Vel et quasi ad. Pariatur dolor et praesentium. Hic fugiat odit accusantium soluta at eius ut.</p>',
      consultation_dates: {
        open: '2018-11-06T13:39:45.440Z',
        close: '2020-01-28T00:39:27.174Z',
      },
      grace_period: 6,
      emails: {
        original_receiver: 'Raymundo_Parisian27@example.com',
        notification: 'Tyrell.Green@example.net',
      },
      assets: {
        supporting_documents: [
          {
            lastModified: 1567388450,
            lastModifiedDate: 1567388450019,
            name: 'global_home_loan_account.rdz',
            size: 82894,
            webkitRelativePath: '',
          },
          {
            lastModified: 1567388450,
            lastModifiedDate: 1567388450021,
            name: 'mobile.vcf',
            size: 95976,
            webkitRelativePath: '',
          },
          {
            lastModified: 1567388450,
            lastModifiedDate: 1567388450023,
            name: 'operations_multi_byte.msi',
            size: 59860,
            webkitRelativePath: '',
          },
        ],
        logo: {
          lastModified: 1567388450,
          lastModifiedDate: 1567388450025,
          name: 'zero_administration_mouse_invoice.wav',
          size: 24060,
          webkitRelativePath: '',
        },
      },
      required_contact_fields: {
        given_or_company_name: true,
        surname: false,
        contact_person: false,
        address: true,
        region: true,
        country: true,
        phone: false,
        email: true,
      },
      submitter_types: {
        allow_public_selection: false,
        required: true,
        options: [
          {
            label: 'HDD Supervisor',
            value: 'ffe8b4ba-8da6-4fca-bc62-1f8a1f78d068',
          },
          {
            label: 'Intelligent Fresh Cheese embrace complexity',
            value: '104413e4-d903-4c6d-bf36-97e08f2cf27a',
          },
          {
            label: 'integrated',
            value: 'c15ca723-a554-4900-8662-06aab3fee60e',
          },
        ],
      },
    },
    formErrors: {},
    section_ids: [
      'b67bb51f-085e-4578-9984-32302cd2072b',
      '1a1eee71-838b-4b32-8289-c393c3db67ef',
      '657a3357-0bce-473a-a573-5b946c0de8b3',
      'b2038522-a3eb-4495-a632-ee7f33a8898e',
      '72a862e2-d56e-48ca-b659-b242455b18eb',
    ],
  },
  sections: {
    'b67bb51f-085e-4578-9984-32302cd2072b': {
      id: 'b67bb51f-085e-4578-9984-32302cd2072b',
      data: {
        title: 'B2C',
        description:
          'Iure magni reprehenderit dolorem quisquam officia rerum velit dolores iste. Cumque recusandae adipisci dolorem sint soluta. Vel in quis facilis nam.',
      },
      formErrors: {},
      question_ids: ['c26c73e9-0c1e-40eb-945f-bd201cff7fbd', 'd15aed9e-5a0e-4988-b66c-c8c1a0f302a0'],
      consultation_id: 1,
    },
    '1a1eee71-838b-4b32-8289-c393c3db67ef': {
      id: '1a1eee71-838b-4b32-8289-c393c3db67ef',
      data: {
        title: 'navigate',
        description:
          'Et est qui commodi repellat eos quia veniam. Odit doloremque aut. Labore nesciunt est aut laudantium nemo officiis veritatis. Eaque et quam. Vel quia aperiam dolores distinctio velit. Unde molestiae consequatur et sit sint velit dignissimos dolorum.',
      },
      formErrors: {},
      question_ids: [
        '3ab117bf-32ae-4e40-a888-ff4811db0d4a',
        'aea572fb-b308-4128-9a2a-cffdd2c81a03',
        'e8df7af0-eca0-4162-9722-13064b104a97',
        '4fb1625c-233a-4b6c-bd43-4245b5a4075c',
      ],
      consultation_id: 1,
    },
    '657a3357-0bce-473a-a573-5b946c0de8b3': {
      id: '657a3357-0bce-473a-a573-5b946c0de8b3',
      data: {
        title: 'lime monitor',
        description:
          'Voluptatibus hic totam laudantium eos esse. Sint vel minima autem doloribus itaque et. Animi id excepturi explicabo similique. Sint quo suscipit magnam voluptatum voluptatem consequuntur. Enim consequatur beatae laudantium sed tempora. Incidunt eos aut iure cupiditate autem.',
      },
      formErrors: {},
      question_ids: [
        '4c1fa150-bb95-4ccb-a283-6e7a54e83c75',
        '161a34d0-b7b6-4938-9f59-af960fd37d6e',
        'b5b6563c-5c67-44bc-9be6-705e83f71c8d',
      ],
      consultation_id: 1,
    },
    'b2038522-a3eb-4495-a632-ee7f33a8898e': {
      id: 'b2038522-a3eb-4495-a632-ee7f33a8898e',
      data: {
        title: 'Inlet',
        description:
          'Voluptates animi quo voluptates odit. Et maxime iusto veritatis voluptas molestias dolores aliquam quo. Accusamus cumque qui minus sed ipsam. Aut laudantium dicta impedit.',
      },
      formErrors: {},
      question_ids: [
        'a8066d9e-bee9-4187-9576-60c2fb21293e',
        '19682c1e-78d3-4886-a3cb-28b5a14d57b6',
        '504373a3-869c-414c-ab4d-321acfc4eda9',
        'aeb6b104-2dee-4244-8325-470e15519d5a',
        '57bf202b-bf5a-49f4-ae8a-4ed089b1440c',
        'fa29be86-39c4-4a55-bc8f-37c49d3eea27',
      ],
      consultation_id: 1,
    },
    '72a862e2-d56e-48ca-b659-b242455b18eb': {
      id: '72a862e2-d56e-48ca-b659-b242455b18eb',
      data: {
        title: 'responsive bleeding-edge archive',
        description:
          'Et consequuntur asperiores qui rerum minima accusamus sit. Id laudantium laborum. Harum qui dolor sint molestiae qui sed magni recusandae. Vero illo sunt voluptas et quasi. Porro inventore non sit minus. Repellendus ab itaque quam earum fugiat quasi cupiditate voluptates voluptatum.',
      },
      formErrors: {},
      question_ids: [
        'f398ae01-5ee1-4356-bda2-34094a86ad9e',
        '926ad49e-dec4-4c09-9f7a-685ecfaaeb56',
        'f1e48bcf-9e91-495d-b598-56c88d04398d',
        '4efd1018-dbb0-4ef6-8d1e-b3b9b947689e',
      ],
      consultation_id: 1,
    },
  },
  questions: {
    'c26c73e9-0c1e-40eb-945f-bd201cff7fbd': {
      id: 'c26c73e9-0c1e-40eb-945f-bd201cff7fbd',
      data: {
        title: 'GB',
        description:
          'Unde tenetur error odio qui consequatur. Magni laborum hic corporis necessitatibus. Et ut iure. Sit nostrum fugit officiis rerum maxime et.',
        helper_text: 'Polarised e-markets fresh-thinking',
        input_type: 'Multiple Choice',
        required: false,
        input_options: [
          {
            id: '30691022-4769-4418-b74c-d046929a5cf3',
            value: 'South Dakota integrate',
            order: -1,
          },
          {
            id: '0c2e0435-90aa-45cc-a620-f4ad7ef0cbde',
            value: 'Belarus',
            order: -1,
          },
          {
            id: 'e5c62699-1a31-4b65-a5de-46c5f977035c',
            value: 'deposit Checking Account',
            order: -1,
          },
          {
            id: '7e95299a-eddf-41e1-af48-0d97ad6e7e87',
            value: 'engineer Maine',
            order: -1,
          },
          {
            id: 'fc6e3f94-8e4a-40ff-af3a-7e3169451c43',
            value: 'strategic Checking Account',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'b67bb51f-085e-4578-9984-32302cd2072b',
    },
    'd15aed9e-5a0e-4988-b66c-c8c1a0f302a0': {
      id: 'd15aed9e-5a0e-4988-b66c-c8c1a0f302a0',
      data: {
        title: 'Key Beauty',
        description:
          'Fugiat id voluptates molestias fuga maxime earum sed. Dolor quia enim quia nostrum aut. Ab optio qui assumenda deserunt aut. Unde consequatur itaque mollitia. Et consequuntur at rem dolorem vitae omnis vel ab et.',
        helper_text: 'Orchestrator',
        input_type: 'Multiple Choice',
        required: true,
        input_options: [
          {
            id: '5dc99ad4-86ed-49a0-bfbf-05f2b12f65d9',
            value: 'bypassing Concrete Analyst',
            order: -1,
          },
          {
            id: '8c374341-2b0e-4f18-86d8-1c88a8a9d397',
            value: 'Fresh Focused',
            order: -1,
          },
          {
            id: 'fe7ec9b6-3dd0-4ec5-822c-bd4075084a6c',
            value: 'overriding',
            order: -1,
          },
          {
            id: '8f0d3e3b-d517-4a8a-a55c-cb0e3a7dd1d7',
            value: 'visionary Multi-channelled',
            order: -1,
          },
          {
            id: '93047ce0-048a-4deb-93af-74742c379d5a',
            value: 'Slovakia (Slovak Republic)',
            order: -1,
          },
          {
            id: 'da2dd1e2-8da6-4daa-9c82-b00ec28ccf3f',
            value: 'invoice',
            order: -1,
          },
          {
            id: '8cdd809c-8ae2-4c9e-8db0-b8139f544aa5',
            value: 'Human',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'b67bb51f-085e-4578-9984-32302cd2072b',
    },
    '3ab117bf-32ae-4e40-a888-ff4811db0d4a': {
      id: '3ab117bf-32ae-4e40-a888-ff4811db0d4a',
      data: {
        title: 'vertical Corporate out-of-the-box',
        description:
          'Maiores omnis provident odio nesciunt autem aut et. Qui tenetur sed corporis excepturi non aliquam et aspernatur. Velit animi nam cum quaerat laborum facilis. Quas sit debitis ut necessitatibus blanditiis eos.',
        helper_text: 'Ergonomic Rubber Car partnerships mobile',
        input_type: 'Checkboxes',
        required: true,
        input_options: [
          {
            id: 'b3448446-45c6-4c12-9e29-ba1685316570',
            value: 'Home parsing Avon',
            order: -1,
          },
          {
            id: '119b9ce9-124c-470c-9f5c-633fde831466',
            value: 'black',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '1a1eee71-838b-4b32-8289-c393c3db67ef',
    },
    'aea572fb-b308-4128-9a2a-cffdd2c81a03': {
      id: 'aea572fb-b308-4128-9a2a-cffdd2c81a03',
      data: {
        title: 'salmon',
        description:
          'Voluptas nisi dolor itaque qui. Consectetur exercitationem quas qui est eos. Maxime aperiam ex commodi sapiente. Voluptatibus libero magni natus.',
        helper_text: 'Internal synthesizing neural',
        input_type: 'Checkboxes',
        required: false,
        input_options: [
          {
            id: '0ff2a716-ec82-4518-97cf-3a45e8b4981a',
            value: 'Analyst synthesize SAS',
            order: -1,
          },
          {
            id: '08e7d767-9346-40b3-95c8-857a93dc3f11',
            value: 'hard drive Legacy Tennessee',
            order: -1,
          },
          {
            id: '5b70e845-5b52-4d83-8a66-f03d4f637d1a',
            value: 'Jewelery',
            order: -1,
          },
          {
            id: '3f6800b2-db00-4c65-8fc1-e49756c8f4b6',
            value: 'deposit eco-centric ROI',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '1a1eee71-838b-4b32-8289-c393c3db67ef',
    },
    'e8df7af0-eca0-4162-9722-13064b104a97': {
      id: 'e8df7af0-eca0-4162-9722-13064b104a97',
      data: {
        title: 'Rand Loti Home Loan Account homogeneous',
        description:
          'Et provident ipsa atque harum molestias incidunt et. Magni sed debitis voluptatem. Sint qui et eveniet molestiae nam doloribus.',
        helper_text: 'ability withdrawal plum',
        input_type: 'Multiple Choice',
        required: true,
        input_options: [
          {
            id: '4e76cf8d-f9c9-4414-a3aa-9c0ebc2e97fe',
            value: 'Music',
            order: -1,
          },
          {
            id: 'ef7025e6-25dc-4e57-ae45-3fa68c8fd52f',
            value: 'copy bus',
            order: -1,
          },
          {
            id: 'becbb99c-f54b-43fd-8f1b-3b2678829d9d',
            value: 'didactic Orchestrator',
            order: -1,
          },
          {
            id: '053c83ad-2da5-4d38-9235-ad7ac12ce972',
            value: 'streamline',
            order: -1,
          },
          {
            id: 'f3bc9075-f7c6-4264-8adb-812398530048',
            value: 'attitude-oriented',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '1a1eee71-838b-4b32-8289-c393c3db67ef',
    },
    '4fb1625c-233a-4b6c-bd43-4245b5a4075c': {
      id: '4fb1625c-233a-4b6c-bd43-4245b5a4075c',
      data: {
        title: 'Cameroon help-desk invoice',
        description:
          'Nihil sint facilis quam perferendis ratione numquam rerum. Dicta a architecto dolor cum quia veniam. Velit id dolor aut voluptate impedit expedita. Voluptatum atque ipsam officiis quidem earum voluptas. Ut quo aut accusamus sunt animi veniam. Inventore cumque nesciunt enim suscipit.',
        helper_text: 'schemas payment payment',
        input_type: 'Multiple Choice',
        required: false,
        input_options: [
          {
            id: '1c4de180-269d-4b04-bf86-c7c3eaa04b31',
            value: 'driver',
            order: -1,
          },
          {
            id: '469b3ea4-26f2-4a1e-895c-c4422991d351',
            value: 'users Berkshire Functionality',
            order: -1,
          },
          {
            id: '021fff60-e9a9-4730-8bda-f786c8da6b67',
            value: 'CSS infrastructures',
            order: -1,
          },
          {
            id: '67189308-e589-4fc2-8520-964b3c943d0e',
            value: 'markets Tools',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '1a1eee71-838b-4b32-8289-c393c3db67ef',
    },
    '4c1fa150-bb95-4ccb-a283-6e7a54e83c75': {
      id: '4c1fa150-bb95-4ccb-a283-6e7a54e83c75',
      data: {
        title: 'Clothing Operative Operations',
        description:
          'Consequatur sequi nemo minus quis aliquid dolorem. Porro et veniam facilis dolor ut quidem dolores quidem. Delectus ut animi sequi amet vero adipisci quo ad molestiae. Sint sequi error in eius laborum facere omnis. Occaecati ut fuga et veritatis qui laudantium est quia voluptas. Nobis dolorem itaque autem.',
        helper_text: 'Cambridgeshire Checking Account index',
        input_type: 'Multiple Choice',
        required: true,
        input_options: [
          {
            id: '969487b1-ca32-4eac-8c3b-eafc581c7ea4',
            value: 'Connecticut',
            order: -1,
          },
          {
            id: 'e9b0159b-9e20-4679-a6f1-87385615a4cf',
            value: 'e-tailers',
            order: -1,
          },
          {
            id: 'ce16a51a-f5b6-4f1f-b22e-4e74ce635fa4',
            value: 'deposit markets payment',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '657a3357-0bce-473a-a573-5b946c0de8b3',
    },
    '161a34d0-b7b6-4938-9f59-af960fd37d6e': {
      id: '161a34d0-b7b6-4938-9f59-af960fd37d6e',
      data: {
        title: 'Kwacha Afghani',
        description:
          'Quia sequi quod iure officia libero. Et fuga alias vitae nostrum qui sunt beatae eligendi. Nisi ratione quos eligendi velit rerum sed odio. Consequatur doloremque quasi at aut sunt.',
        helper_text: 'port FTP Dobra',
        input_type: 'Checkboxes',
        required: true,
        input_options: [
          {
            id: '0013acde-c5b1-493c-b6f3-285a851df306',
            value: 'copy',
            order: -1,
          },
          {
            id: '3a67fb12-5dd4-4e5d-aa31-1304f57d54b7',
            value: 'feed',
            order: -1,
          },
          {
            id: '1e9e489b-c5db-4aa8-8072-28e7f0522ae8',
            value: 'Intelligent Brooks leverage',
            order: -1,
          },
          {
            id: 'd7c6cc2a-2a28-4322-b6ea-e7f1a804c02b',
            value: 'Rustic Wooden Chicken',
            order: -1,
          },
          {
            id: '79c84ecf-b7a4-419d-a14e-b227c877da96',
            value: 'protocol',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '657a3357-0bce-473a-a573-5b946c0de8b3',
    },
    'b5b6563c-5c67-44bc-9be6-705e83f71c8d': {
      id: 'b5b6563c-5c67-44bc-9be6-705e83f71c8d',
      data: {
        title: 'web-enabled Engineer invoice',
        description:
          'Similique reiciendis quae ut voluptatum enim saepe voluptas eligendi dolorem. Officiis necessitatibus qui ullam vero quis et. Ipsam eligendi odit voluptas explicabo aspernatur tempore non eum. Animi quis ipsam ex nihil ut deserunt. Molestiae aut voluptas veniam qui doloribus aperiam aliquid cumque. Et est excepturi velit exercitationem ea ipsa.',
        helper_text: 'Paraguay Product Fish',
        input_type: 'Multiple Choice',
        required: true,
        input_options: [
          {
            id: 'beca3840-f471-4a42-9389-02eec3c48f5d',
            value: 'Data bottom-line Cotton',
            order: -1,
          },
          {
            id: '07e9a952-bfcc-4e39-9e77-00a212c8d8bb',
            value: 'array solid state solid state',
            order: -1,
          },
          {
            id: 'ddb89bfe-5cac-4e48-ae1a-53911b7ef65f',
            value: 'Incredible Plastic Shoes',
            order: -1,
          },
          {
            id: '30fbb0ad-f308-48c8-a3a1-215d6ff2e28d',
            value: 'conglomeration Fresh generate',
            order: -1,
          },
          {
            id: 'c8980a96-e24d-4230-8f35-efe454f3620d',
            value: 'transmitting leverage',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '657a3357-0bce-473a-a573-5b946c0de8b3',
    },
    'a8066d9e-bee9-4187-9576-60c2fb21293e': {
      id: 'a8066d9e-bee9-4187-9576-60c2fb21293e',
      data: {
        title: 'Village Avon Station',
        description:
          'Eligendi numquam quo corrupti quo fuga laborum. Voluptates molestias deleniti. Esse ratione iusto id sunt id rerum non deleniti. Ut voluptas unde ea quam porro eos corporis. Quis aliquam animi quas corporis eligendi. Quia quam aut deserunt placeat earum explicabo pariatur hic.',
        helper_text: 'Argentine Peso Money Market Account',
        input_type: 'Checkboxes',
        required: true,
        input_options: [
          {
            id: '73958fc4-b219-478f-b91d-019246190b91',
            value: 'input navigating',
            order: -1,
          },
          {
            id: '4596fef2-e765-4754-a9f0-bf91b390cc5f',
            value: 'Bedfordshire Investment Account Ergonomic',
            order: -1,
          },
          {
            id: 'f2ecc8e9-dc08-460d-b3e1-09325b3d726f',
            value: 'Granite',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'b2038522-a3eb-4495-a632-ee7f33a8898e',
    },
    '19682c1e-78d3-4886-a3cb-28b5a14d57b6': {
      id: '19682c1e-78d3-4886-a3cb-28b5a14d57b6',
      data: {
        title: 'grey',
        description:
          'Et laboriosam quis deserunt provident voluptas. Voluptates eveniet doloribus aut fugiat dolores maxime. Placeat sed ullam sed ut quidem. Nostrum distinctio deleniti eaque aliquam et. Velit vel tempore sed commodi repellat inventore voluptatum.',
        helper_text: 'hierarchy withdrawal',
        input_type: 'Checkboxes',
        required: true,
        input_options: [
          {
            id: '350c96b8-e9ff-4c9a-87a5-3db2f0c0196a',
            value: 'Investment Account scalable Dynamic',
            order: -1,
          },
          {
            id: 'f6f19478-9b7c-42f4-a62e-5ab7c7a233c8',
            value: 'Central high-level Research',
            order: -1,
          },
          {
            id: 'e3821de2-c39d-482e-a6fd-8a2be99e149d',
            value: 'orchid',
            order: -1,
          },
          {
            id: '04cdf0a1-cb92-49db-b1fa-293926644fc8',
            value: 'Sausages',
            order: -1,
          },
          {
            id: '297a68f9-fb87-40ca-862c-bc670dd33d18',
            value: 'Assistant',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'b2038522-a3eb-4495-a632-ee7f33a8898e',
    },
    '504373a3-869c-414c-ab4d-321acfc4eda9': {
      id: '504373a3-869c-414c-ab4d-321acfc4eda9',
      data: {
        title: 'web-readiness Wisconsin',
        description:
          'Inventore qui corrupti fuga ducimus molestias quia dolor voluptas molestiae. Excepturi consequatur non commodi aperiam et sunt architecto ea. Magnam quia praesentium. Veniam et vel et voluptatibus a hic quod ab est. Assumenda dolores et eum amet asperiores consequatur quo omnis. Eligendi perferendis similique aliquid vitae fugiat qui labore amet.',
        helper_text: 'bluetooth Wooden Ergonomic',
        input_type: 'Multiple Choice',
        required: true,
        input_options: [
          {
            id: 'f1acd817-72e9-4890-9b0d-778b73b2a7f5',
            value: 'invoice Central',
            order: -1,
          },
          {
            id: '5c8afa71-645b-47b8-9bca-0cbe980b9b3b',
            value: 'Pound Sterling Functionality seamless',
            order: -1,
          },
          {
            id: 'ada06528-edc1-4305-b7ac-2936ad066a1d',
            value: 'Engineer Falkland Islands Pound Avon',
            order: -1,
          },
          {
            id: '077919a3-c5a3-43b0-b58b-aa15660e6ee5',
            value: 'FTP streamline',
            order: -1,
          },
          {
            id: '3eba45dd-5f21-4db4-adeb-b4d3a0bd3e3d',
            value: 'synergy Metal',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'b2038522-a3eb-4495-a632-ee7f33a8898e',
    },
    'aeb6b104-2dee-4244-8325-470e15519d5a': {
      id: 'aeb6b104-2dee-4244-8325-470e15519d5a',
      data: {
        title: 'Electronics',
        description:
          'Eum corporis sint quas beatae est. Doloribus sed incidunt eum. Laborum et quis voluptatem nihil qui enim. Ipsam voluptate exercitationem et facilis. Pariatur distinctio odio odio doloremque quia et explicabo repellendus perspiciatis. Possimus delectus harum qui aut tempora omnis cumque et quo.',
        helper_text: 'Electronics even-keeled',
        input_type: 'Checkboxes',
        required: true,
        input_options: [
          {
            id: '9b26014d-c805-4462-a7e3-88fe3c973c93',
            value: 'Avon array Handcrafted',
            order: -1,
          },
          {
            id: 'f6b4bcc2-5a3f-4018-8427-bbd39dcaf9cf',
            value: 'South Dakota Rustic Wooden Tuna Handcrafted Cotton Computer',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'b2038522-a3eb-4495-a632-ee7f33a8898e',
    },
    '57bf202b-bf5a-49f4-ae8a-4ed089b1440c': {
      id: '57bf202b-bf5a-49f4-ae8a-4ed089b1440c',
      data: {
        title: 'system mobile',
        description:
          'Hic suscipit ab aut cupiditate. Est aut et vero nesciunt voluptatem. Quia sint beatae. Aut libero consequatur doloribus omnis sed perferendis. Unde sed ut culpa ut modi.',
        helper_text: 'feed',
        input_type: 'Multiple Choice',
        required: false,
        input_options: [
          {
            id: 'a248a765-52a4-43cc-bdac-b67e393e750b',
            value: 'Factors',
            order: -1,
          },
          {
            id: 'c11c3dcb-3370-4b6e-9c3b-22061cf7bbd8',
            value: 'engineer',
            order: -1,
          },
          {
            id: '0b31f5f1-9f9e-45f2-9bfc-cd3f2ae6995f',
            value: 'Auto Loan Account',
            order: -1,
          },
          {
            id: '594bf050-d31b-469c-9cae-aabe062332e3',
            value: 'Product Tasty Soft Towels Fantastic Rubber Soap',
            order: -1,
          },
          {
            id: '36a401b3-fbe9-455e-a5e1-20cd0b0955b8',
            value: 'Tasty Avon',
            order: -1,
          },
          {
            id: '1b87146b-0ec9-4b16-a9a9-2e722c9a07b7',
            value: 'primary',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'b2038522-a3eb-4495-a632-ee7f33a8898e',
    },
    'fa29be86-39c4-4a55-bc8f-37c49d3eea27': {
      id: 'fa29be86-39c4-4a55-bc8f-37c49d3eea27',
      data: {
        title: 'User-friendly XML Senior',
        description:
          'Et ad laudantium commodi. Consequatur aperiam laborum quia ut. Explicabo eos alias. Qui quis eligendi veniam itaque.',
        helper_text: 'Handcrafted Granite Chips portals transmitting',
        input_type: 'Checkboxes',
        required: true,
        input_options: [
          {
            id: 'b86c6041-4876-41e7-9618-f4e9de3edf46',
            value: 'neural lavender',
            order: -1,
          },
          {
            id: '38659715-b0ec-482e-8aec-6366bb394bea',
            value: 'Beauty',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'b2038522-a3eb-4495-a632-ee7f33a8898e',
    },
    'f398ae01-5ee1-4356-bda2-34094a86ad9e': {
      id: 'f398ae01-5ee1-4356-bda2-34094a86ad9e',
      data: {
        title: 'Rustic Granite Computer Program Licensed Fresh Ball',
        description:
          'Ex iusto delectus culpa nulla. Alias consectetur dolores amet non velit. Quia similique et et error ut ut. Laborum cupiditate asperiores est. Alias ut animi iste dolorum labore aspernatur soluta qui fuga. Impedit quidem error voluptas explicabo assumenda ducimus fugit sit tenetur.',
        helper_text: 'facilitate magnetic',
        input_type: 'Checkboxes',
        required: true,
        input_options: [
          {
            id: 'b6671ee3-d3df-4c89-9b4c-9b90cb84dd98',
            value: 'Architect Equatorial Guinea',
            order: -1,
          },
          {
            id: 'e99772ed-6b4f-4138-b258-a0c6bdaff693',
            value: 'parsing Indian Rupee Borders',
            order: -1,
          },
          {
            id: '7fb2dd00-7211-461d-9fe3-903bb5657fa5',
            value: 'Table',
            order: -1,
          },
          {
            id: '43fe69cb-8914-46e7-9421-4c4d1eabcbe9',
            value: 'digital Cotton Chair',
            order: -1,
          },
          {
            id: '23dccd3a-7f7f-4138-881b-1fc12dfeceb4',
            value: 'multi-byte portal indexing',
            order: -1,
          },
          {
            id: '7a90b8a5-a94d-4408-8ba9-de80586a6364',
            value: 'bandwidth Lesotho Inlet',
            order: -1,
          },
          {
            id: '3279c08d-0f00-4e83-9b87-dd8f2e0e132b',
            value: 'Applications',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '72a862e2-d56e-48ca-b659-b242455b18eb',
    },
    '926ad49e-dec4-4c09-9f7a-685ecfaaeb56': {
      id: '926ad49e-dec4-4c09-9f7a-685ecfaaeb56',
      data: {
        title: 'Creative',
        description:
          'Necessitatibus labore amet. Consequatur minima delectus corrupti quo. Veritatis ea itaque et reiciendis dolorem eveniet aspernatur.',
        helper_text: 'Intelligent Sweden',
        input_type: 'Checkboxes',
        required: true,
        input_options: [
          {
            id: 'd7fc235d-ee23-4948-8a52-b4b7db0c56c1',
            value: 'Factors Granite red',
            order: -1,
          },
          {
            id: 'e255159d-a557-42a2-bca0-818d27533c44',
            value: 'Credit Card Account neural Distributed',
            order: -1,
          },
          {
            id: '6eaf6e4c-a47b-4c86-a044-a31698d99f1c',
            value: 'Lead',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '72a862e2-d56e-48ca-b659-b242455b18eb',
    },
    'f1e48bcf-9e91-495d-b598-56c88d04398d': {
      id: 'f1e48bcf-9e91-495d-b598-56c88d04398d',
      data: {
        title: 'relationships solution',
        description:
          'Dignissimos minus velit illo harum aut pariatur ut. Minus labore mollitia qui atque temporibus qui sed aspernatur maxime. Fugit id eum nihil. Magnam cupiditate eum consequatur enim optio sed esse. Cupiditate velit nam est ut consequatur. Corporis sunt consequuntur earum.',
        helper_text: 'Credit Card Account Berkshire parsing',
        input_type: 'Checkboxes',
        required: false,
        input_options: [
          {
            id: '21277b8f-3b63-492d-9047-bfd960bdaab2',
            value: 'monitoring unleash',
            order: -1,
          },
          {
            id: '5afeb3dc-e916-4040-940d-69ebed8a29d7',
            value: 'Licensed expedite',
            order: -1,
          },
          {
            id: 'ffca28a4-996e-4592-836c-487e9bd21579',
            value: 'customized',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '72a862e2-d56e-48ca-b659-b242455b18eb',
    },
    '4efd1018-dbb0-4ef6-8d1e-b3b9b947689e': {
      id: '4efd1018-dbb0-4ef6-8d1e-b3b9b947689e',
      data: {
        title: 'Metrics web services',
        description:
          'Corporis aliquam est voluptatem autem facere cumque ipsam ut. Hic sit tempora est sit nostrum eos voluptatem voluptatum recusandae. Totam et voluptatem. Quia aut error voluptate. Est laborum velit ut vel.',
        helper_text: 'Jewelery',
        input_type: 'Checkboxes',
        required: true,
        input_options: [
          {
            id: '6349b805-7463-449a-8f2c-013b712ebf34',
            value: 'system engine Investment Account Angola',
            order: -1,
          },
          {
            id: '9ceb9684-06ae-46a1-9c31-74d560ae1457',
            value: 'projection Specialist input',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '72a862e2-d56e-48ca-b659-b242455b18eb',
    },
  },
};

export const fakeConsultation2 = {
  consultation: {
    id: 2,
    primary_contact: 'Casimir Crona',
    data: {
      title: 'Gorgeous Frozen Cheese SQL Wooden',
      description:
        '<p>Veritatis adipisci explicabo deserunt voluptatem aliquam et quis sequi. Minima quia similique aut possimus eveniet assumenda provident porro. Eveniet dolorum molestias harum hic assumenda eaque.\n \rQui et perspiciatis dicta saepe in ut. Dolorem doloribus est assumenda ad ut pariatur aut. Expedita eligendi cupiditate adipisci suscipit. Est eveniet ipsum dolores. Culpa labore itaque beatae beatae est beatae.\n \rAut itaque cupiditate repudiandae ex explicabo ducimus sint quae id. Voluptatibus illo inventore et culpa magni quia illum adipisci nostrum. Dolore sunt reprehenderit iure.</p>',
      status: 'Draft',
      privacy_statement:
        '<p>Quia neque fugiat. Voluptatibus fugit eos eum vero est labore facilis. Deserunt magni ut et voluptatem pariatur ipsum dolor illo.</p>',
      consultation_dates: {
        open: '2019-03-01T16:54:39.720Z',
        close: '2019-09-04T23:08:04.077Z',
      },
      grace_period: 0,
      emails: {
        original_receiver: 'Reyna_Gerhold23@example.org',
        notification: 'Easton_OKeefe60@example.org',
      },
      assets: {
        supporting_documents: [
          {
            lastModified: 1567388518,
            lastModifiedDate: 1567388518527,
            name: 'home_loan_account_washington.atx',
            size: 22089,
            webkitRelativePath: '',
          },
        ],
        logo: {
          lastModified: 1567388518,
          lastModifiedDate: 1567388518529,
          name: 'overriding_primary.mgz',
          size: 68313,
          webkitRelativePath: '',
        },
      },
      required_contact_fields: {
        given_or_company_name: false,
        surname: true,
        contact_person: true,
        address: true,
        region: true,
        country: true,
        phone: false,
        email: false,
      },
      submitter_types: {
        allow_public_selection: true,
        required: false,
        options: [
          {
            label: 'models',
            value: '1b1dfee1-8c0f-44aa-b942-497bc41dcd0d',
          },
          {
            label: 'bypass Arizona Face to face',
            value: '98cb2b90-13da-4e02-8d93-37ea5cfc28ae',
          },
          {
            label: 'Idaho Mayotte Money Market Account',
            value: '46b89a87-8116-4751-832d-9119b8504d33',
          },
        ],
      },
    },
    formErrors: {},
    section_ids: [
      '2d0bbdac-5d6a-4fce-b6b2-45f97a3ac011',
      '72d8b60b-9da1-42e2-99df-69ae4c7a235f',
      'a03efd9c-4bd5-4180-9c45-1b67180bc8cc',
      'ee9b6c5f-8107-4cad-bcb5-cb658e8cbc74',
      'a025a739-5acb-4b10-b860-51d54e75a6c8',
      '6587fcea-839a-4cb4-875d-760923e36778',
      '7d9091b6-eae6-4ca3-b283-fdd94d2ce1b6',
    ],
  },
  sections: {
    '2d0bbdac-5d6a-4fce-b6b2-45f97a3ac011': {
      id: '2d0bbdac-5d6a-4fce-b6b2-45f97a3ac011',
      data: {
        title: 'North Dakota invoice azure',
        description:
          'Voluptatem delectus soluta itaque odio totam dignissimos sunt numquam. Voluptates sequi repudiandae perferendis aperiam. Ut qui voluptatem natus id. Ipsum tempora necessitatibus nihil ea asperiores nobis. Rerum consequatur expedita totam non omnis magnam quo nulla. Voluptate quam quo non iusto hic ipsam.',
      },
      formErrors: {},
      question_ids: ['935bf4f6-d4b2-42f3-a714-def1220ec985'],
      consultation_id: 2,
    },
    '72d8b60b-9da1-42e2-99df-69ae4c7a235f': {
      id: '72d8b60b-9da1-42e2-99df-69ae4c7a235f',
      data: {
        title: 'generate Checking Account',
        description:
          'Sit id ut soluta sed fugiat sed veritatis. Nisi ex omnis alias sequi non delectus. Dicta dicta libero dolores accusamus nam libero. Accusamus aut fuga voluptas iure aut qui deserunt alias voluptatem. Sed voluptatem in ab nihil quos omnis sunt.',
      },
      formErrors: {},
      question_ids: [
        '6be9cd4d-4e3e-4a88-baad-30e9a7b5a439',
        '3d315b36-280a-46a2-9968-3751c1d9129e',
        'ff3da9ce-7168-418d-b942-45b8431d2e9c',
        'c93289f3-e996-4f41-ac1e-634b5eba9d6a',
        '20d1346c-bd5f-4ce1-9381-437b2978dda0',
        'c7a1b469-d4fd-41d1-afc3-3c89b042cb79',
        '2ac96442-774b-495e-aae0-7f62f69de87e',
      ],
      consultation_id: 2,
    },
    'a03efd9c-4bd5-4180-9c45-1b67180bc8cc': {
      id: 'a03efd9c-4bd5-4180-9c45-1b67180bc8cc',
      data: {
        title: 'Soft Chief',
        description:
          'Tenetur beatae molestias inventore ea. Debitis tempora et explicabo aut exercitationem non inventore exercitationem accusamus. Similique porro non adipisci et corrupti est. Aliquid illo et aut beatae deleniti eos explicabo vel in. Voluptas a nobis dolor similique est et consequatur. Non quia ratione suscipit non ullam ut.',
      },
      formErrors: {},
      question_ids: [
        'cb707edc-57a6-4355-87ad-6d8343e41024',
        '911ecd9d-393d-426b-b972-eb73d7476524',
        '892c5e13-3326-43ed-9f33-a7c3e18f578d',
        '986b0ea6-c53e-4313-8af9-8fe7730a7de2',
        'dabfad81-2b9a-44b3-a5c3-2ba38a632dde',
        'd69c3339-05ea-4ccf-9f02-4ba9ab1d692f',
        '9956e385-2c3e-4093-afca-c6b1983b9832',
      ],
      consultation_id: 2,
    },
    'ee9b6c5f-8107-4cad-bcb5-cb658e8cbc74': {
      id: 'ee9b6c5f-8107-4cad-bcb5-cb658e8cbc74',
      data: {
        title: 'wireless',
        description:
          'Dolores qui molestiae in ea. Dolores necessitatibus pariatur necessitatibus nihil assumenda. Modi veniam dolorem id voluptatem et nihil dolorem debitis. Porro aliquid recusandae minus.',
      },
      formErrors: {},
      question_ids: [
        'd85ab2db-30f5-4fcd-b7b2-d12ba3f91045',
        'f858b002-f532-4ff2-a706-d89d4170b72c',
        '37803525-eac0-4cb7-8d23-9fb887146bc2',
        '1850b2df-5471-4671-a00d-8744ecce799e',
        '03b77e22-581f-49c9-ab8c-d0f6c7039f94',
        '4c5d912e-6306-4eb8-923a-7ab28330204d',
        'ad0eed68-c925-4ec6-8d5a-7f70b5d196d5',
      ],
      consultation_id: 2,
    },
    'a025a739-5acb-4b10-b860-51d54e75a6c8': {
      id: 'a025a739-5acb-4b10-b860-51d54e75a6c8',
      data: {
        title: 'Total interface Gloves',
        description:
          'Aut debitis expedita qui. Quasi omnis velit tempora. Consequuntur amet explicabo quas perferendis asperiores placeat nostrum aut. Maiores et quis aut ad dolorem.',
      },
      formErrors: {},
      question_ids: ['1aaf74eb-3319-45fc-91ac-10ba5a33973c', '9963e3ae-3449-420b-8662-a77b2a097fa1'],
      consultation_id: 2,
    },
    '6587fcea-839a-4cb4-875d-760923e36778': {
      id: '6587fcea-839a-4cb4-875d-760923e36778',
      data: {
        title: 'indexing',
        description:
          'Et perspiciatis eum est. Voluptatibus ut quo quod quia qui est. Commodi ab sint magni eum blanditiis aut magnam vel. Non voluptatem debitis aut reprehenderit ut. Voluptatibus vitae aliquid quis in est dolor occaecati est mollitia. Enim quibusdam laboriosam ad quis.',
      },
      formErrors: {},
      question_ids: ['b8b8000f-6a92-4b42-8b7b-d3290c6a865f', 'b42ebae3-8031-42fc-a830-b75c43416322'],
      consultation_id: 2,
    },
    '7d9091b6-eae6-4ca3-b283-fdd94d2ce1b6': {
      id: '7d9091b6-eae6-4ca3-b283-fdd94d2ce1b6',
      data: {
        title: 'Analyst bluetooth',
        description:
          'Sed ut nulla qui et ut. In aspernatur dignissimos ratione veniam debitis voluptas qui qui. Velit rerum consequuntur dolor in ullam voluptate neque. Est velit qui omnis. Vero quam sint aliquid non sed modi quis sunt non. Perspiciatis labore fugit aut molestiae.',
      },
      formErrors: {},
      question_ids: [
        'a76862ec-846a-4213-b085-0d64850266c2',
        '711f448d-d9de-483c-9795-7b13fedeaf29',
        'c7d1d436-d668-4c8a-b5ea-c0fbaad96a63',
      ],
      consultation_id: 2,
    },
  },
  questions: {
    '935bf4f6-d4b2-42f3-a714-def1220ec985': {
      id: '935bf4f6-d4b2-42f3-a714-def1220ec985',
      data: {
        title: 'circuit Argentine Peso',
        description:
          'Iure error eum enim. Tempora eveniet ex. Maxime aut nesciunt repellat ducimus ad labore. Facere rerum iure quo rem eos debitis.',
        helper_text: '4th generation',
        input_type: 'Multiple Choice',
        required: true,
        input_options: [
          {
            id: '95e4f60f-17ec-4e59-9241-c5ab1354e618',
            value: 'Practical Rubber Keyboard invoice maroon',
            order: -1,
          },
          {
            id: '9b6c7165-ce68-4e83-b886-ecbe7b29c674',
            value: 'Practical Steel Shirt Djibouti',
            order: -1,
          },
          {
            id: '28cee8ab-4080-4efb-b3b2-3936b7d38b7a',
            value: 'salmon',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '2d0bbdac-5d6a-4fce-b6b2-45f97a3ac011',
    },
    '6be9cd4d-4e3e-4a88-baad-30e9a7b5a439': {
      id: '6be9cd4d-4e3e-4a88-baad-30e9a7b5a439',
      data: {
        title: 'Bedfordshire matrix',
        description:
          'Ipsum voluptas consequuntur nostrum. Et et rerum numquam id. Eligendi tenetur ea est nam eveniet quae et tenetur. Architecto aut ab eius. Consequatur eos quisquam magni earum voluptatem ipsum sapiente aperiam. Fugit quibusdam voluptatem porro molestias.',
        helper_text: 'payment Colombia orange',
        input_type: 'Multiple Choice',
        required: false,
        input_options: [
          {
            id: 'e77dbfc0-e54d-4899-a185-744179e8014e',
            value: 'calculating Cotton',
            order: -1,
          },
          {
            id: 'c6424e49-8c9b-465c-91e0-e51f6b699c64',
            value: 'Rand Loti copy',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '72d8b60b-9da1-42e2-99df-69ae4c7a235f',
    },
    '3d315b36-280a-46a2-9968-3751c1d9129e': {
      id: '3d315b36-280a-46a2-9968-3751c1d9129e',
      data: {
        title: 'deposit bypassing black',
        description:
          'Ut temporibus molestias maxime ducimus est nobis quidem nisi. Quia libero ut ex possimus eos nisi asperiores. Ut est a voluptatem.',
        helper_text: 'Principal',
        input_type: 'Multiple Choice',
        required: true,
        input_options: [
          {
            id: 'f35eb40b-dd17-4af5-b544-50f38be12b3b',
            value: 'supply-chains',
            order: -1,
          },
          {
            id: 'd1fa4ee8-50bb-40c7-ae65-bc8a39aa07e2',
            value: 'Dam Terrace South Carolina',
            order: -1,
          },
          {
            id: '90d9ea67-e2be-43a8-ab5f-91eda995c212',
            value: 'Generic bandwidth',
            order: -1,
          },
          {
            id: 'd2c99f60-34e0-4d39-a1fe-cd0439bba0ca',
            value: 'exploit innovate innovative',
            order: -1,
          },
          {
            id: 'daacbbbf-3f71-4739-8a86-9ee68789611e',
            value: 'multi-byte Buckinghamshire Soft',
            order: -1,
          },
          {
            id: '58deab77-c633-47c7-8bed-d678559a1ed3',
            value: 'challenge index Bedfordshire',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '72d8b60b-9da1-42e2-99df-69ae4c7a235f',
    },
    'ff3da9ce-7168-418d-b942-45b8431d2e9c': {
      id: 'ff3da9ce-7168-418d-b942-45b8431d2e9c',
      data: {
        title: 'Indiana Texas parsing',
        description:
          'Ut tempore voluptas ut et dolores nesciunt. Ducimus ea et blanditiis omnis cum aliquam. Possimus velit vel dignissimos facere delectus optio tempore molestiae.',
        helper_text: 'Avon',
        input_type: 'Multiple Choice',
        required: true,
        input_options: [
          {
            id: '8b87dece-4e3b-45d0-8b78-ff4996f347b5',
            value: 'turquoise',
            order: -1,
          },
          {
            id: '6e25749f-b2c4-4da7-9b0b-d4bc4d4df61f',
            value: 'Convertible Marks',
            order: -1,
          },
          {
            id: '1b6852ed-0e3d-4100-b303-e9b0d1d6b04e',
            value: 'indexing generating',
            order: -1,
          },
          {
            id: '2f3418bd-ceb7-4b1c-8770-c0b32d9498de',
            value: 'orchid Human synthesize',
            order: -1,
          },
          {
            id: 'fb857699-b727-4560-aa2b-c9933802bf73',
            value: 'connect',
            order: -1,
          },
          {
            id: 'a2f796b6-4cd7-4d6f-8a44-91bc44eb9aff',
            value: 'orchid',
            order: -1,
          },
          {
            id: '7204661a-fb7b-4552-b51b-a9ff087f4ea3',
            value: 'back up payment',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '72d8b60b-9da1-42e2-99df-69ae4c7a235f',
    },
    'c93289f3-e996-4f41-ac1e-634b5eba9d6a': {
      id: 'c93289f3-e996-4f41-ac1e-634b5eba9d6a',
      data: {
        title: 'feed Bedfordshire',
        description:
          'Labore autem omnis fuga velit provident et commodi accusantium minus. Quia dignissimos et deleniti ducimus praesentium id vel adipisci. Ipsam praesentium molestiae at vero commodi. Voluptate nihil aut. Exercitationem voluptatem pariatur accusamus sunt tenetur qui quam quibusdam. Ea recusandae amet.',
        helper_text: 'Refined e-commerce',
        input_type: 'Multiple Choice',
        required: false,
        input_options: [
          {
            id: '146e87f2-c30a-4c89-9728-8374040342cc',
            value: 'quantifying Bedfordshire leading-edge',
            order: -1,
          },
          {
            id: '9a4fa807-a6a7-4b12-9e8f-7d1662cd95a6',
            value: 'Credit Card Account',
            order: -1,
          },
          {
            id: 'dba8d37a-a81b-4254-8f0f-46f14ad73525',
            value: 'Mouse primary Intelligent',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '72d8b60b-9da1-42e2-99df-69ae4c7a235f',
    },
    '20d1346c-bd5f-4ce1-9381-437b2978dda0': {
      id: '20d1346c-bd5f-4ce1-9381-437b2978dda0',
      data: {
        title: 'Balanced Fresh Tools',
        description:
          'Rerum doloribus nemo mollitia aut pariatur aut. Ea repellat nihil eveniet esse eveniet voluptate ut facere sed. Optio illum nisi. Nihil et labore beatae fugit aut consequatur sequi eius. Veritatis neque assumenda consectetur veniam aut accusantium.',
        helper_text: 'Congo payment',
        input_type: 'Checkboxes',
        required: true,
        input_options: [
          {
            id: '52237deb-f0a8-4641-b560-1039aa87b9c3',
            value: 'Devolved HTTP',
            order: -1,
          },
          {
            id: '23b41316-3af1-432c-a20a-cc6f93d1d8fb',
            value: 'Product Cheese',
            order: -1,
          },
          {
            id: '6e00ebbb-6d04-4bd0-8df1-ed3aeef0c1e0',
            value: 'application',
            order: -1,
          },
          {
            id: 'a64e3b1d-26bd-446d-96b3-fcd8771d45c2',
            value: 'Savings Account Senior overriding',
            order: -1,
          },
          {
            id: 'b2107334-16e4-4e75-b893-d634a9c0306b',
            value: 'invoice bypass',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '72d8b60b-9da1-42e2-99df-69ae4c7a235f',
    },
    'c7a1b469-d4fd-41d1-afc3-3c89b042cb79': {
      id: 'c7a1b469-d4fd-41d1-afc3-3c89b042cb79',
      data: {
        title: 'Shoals',
        description:
          'Non ea optio eos sed ut facilis. Cumque nam aut odio occaecati non accusantium animi eos sapiente. Ipsam neque culpa sapiente odit consectetur necessitatibus corporis quo laboriosam. Non eius nesciunt nihil. In sapiente dolorum ut voluptate repellendus suscipit cumque numquam. Est reprehenderit tempora a.',
        helper_text: 'Berkshire Wall Central',
        input_type: 'Checkboxes',
        required: true,
        input_options: [
          {
            id: '4f9ff4c9-398b-4fff-9aa4-f91c5a5326b7',
            value: 'Buckinghamshire Plastic back up',
            order: -1,
          },
          {
            id: '7e2073ce-65ba-4fb4-bf11-72fed93c339c',
            value: 'Creative evolve transmitter',
            order: -1,
          },
          {
            id: 'b792dfca-045c-4940-a472-8c980a9f46aa',
            value: 'Games didactic',
            order: -1,
          },
          {
            id: 'cda8da18-4756-41c8-ae9b-c5cb1b41e989',
            value: 'Unbranded Cotton Shirt Handcrafted Fresh Bike indigo',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '72d8b60b-9da1-42e2-99df-69ae4c7a235f',
    },
    '2ac96442-774b-495e-aae0-7f62f69de87e': {
      id: '2ac96442-774b-495e-aae0-7f62f69de87e',
      data: {
        title: 'Agent Senior Solutions',
        description:
          'Vel est quasi tempora quo necessitatibus eum. Voluptas error dicta necessitatibus accusamus quia consectetur nostrum. Similique dicta dignissimos culpa provident distinctio rerum omnis culpa impedit. Dolor expedita maiores et eligendi vitae veritatis magnam.',
        helper_text: 'Computers',
        input_type: 'Multiple Choice',
        required: false,
        input_options: [
          {
            id: '35def74e-a19a-4c16-ab19-975162503c74',
            value: 'Sports utilize',
            order: -1,
          },
          {
            id: '2c97a6d4-69af-46e5-973b-c9a7a1d235ab',
            value: 'backing up card',
            order: -1,
          },
          {
            id: '874896fe-b44f-43f9-b2fc-dcca0cff6c4d',
            value: 'Plastic Shirt Customer',
            order: -1,
          },
          {
            id: 'f1a8cb4b-db1f-44f5-8203-088f3f771e38',
            value: 'Security Savings Account bluetooth',
            order: -1,
          },
          {
            id: '2003550b-e5dc-470f-80ab-0071a1988793',
            value: 'Triple-buffered intuitive Money Market Account',
            order: -1,
          },
          {
            id: 'bae602d7-180f-4bd2-97ba-b0e6ea4343ba',
            value: 'bottom-line',
            order: -1,
          },
          {
            id: '3fe45f87-b484-442f-9985-d0a1d23ed65a',
            value: 'convergence protocol',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '72d8b60b-9da1-42e2-99df-69ae4c7a235f',
    },
    'cb707edc-57a6-4355-87ad-6d8343e41024': {
      id: 'cb707edc-57a6-4355-87ad-6d8343e41024',
      data: {
        title: 'Rubber',
        description:
          'Minus sunt ipsum totam quisquam ullam officiis non optio. Nihil sunt soluta voluptas illo neque molestiae perspiciatis perspiciatis. Qui nesciunt dolor culpa voluptates ullam. Perspiciatis et blanditiis tempora magni corporis et incidunt animi laborum. Impedit fugit laudantium dolorem. Deleniti eos dolorem ea.',
        helper_text: 'transmit Soft',
        input_type: 'Checkboxes',
        required: false,
        input_options: [
          {
            id: '19fd1e39-00f9-4930-9dbf-71cff6bd5434',
            value: 'Fresh back up pixel',
            order: -1,
          },
          {
            id: 'bafcbcc9-13b9-4442-a1cd-4d0ee7dd74f2',
            value: 'Shore',
            order: -1,
          },
          {
            id: '20e3cd65-8c36-4711-8af8-4f2571a23d67',
            value: 'Nebraska reinvent budgetary management',
            order: -1,
          },
          {
            id: 'e5cf3c7c-0e2a-428d-95c9-3357568450f0',
            value: 'Open-architected Licensed Plastic Table bypass',
            order: -1,
          },
          {
            id: '6f5348f1-cdc2-41dc-8401-0ded392c31f9',
            value: 'Music mission-critical',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'a03efd9c-4bd5-4180-9c45-1b67180bc8cc',
    },
    '911ecd9d-393d-426b-b972-eb73d7476524': {
      id: '911ecd9d-393d-426b-b972-eb73d7476524',
      data: {
        title: 'Avon Argentina',
        description:
          'Quo atque quas quam nobis. Officiis odit sequi commodi nihil voluptatem quis. Vel est nulla rerum ea eos hic. Nostrum debitis ipsum voluptatum magni qui omnis eos. Culpa possimus mollitia dolores qui voluptatem est. Distinctio officiis illum ipsa inventore rerum est maiores quia.',
        helper_text: 'Forward Florida Central African Republic',
        input_type: 'Multiple Choice',
        required: false,
        input_options: [
          {
            id: 'cdc020f8-a6b6-453d-8a12-94f86902f638',
            value: 'Austria District indexing',
            order: -1,
          },
          {
            id: '24ee5bb9-7b22-4167-a3cd-edca2d5e460e',
            value: 'Gorgeous Soft Pizza',
            order: -1,
          },
          {
            id: '08dd7ada-1539-485a-a2a7-f74586697dc4',
            value: 'responsive Distributed',
            order: -1,
          },
          {
            id: 'fcf8e99c-89b1-491e-8acb-e87770b336fc',
            value: 'copy sensor',
            order: -1,
          },
          {
            id: '3908d6de-4fcd-4251-9ff1-8a1edfad65e5',
            value: 'backing up strategic Cedi',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'a03efd9c-4bd5-4180-9c45-1b67180bc8cc',
    },
    '892c5e13-3326-43ed-9f33-a7c3e18f578d': {
      id: '892c5e13-3326-43ed-9f33-a7c3e18f578d',
      data: {
        title: 'HTTP optical',
        description:
          'Tempore mollitia ut quas ut et adipisci molestiae ratione ipsam. Saepe vel facilis dolorem suscipit animi. Vel nobis dolore cupiditate officiis sit asperiores rerum. Aliquam id cum deleniti quasi vel aliquid.',
        helper_text: 'Tuna',
        input_type: 'Multiple Choice',
        required: false,
        input_options: [
          {
            id: '78839df9-9c78-4353-817c-3f8ccaa82c91',
            value: 'Analyst',
            order: -1,
          },
          {
            id: '62cd3f27-dfa8-461b-9352-d0ee334123aa',
            value: 'HDD Tasty',
            order: -1,
          },
          {
            id: '1bd94b0a-34c8-4bbf-ba45-daf109b9de77',
            value: 'Gorgeous',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'a03efd9c-4bd5-4180-9c45-1b67180bc8cc',
    },
    '986b0ea6-c53e-4313-8af9-8fe7730a7de2': {
      id: '986b0ea6-c53e-4313-8af9-8fe7730a7de2',
      data: {
        title: 'HDD Avon parsing',
        description:
          'Voluptatem ut nemo. Aperiam eos repellat ipsa rerum soluta. Similique omnis et repudiandae doloribus est vero dolor reiciendis. Ipsum quis non. Sunt non sapiente necessitatibus sunt blanditiis ad. Nulla velit inventore non ullam pariatur omnis.',
        helper_text: 'salmon Lead facilitate',
        input_type: 'Multiple Choice',
        required: false,
        input_options: [
          {
            id: '2d37a4a8-39d1-484a-a62f-03a6f7992c80',
            value: 'Cuba',
            order: -1,
          },
          {
            id: '6d616d15-5a1b-4fc6-a91d-5c9deb9c5372',
            value: 'protocol Rustic Fresh Fish',
            order: -1,
          },
          {
            id: '97689395-1792-4e79-be53-8f4696610c32',
            value: 'portals Bedfordshire',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'a03efd9c-4bd5-4180-9c45-1b67180bc8cc',
    },
    'dabfad81-2b9a-44b3-a5c3-2ba38a632dde': {
      id: 'dabfad81-2b9a-44b3-a5c3-2ba38a632dde',
      data: {
        title: 'Personal Loan Account Small Steel Mouse',
        description:
          'Voluptatem maiores deserunt et illum voluptatem. Earum accusamus et ut est. Accusamus magni quia amet velit est ut quia ut.',
        helper_text: 'Creative Division',
        input_type: 'Multiple Choice',
        required: true,
        input_options: [
          {
            id: '0ca804c3-b7b2-4259-9d6d-285b69024b7a',
            value: 'matrix Granite',
            order: -1,
          },
          {
            id: 'c33e784e-e531-4304-bef8-fd0873083eac',
            value: 'stable',
            order: -1,
          },
          {
            id: '152b0757-bb87-451b-9b24-2f350cadebf7',
            value: 'Configuration',
            order: -1,
          },
          {
            id: 'b9ddf771-e6e9-4595-abd3-6b79ace37434',
            value: 'Fresh Principal',
            order: -1,
          },
          {
            id: '8096a9ae-3cdf-4e29-818b-473d3a4ed673',
            value: 'tangible Cotton',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'a03efd9c-4bd5-4180-9c45-1b67180bc8cc',
    },
    'd69c3339-05ea-4ccf-9f02-4ba9ab1d692f': {
      id: 'd69c3339-05ea-4ccf-9f02-4ba9ab1d692f',
      data: {
        title: 'Credit Card Account Division Investment Account',
        description:
          'Reprehenderit corrupti molestiae. Distinctio quod expedita ea qui vitae. Excepturi explicabo dolor dolor voluptas.',
        helper_text: 'teal Nuevo Sol',
        input_type: 'Checkboxes',
        required: true,
        input_options: [
          {
            id: '24fe345a-13fa-4f09-9a32-fccf3e7cb1db',
            value: 'wireless',
            order: -1,
          },
          {
            id: '869d3f23-7079-4148-a1eb-020a5e03a358',
            value: 'Savings Account',
            order: -1,
          },
          {
            id: '6a246a6a-c2c2-4bfd-a4d5-8fa04eda7a2e',
            value: 'core Virginia haptic',
            order: -1,
          },
          {
            id: '3638cd2d-f6b3-4064-9e12-0b8f5b0f67a3',
            value: 'navigating Plastic Automotive',
            order: -1,
          },
          {
            id: '3975f1b1-fa96-4aa8-b1db-da2d20a81897',
            value: 'Function-based back-end',
            order: -1,
          },
          {
            id: 'baf907c5-b5a9-4fce-8406-a00993b3803d',
            value: 'back-end Coordinator',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'a03efd9c-4bd5-4180-9c45-1b67180bc8cc',
    },
    '9956e385-2c3e-4093-afca-c6b1983b9832': {
      id: '9956e385-2c3e-4093-afca-c6b1983b9832',
      data: {
        title: 'Incredible Keyboard',
        description:
          'Sed deserunt sed animi sit et consequatur. Veritatis esse officiis itaque voluptas laboriosam error aut natus molestiae. Occaecati autem alias assumenda eveniet qui laborum. Tempore dolorum odit inventore autem est autem. Rerum doloremque consequatur et et dolorem.',
        helper_text: 'Home Awesome',
        input_type: 'Multiple Choice',
        required: false,
        input_options: [
          {
            id: 'e8919299-e3ac-48c4-9a5f-fda6c56b675f',
            value: 'deliverables',
            order: -1,
          },
          {
            id: 'be33660c-aad8-4026-8fa7-0bd3e96fa882',
            value: 'Tuna',
            order: -1,
          },
          {
            id: '0b1f986b-ab75-4499-bd0b-712c6e252410',
            value: 'Nepalese Rupee teal',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'a03efd9c-4bd5-4180-9c45-1b67180bc8cc',
    },
    'd85ab2db-30f5-4fcd-b7b2-d12ba3f91045': {
      id: 'd85ab2db-30f5-4fcd-b7b2-d12ba3f91045',
      data: {
        title: 'synthesize adapter migration',
        description:
          'Qui sed ex asperiores aut expedita ab pariatur quos. Aliquid possimus omnis sapiente saepe ducimus. Voluptate voluptatem et.',
        helper_text: 'Markets',
        input_type: 'Multiple Choice',
        required: false,
        input_options: [
          {
            id: '4423b24b-b402-47e0-ad35-12fdc3bb5a7c',
            value: 'optical violet invoice',
            order: -1,
          },
          {
            id: '91df76b9-aa93-4afc-b821-5619c7de164a',
            value: 'Associate auxiliary mindshare',
            order: -1,
          },
          {
            id: '01783dcc-ccd3-4fee-9b48-941bb8bb054c',
            value: 'firewall',
            order: -1,
          },
          {
            id: 'f24393ef-fdf1-446e-a00e-d89641807243',
            value: 'Buckinghamshire',
            order: -1,
          },
          {
            id: '91c68c82-9883-4fe2-99f4-2f9ef215e3c4',
            value: 'embrace',
            order: -1,
          },
          {
            id: 'd74cd202-ed90-4299-95f1-af33705dc026',
            value: 'online invoice',
            order: -1,
          },
          {
            id: '4fbd956b-236b-4f24-a6e5-93135747ca0f',
            value: 'zero defect multi-byte Mountains',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'ee9b6c5f-8107-4cad-bcb5-cb658e8cbc74',
    },
    'f858b002-f532-4ff2-a706-d89d4170b72c': {
      id: 'f858b002-f532-4ff2-a706-d89d4170b72c',
      data: {
        title: 'wireless',
        description:
          'Et esse eaque possimus esse excepturi vero a incidunt. Fuga minus eos amet perferendis veniam voluptas aliquid quis earum. Rerum omnis tempora est velit. Voluptas id rerum. Qui sed nesciunt. Ipsum magnam repellat quisquam quia omnis aut.',
        helper_text: 'compress Investment Account',
        input_type: 'Multiple Choice',
        required: false,
        input_options: [
          {
            id: 'c7f6e765-e7c9-4e89-8722-36ad161669bd',
            value: 'Chief Bouvet Island (Bouvetoya) pixel',
            order: -1,
          },
          {
            id: '0e4d6215-2fdc-4d3b-b0eb-d0b80a3fe075',
            value: 'Connecticut',
            order: -1,
          },
          {
            id: 'c9bd0564-8362-4b5e-b95a-d4924dc3afb8',
            value: 'Cambridgeshire enhance sensor',
            order: -1,
          },
          {
            id: '118c731c-bc41-401c-8b5b-dd540466cc2c',
            value: 'HDD Research',
            order: -1,
          },
          {
            id: 'fa44bdf5-4903-4082-bcee-14645d036a7a',
            value: 'IB',
            order: -1,
          },
          {
            id: '8440fe18-f0b2-4a46-8c68-7a0f7c529e11',
            value: 'Common plug-and-play',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'ee9b6c5f-8107-4cad-bcb5-cb658e8cbc74',
    },
    '37803525-eac0-4cb7-8d23-9fb887146bc2': {
      id: '37803525-eac0-4cb7-8d23-9fb887146bc2',
      data: {
        title: 'port bypass indexing',
        description:
          'Ea placeat praesentium quo delectus eaque odio. Quo voluptas maiores et nobis nobis. Laborum cumque rerum rem temporibus quis quis minima ipsum ipsam. Dolor est omnis nihil non. Inventore velit aut rerum ut quia provident.',
        helper_text: 'North Dakota Wooden',
        input_type: 'Checkboxes',
        required: true,
        input_options: [
          {
            id: '10c499eb-c5c9-4236-b77e-c3d57797d0df',
            value: 'mindshare override',
            order: -1,
          },
          {
            id: 'cfbd1a2f-28d6-4492-bc32-feea034fff7f',
            value: 'payment intuitive',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'ee9b6c5f-8107-4cad-bcb5-cb658e8cbc74',
    },
    '1850b2df-5471-4671-a00d-8744ecce799e': {
      id: '1850b2df-5471-4671-a00d-8744ecce799e',
      data: {
        title: 'Division data-warehouse',
        description:
          'Et quaerat et consectetur. Labore repellat sed rerum accusantium vel non nobis delectus. Ut odio voluptas.',
        helper_text: 'program Vermont',
        input_type: 'Multiple Choice',
        required: true,
        input_options: [
          {
            id: '5b940daa-75e5-4544-b544-2c10fa462064',
            value: 'Plastic',
            order: -1,
          },
          {
            id: 'f76d6fad-b902-41c0-9a13-1346bf0be2fe',
            value: 'fuchsia',
            order: -1,
          },
          {
            id: 'a860bcad-ccc1-4fea-beb4-894b5d7f6e62',
            value: 'COM',
            order: -1,
          },
          {
            id: '1ffdbb09-3012-44de-b945-6064c91727db',
            value: 'transitional Micronesia',
            order: -1,
          },
          {
            id: 'e966d69e-5572-4961-9512-8fc60a8dd9be',
            value: 'Future',
            order: -1,
          },
          {
            id: '296d7bfa-ef90-456d-84cb-010460b1709b',
            value: 'back-end Berkshire',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'ee9b6c5f-8107-4cad-bcb5-cb658e8cbc74',
    },
    '03b77e22-581f-49c9-ab8c-d0f6c7039f94': {
      id: '03b77e22-581f-49c9-ab8c-d0f6c7039f94',
      data: {
        title: 'Practical',
        description:
          'Perspiciatis qui et earum voluptate ad saepe ratione voluptas. Velit ducimus aut. Ea fugit laudantium deserunt et debitis.',
        helper_text: 'Tools radical Ergonomic',
        input_type: 'Checkboxes',
        required: false,
        input_options: [
          {
            id: 'd21a978a-8633-4d7c-a5b1-6cccf1cef896',
            value: 'Future Unbranded Business-focused',
            order: -1,
          },
          {
            id: 'a8121a9b-be88-4655-ad49-b94af6922530',
            value: 'Refined Rubber Soap Plastic Rial Omani',
            order: -1,
          },
          {
            id: 'd766edee-0630-47b2-b691-375b0463ab0e',
            value: 'solid state Louisiana',
            order: -1,
          },
          {
            id: 'bf0e8563-f4c2-48bb-ba6b-caa29895f0ac',
            value: 'virtual',
            order: -1,
          },
          {
            id: '6abeb9de-c2d4-4cee-b377-e94d4057f2af',
            value: 'PCI solid state',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'ee9b6c5f-8107-4cad-bcb5-cb658e8cbc74',
    },
    '4c5d912e-6306-4eb8-923a-7ab28330204d': {
      id: '4c5d912e-6306-4eb8-923a-7ab28330204d',
      data: {
        title: 'Music',
        description:
          'Minima quo nihil perferendis distinctio. Dolores deserunt quo non quae iste nulla sunt a totam. Unde natus aut quos dolores sed aut nobis eos aut. Harum est dicta ut eligendi. Sunt voluptates officia odit voluptates non voluptatibus non.',
        helper_text: 'Alabama Clothing protocol',
        input_type: 'Checkboxes',
        required: false,
        input_options: [
          {
            id: '707259b4-8a8f-49bb-ba67-645d8370cd3c',
            value: 'indigo Money Market Account Auto Loan Account',
            order: -1,
          },
          {
            id: '3a57aad9-015c-4e3f-be7c-7e64c89039fb',
            value: 'RSS',
            order: -1,
          },
          {
            id: '149e62b6-5928-4d42-ba3d-6a0f29df1aa5',
            value: 'Malawi plug-and-play',
            order: -1,
          },
          {
            id: '3bd85231-4411-454c-837e-219906936633',
            value: 'benchmark New York',
            order: -1,
          },
          {
            id: '269759c6-be9d-4abc-a718-1c7bfe9161dd',
            value: 'Accounts',
            order: -1,
          },
          {
            id: 'efd188b5-ba9d-49ec-97e4-2b3faa065202',
            value: 'Polarised protocol overriding',
            order: -1,
          },
          {
            id: '5ca9c8c1-e511-47bb-9985-dd5aeb150464',
            value: 'Saudi Riyal port Mexico',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'ee9b6c5f-8107-4cad-bcb5-cb658e8cbc74',
    },
    'ad0eed68-c925-4ec6-8d5a-7f70b5d196d5': {
      id: 'ad0eed68-c925-4ec6-8d5a-7f70b5d196d5',
      data: {
        title: 'Intelligent Fresh Tuna West Virginia',
        description:
          'Dolor a totam odit impedit neque dolores sit delectus. Quasi in libero ab asperiores aut. Cumque sed non modi deserunt assumenda et corporis animi. Delectus ullam quam vero aut esse tenetur fuga.',
        helper_text: 'impactful Director',
        input_type: 'Multiple Choice',
        required: false,
        input_options: [
          {
            id: '8eb90a31-b86a-4413-87ff-3d65cbad3785',
            value: 'deliver Checking Account Soft',
            order: -1,
          },
          {
            id: '3e46ec08-77e0-414b-add8-cfa8e0ec1c57',
            value: 'Ferry Forward salmon',
            order: -1,
          },
          {
            id: '43e35d50-c570-4434-a276-489a27842201',
            value: 'focus group Internal',
            order: -1,
          },
          {
            id: 'a79032ee-2939-46f2-a84c-64fe63284e67',
            value: 'systems metrics',
            order: -1,
          },
          {
            id: 'c0eb2603-6607-4e0c-9e6a-0f06f70707d0',
            value: 'AGP synthesize reboot',
            order: -1,
          },
          {
            id: 'ce26db07-4bdd-4b77-b99e-1666f28557d5',
            value: 'withdrawal',
            order: -1,
          },
          {
            id: '78886a39-ee74-4ccb-9849-12e2c36daf19',
            value: 'Pizza',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'ee9b6c5f-8107-4cad-bcb5-cb658e8cbc74',
    },
    '1aaf74eb-3319-45fc-91ac-10ba5a33973c': {
      id: '1aaf74eb-3319-45fc-91ac-10ba5a33973c',
      data: {
        title: 'attitude Egyptian Pound National',
        description:
          'Beatae nulla velit quod ut voluptas eligendi. Aut voluptas voluptas praesentium. Ut voluptas iure laborum atque quis veritatis. Enim quo non perspiciatis debitis molestiae animi repellendus corrupti distinctio.',
        helper_text: 'Senior Health solid state',
        input_type: 'Checkboxes',
        required: false,
        input_options: [
          {
            id: 'c5b21a54-6f43-4494-bab0-2bfb9450fcd9',
            value: 'Tasty',
            order: -1,
          },
          {
            id: '54425c03-9258-4a40-b90c-c502d7789afc',
            value: 'withdrawal Bedfordshire',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'a025a739-5acb-4b10-b860-51d54e75a6c8',
    },
    '9963e3ae-3449-420b-8662-a77b2a097fa1': {
      id: '9963e3ae-3449-420b-8662-a77b2a097fa1',
      data: {
        title: 'Assistant full-range Cotton',
        description:
          'Eius dicta quaerat fuga dolores ea aut dolorem consequatur nihil. Aut magni ipsa soluta dolores exercitationem. Dolorem quaerat non. Nesciunt nesciunt facere tempore provident totam. Distinctio quos dolor odio. Et ut est deserunt tenetur rerum quam.',
        helper_text: 'mindshare Somali Shilling Fresh',
        input_type: 'Multiple Choice',
        required: false,
        input_options: [
          {
            id: '02baef12-5bc9-45ca-9c25-2c5f055dc1a1',
            value: 'Refined',
            order: -1,
          },
          {
            id: '6cac4460-7b98-4165-9c4f-ebbbae3b1d20',
            value: 'Lebanon iterate',
            order: -1,
          },
          {
            id: 'c7ad2954-16fc-4296-9af0-5f9e9bccf488',
            value: 'generating Operative SCSI',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: 'a025a739-5acb-4b10-b860-51d54e75a6c8',
    },
    'b8b8000f-6a92-4b42-8b7b-d3290c6a865f': {
      id: 'b8b8000f-6a92-4b42-8b7b-d3290c6a865f',
      data: {
        title: 'Peso Uruguayo Uruguay Peso en Unidades Indexadas',
        description:
          'Animi eligendi nostrum non provident. Nam perferendis non beatae enim illo nemo qui. Maiores omnis blanditiis ut excepturi. Numquam similique iste velit praesentium ipsa cumque architecto velit occaecati. Ut sit temporibus rem voluptatem quidem dolorem molestiae. Saepe sit ut quia nostrum.',
        helper_text: 'Designer Fantastic Rubber Chips',
        input_type: 'Multiple Choice',
        required: true,
        input_options: [
          {
            id: 'b7a22167-1d4b-4f6f-a180-fc91fb7add9e',
            value: 'cross-platform magenta open-source',
            order: -1,
          },
          {
            id: '788ed22f-0896-4f2a-b226-d03516ee59b2',
            value: 'connecting Customizable backing up',
            order: -1,
          },
          {
            id: '7360e684-afdf-48fc-8240-5a0007b229d6',
            value: 'monetize quantifying Awesome',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '6587fcea-839a-4cb4-875d-760923e36778',
    },
    'b42ebae3-8031-42fc-a830-b75c43416322': {
      id: 'b42ebae3-8031-42fc-a830-b75c43416322',
      data: {
        title: 'Horizontal Credit Card Account',
        description:
          'Praesentium esse et ex fuga quis. Voluptatem excepturi amet laboriosam nobis aut. Quos quia eligendi. Sed temporibus odio velit quasi rerum culpa perspiciatis quis. Voluptates molestiae aut quis.',
        helper_text: 'azure Berkshire',
        input_type: 'Multiple Choice',
        required: false,
        input_options: [
          {
            id: 'b2228fc9-eff5-4594-afbf-b4685efcdfdc',
            value: 'Connecticut Customer',
            order: -1,
          },
          {
            id: 'ad2bb8fa-7543-428e-897e-d7814cdbe763',
            value: 'Personal Loan Account',
            order: -1,
          },
          {
            id: 'fe4c53ca-69cd-41ed-913a-f43ebfb80660',
            value: 'embrace Home Loan Account',
            order: -1,
          },
          {
            id: '659a8cbe-bd25-4bde-ad8b-5e65566cb967',
            value: 'Bedfordshire Buckinghamshire',
            order: -1,
          },
          {
            id: '6b3c4940-10fb-442d-ad47-5c6f63c59830',
            value: 'withdrawal models silver',
            order: -1,
          },
          {
            id: 'd37cd8e6-cc6a-4306-bf4c-127a63cccaf6',
            value: 'mobile',
            order: -1,
          },
          {
            id: 'b1544147-ac60-4b59-926b-6f9e769a87e7',
            value: 'Liaison Michigan Optimization',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '6587fcea-839a-4cb4-875d-760923e36778',
    },
    'a76862ec-846a-4213-b085-0d64850266c2': {
      id: 'a76862ec-846a-4213-b085-0d64850266c2',
      data: {
        title: 'white Cotton Gloves',
        description:
          'Harum debitis repellendus dicta. Nisi et qui. Similique in molestiae natus molestias harum beatae quibusdam et ex. Modi maiores ut quis ipsum hic consequatur rerum reiciendis. Ex temporibus temporibus vitae. Totam ipsam repellat aut.',
        helper_text: 'responsive Cotton Agent',
        input_type: 'Multiple Choice',
        required: true,
        input_options: [
          {
            id: '7f546e84-5a13-47f8-b8fa-9ca23888fc5d',
            value: 'firewall',
            order: -1,
          },
          {
            id: '16476d24-2e44-4e61-a2ef-4b83f61ea9da',
            value: 'Robust indexing Steel',
            order: -1,
          },
          {
            id: 'fbbca916-6771-46ab-9366-290bb672eca6',
            value: 'scale',
            order: -1,
          },
          {
            id: '3525c0a9-e51d-4527-a27e-eb58f71163cd',
            value: 'magnetic solid state',
            order: -1,
          },
          {
            id: 'a6b03e8a-424f-4738-98a5-5b30dd14dde3',
            value: 'Bike Fresh HDD',
            order: -1,
          },
          {
            id: '6ed1849c-1cc9-4b32-b258-04e013d27929',
            value: 'experiences Branding Cuba',
            order: -1,
          },
          {
            id: '8a2c0464-72f8-4f68-948a-f5ba5a091cdb',
            value: 'local area network systems fuchsia',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '7d9091b6-eae6-4ca3-b283-fdd94d2ce1b6',
    },
    '711f448d-d9de-483c-9795-7b13fedeaf29': {
      id: '711f448d-d9de-483c-9795-7b13fedeaf29',
      data: {
        title: 'digital',
        description:
          'Architecto et molestiae qui rerum consectetur dolorem ut ullam. Consectetur quasi nulla et sunt saepe sed aut. Omnis sunt nulla impedit sed rem. Blanditiis architecto corporis autem similique quas soluta pariatur totam.',
        helper_text: 'primary payment bandwidth',
        input_type: 'Checkboxes',
        required: false,
        input_options: [
          {
            id: '4502bd05-24b1-4b6a-91bf-003eda10c744',
            value: 'port',
            order: -1,
          },
          {
            id: '4c10a229-c6fa-41ba-b09d-38ecf1bcf094',
            value: 'Credit Card Account',
            order: -1,
          },
          {
            id: 'e38b37b9-e352-4e8e-93e0-dce1eca55eec',
            value: 'Mandatory Lane contextually-based',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '7d9091b6-eae6-4ca3-b283-fdd94d2ce1b6',
    },
    'c7d1d436-d668-4c8a-b5ea-c0fbaad96a63': {
      id: 'c7d1d436-d668-4c8a-b5ea-c0fbaad96a63',
      data: {
        title: 'auxiliary connect',
        description:
          'Soluta occaecati et aliquid ut sunt accusantium. Sunt dolore dolor similique. Non cum cumque culpa rerum corrupti.',
        helper_text: 'models Outdoors',
        input_type: 'Checkboxes',
        required: false,
        input_options: [
          {
            id: '1eac6a21-6712-4a77-bfa7-bb32b30246ba',
            value: 'Bacon payment',
            order: -1,
          },
          {
            id: '5a7b0533-8703-4c47-b5ce-ea2d5faed8c2',
            value: 'Guinea Franc',
            order: -1,
          },
        ],
      },
      formErrors: {},
      section_id: '7d9091b6-eae6-4ca3-b283-fdd94d2ce1b6',
    },
  },
};
