import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import { getQuestion } from '../../redux/selectors/consultationSelectors';
import ChangeHighlighter from '../ChangeHighlighter/ChangeHighlighter';
import MemoTextField from '../Common/MemoTextField/MemoTextField';

const Inputs = props => {
  const {
    inputType,
    options,
    questionId,
    errors,
    setFieldTouched,
    handleBlur,
    handleChange,
    setFieldValue,
    name,
    values,
    sectionId,
  } = props;
  switch (inputType) {
    case 'text':

      return (
        <MemoTextField
          onDoneEditing={ () => {} }
        />
      );
    case 'radio':
      // options is an array []
      let answer = '';
      if (values.answer) {
        // regex to check if its a string or integer. Convert to integer if integer
        answer = /^\d+$/.test(values.answer) ? Number(values.answer) : values.answer;
      }

      const radioButtons = (options && options.length > 0) ? (options.map(option => (
        <FormControlLabel
          value={ option.id ? option.id : '' }
          control={ <Radio color='primary' /> }
          label={ option.value }
          labelPlacement='end'
          key={ `question-${questionId}-option-${option.id}` }
        />
      ))) : (
        <FormControlLabel
          disabled
          style={ { fontStyle: 'italic' } }
          control={ <Radio color='primary' /> }
          label='Option placeholder...'
          labelPlacement='end'
        />
      );

      return (
        <RadioGroup name={ name } aria-label='Radio Group' value={ answer } onChange={ handleChange }>
          {radioButtons}
        </RadioGroup>
      );
    case 'checkbox':
      // options is an array []

      const checkboxes = (options && options.length > 0) ? (options.map(option => {
        const optionId = option.id;
        return (
          <FormControlLabel
            control={ (
              <Checkbox
                onChange={ (e, checked) => {
                  if (values.answer) {
                    const newValue = [...values.answer];
                    if (checked) {
                      newValue.push(optionId);
                    } else {
                      newValue.splice(newValue.indexOf(optionId), 1);
                    }
                    return setFieldValue(`sections.${sectionId}.${questionId}.answer`, newValue);
                  }
                  return setFieldValue(`sections.${sectionId}.${questionId}.answer`, [optionId]);
                } }
                value={ option.value ? option.value : '' }
                color='primary'
              />
            ) }
            label={ option.value }
            key={ `question-${questionId}-option-${optionId}` }
          />

        );
      })) : (
        <FormControlLabel
          disabled
          style={ { fontStyle: 'italic' } }
          control={ <Checkbox color='primary' /> }
          label='Option placeholder...'
          labelPlacement='end'
        />
      );

      return (
        <FormGroup aria-label='Checkbox Group'>
          {checkboxes}
        </FormGroup>
      );

    default:
      return null;
  }
};

function Input(props) {
  const {
    questionId,
    values,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    errors,
    options,
    inputType,
    sectionId,
  } = props;

  return (
    <ChangeHighlighter monitorMultipleValues={ [inputType, options] }>
      <div id={ `question-${questionId}-field-input_options` }>
        <Inputs
          inputType={ inputType }
          options={ options }
          questionId={ questionId }
          name={ `sections.${sectionId}.${questionId}.answer` }
          values={ values }
          errors={ errors }
          touched={ touched }
          handleBlur={ handleBlur }
          handleChange={ handleChange }
          setFieldValue={ setFieldValue }
          setFieldTouched={ setFieldTouched }
          sectionId={ sectionId }
        />
      </div>
    </ChangeHighlighter>
  );
}

Input.propTypes = {
  questionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  options: PropTypes.any,
  values: PropTypes.object, // is required when !preview
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state, { questionId }) => getQuestion(state, questionId);

export default connect(mapStateToProps)(Input);
