//  This toggles between:
//
//      Questions/fields being "optional" by default, where a switch must manually be enabled to set questions as "required".
//      This also includes asterisks being present when fields are required, and no "(Optional)" labels.
//
//      and
//
//      Questions/fields being "required" by default, where a switch must manually be enabled to set questions as "optional".
//      This also includes "(Optional)" being present when fields are optional, and no asterisks.
//
export const ENABLE_OPTIONAL_FIELD_LABELS = true;
