import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import MakeSubmissionIcon from '@material-ui/icons/HowToVote';
import Table from '../../components/Table/Table';
import { removeConsultation, getConsultations } from '../../redux/actions/consultations';
import { selectConsultations } from '../../redux/selectors/consultationSelectors';

const columns = {
  title: { label: 'Title', filterType: 'search' },
  openDate: { label: 'Open Date', filterType: 'date' },
  closeDate: { label: 'Close Date', filterType: 'date' },
};

const convertConsultationsToRows = consultations => (
  consultations ? Object.keys(consultations).map(consultationId => {
    const consultation = consultations[consultationId];
    const openDate = moment(consultation.data.consultation_dates.open);
    const closeDate = moment(consultation.data.consultation_dates.close);
    return ({
      id: consultation.id,
      title: consultation.data.title || 'Untitled consultation',
      openDate: openDate.isValid() ? openDate.format('DD/MM/YYYY LT') : 'No open date set',
      closeDate: closeDate.isValid() ? closeDate.format('DD/MM/YYYY LT') : 'No close date set',
    });
  }) : []);

const ConsultationsTablePublic = props => {
  const {
    consultations, getConsultations, history,
  } = props;

  const [rows, setRows] = useState([]);

  useEffect(() => {
    getConsultations();
  }, [getConsultations]);

  useEffect(() => {
    setRows(convertConsultationsToRows(consultations));
  }, [consultations]);

  return (
    <MainContainer>
      <Table
        contextLabel='Open Consultations'
        columns={ columns }
        rows={ rows }
        primaryAction={ consultation => history.push(`/public/consultations/${consultation.id}/make-a-submission`) }
      />
    </MainContainer>
  );
};

const MainContainer = styled.div`
  height: 100%;
`;

const mapStateToProps = state => {
  const consultations = selectConsultations(state);
  return {
    consultations,
  };
};

const mapDispatchToProps = dispatch => ({
  removeConsultation: id => dispatch(removeConsultation(id)),
  getConsultations: () => dispatch(getConsultations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConsultationsTablePublic));
