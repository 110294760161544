import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ProcessSubmissionPageContent.styles';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { show } from '../../../redux/actions/submissions';
import windowSize from 'react-window-size';
import ProcessSubmission from '../../../containers/Forms/ProcessSubmission';

function ProcessSubmissionPageContent(props) {
  // id -> submission id
  const { submission, id } = props;

  useEffect(() => {
    if (!submission) {
      props.show(id);
      return null;
    }
  });

  if (!submission) {
    return null;
  }
  const { classes } = props;
  return (
    <div className={classes.mainContainer}>
      <div className={classes.formsContainer}>
        <div className={classes.formsContent}>
          <ProcessSubmission
            submission={props.submission}
            submissionId={id}
            consultationId={submission.consultation_id}
            submitterId={submission.submitter_id}
          />
        </div>
      </div>
      <div className={classes.pdfContainer}>
        <iframe src="https://arxiv.org/pdf/1708.08021.pdf" style={{ width: '100%', height: '100%' }} />
      </div>
    </div>
  );
}

function mapStateToProps(state, { id }) {
  return {
    submission: state.submissions.processSubmissions[id],
  };
}

ProcessSubmissionPageContent.propTypes = {
  submission: PropTypes.object,
  submissionId: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  { show },
)(windowSize(withStyles(styles, { withTheme: true })(ProcessSubmissionPageContent)));
