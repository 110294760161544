import {
  SUBMISSIONS_SHOW,
  GET_SUBMISSIONS,
  GET_SUBMISSIONS_ERROR,
  SET_SELECTED_SUBMISSIONS,
  ADD_SUBMISSION_FILTER,
  REMOVE_SUBMISSION_FILTER,
  REMOVE_ALL_SUBMISSION_FILTERS,
  SET_SUBMISSION_SORT,
  UPDATE_SUBMISSION_PAGINATION,
  SET_PROCESS_SUBMISSION_VIEW_MODE,
  GET_SUBMISSION_FILTER_OPTIONS,
} from '../constants/action-types';

import store from '../store';

import api from '../../api/index';
import axios from 'axios';

export const show = id => dispatch => {
  const url = `//${window.location.hostname}:3000/api/v1/submissions/${id}`;
  return axios
    .get(url)
    .then(response => {
      dispatch({ type: SUBMISSIONS_SHOW, payload: response.data, id: response.data.id });
    })
    .catch(error => {
      // todo
    });
};

export const getSubmissions = () => {
  return function(dispatch) {
    const { columnFilters, columnSort } = store.getState().submissions;
    return api
      .getSubmissions(1, columnFilters, columnSort)
      .then(response => {
        const submissions = response.data;
        dispatch({ type: GET_SUBMISSIONS, payload: submissions });
      })
      .catch(error => {
        console.log('Error fetching submissions from API.');
        dispatch({ type: GET_SUBMISSIONS_ERROR });
      });
  };
};

export const getSubmissionFilterOptions = () => {
  return function(dispatch) {
    return api
      .getFilterOptions(1) //param is consultation ID
      .then(response => {
        const filterOptions = response.data;
        dispatch({ type: GET_SUBMISSION_FILTER_OPTIONS, payload: filterOptions });
      })
      .catch(error => {
        console.log('Error fetching submissions from API.');
        dispatch({ type: GET_SUBMISSIONS_ERROR });
      });
  };
};

export const setSelectedSubmissions = submissions => ({
  type: SET_SELECTED_SUBMISSIONS,
  payload: submissions,
});

export const addSubmissionFilter = filter => {
  return dispatch => {
    dispatch({ type: ADD_SUBMISSION_FILTER, payload: filter });
    dispatch(getSubmissions());
  };
};

export const removeSubmissionFilter = filter => {
  return dispatch => {
    dispatch({ type: REMOVE_SUBMISSION_FILTER, payload: filter });
    dispatch(getSubmissions());
  };
};

export const removeAllSubmissionFilters = () => {
  return dispatch => {
    dispatch({ type: REMOVE_ALL_SUBMISSION_FILTERS });
    dispatch(getSubmissions());
  };
};

export const setSubmissionSort = submissionSort => {
  return dispatch => {
    dispatch({ type: SET_SUBMISSION_SORT, payload: submissionSort });
    dispatch(getSubmissions());
  };
};

export const updateSubmissionPagination = submissionPagination => {
  return dispatch => {
    dispatch({
      type: UPDATE_SUBMISSION_PAGINATION,
      payload: submissionPagination,
    });
    dispatch(getSubmissions());
  };
};

export const setProcessSubmissionViewMode = mode => {
  return dispatch => {
    dispatch({ type: SET_PROCESS_SUBMISSION_VIEW_MODE, payload: mode });
  };
};
