const styles = theme => {
  return {
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    },
    content: {
      overflow: 'auto',
      height: '100%',
    },
    headerArea: {
      marginTop: 7,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  };
};

export default styles;
