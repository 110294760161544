import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { show } from '../../redux/actions/consultations';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Question from './Question';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

const Form = props => {
  const { classes, answers, consultation, consultationId, submissionId, show } = props;
  useEffect(() => {
    if (!consultation) show(consultationId);
  });
  if (!consultation) {
    return (
      <div
        style={{
          height: '80vh',
          display: 'flex',
          justifyContents: 'center',
        }}
      >
        <div style={{ margin: 'auto' }}>
          <p>Please Wait..</p>
          <CircularProgress style={{ margin: 'auto', display: 'block' }} />
        </div>
      </div>
    );
  } else if (consultation.error) {
    return (
      <div
        style={{
          height: '90vh',
          display: 'flex',
          justifyContents: 'center',
        }}
      >
        <div style={{ margin: 'auto' }}>
          <p>{consultation.error}</p>
        </div>
      </div>
    );
  }
  const content = [];

  let render;
  for (var topicKey in consultation.topics) {
    var topic = consultation.topics[topicKey];
    content.push(<h1 key={`topic:${topic.id}`}>{topic.description}</h1>);
    var count = 1;
    for (var clauseKey in topic.clauses) {
      var clause = topic.clauses[clauseKey];
      var positions = [];
      // eslint-disable-next-line no-loop-func
      clause.clause_positions.map(id => {
        positions.push(consultation.positions[id].text);
      });
      var answer = answers[clause.id];
      content.push(
        <div key={`clause:${clause.id}`}>
          {answer && (
            <Question
              questionNumber={count++}
              question={clause.text}
              positions={positions}
              submitterNotes={answer.notes || ''}
              adminNotes={answer.internal_notes || ''}
              associatedPositions={answer.associated_positions || []}
              isMulti={!clause.is_multi ? clause.is_multi : false}
            />
          )}
          {!answer && (
            <Question
              consultation={consultation}
              questionNumber={count++}
              question={clause.text}
              positions={positions}
              isMulti={!clause.is_multi ? clause.is_multi : false}
            />
          )}
          <Divider classes={{ root: classes.divider }} />
        </div>,
      );
    }
  }
  render = content;
  return <div>{render}</div>;
};

function mapStateToProps(state, { consultationId, submissionId }) {
  return {
    consultation: state.consultations.consultationQuestions[consultationId],
    answers: state.submissions.processSubmissions[submissionId].answers,
  };
}

Form.propTypes = {
  consultation: PropTypes.object,
  consultationId: PropTypes.number,
};

export default connect(
  mapStateToProps,
  { show },
)(withStyles(styles, { withTheme: true })(Form));
