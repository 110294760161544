import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Add from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import numberToWords from 'number-to-words';
import { withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import CustomButton from '../../components/CustomButton/CustomButton';
import Question from './Question';
import {
  updateFieldInSection, addQuestion, removeQuestion, scrollTo,
} from '../../redux/actions/consultations';
import TypingTimeoutTextField from '../Forms/TypingTimeoutTextField';
import FormItemDecorator from '../Forms/FormItemDecorator';
import { createQuestion } from '../../util/data';
import { updateSubmissionData } from '../../redux/actions/submissionForm';
import { getSection } from '../../redux/selectors/consultationSelectors';
import NoContentMessage from '../../components/NoContentMessage/NoContentMessage';
import IconButton from '../../components/IconButton/IconButton';
import QuillFormItem from '../Forms/QuillFormItem';
import DeleteConfirmationDialog from '../../components/Dialogs/DeleteConfirmationDialog';

const styles = theme => ({
  paper: {
    padding: 20,
    paddingTop: 0,
    marginTop: 20,
    marginBottom: 30,
    borderRadius: 10,
    backgroundColor: '#FFFFFF',
  },
  sectionHeaderArea: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: -20,
    marginBottom: 5,
  },
  sectionTitleArea: {
    padding: 5,
    paddingLeft: 15,
    paddingRight: 15,
    height: 20,
    borderRadius: '10px 0 4px 0',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionTitleText: {
    color: 'white',
    fontSize: 14,
    fontWeight: 500,
  },
  deleteButtonContainer: {
    marginTop: 10,
    marginRight: -10,
  },
  iconButton: {
    width: '32px', height: '32px',
  },
  icon: { color: 'rgb(51, 51, 51)', fontSize: '18pt' },
  linearProgress: {
    marginLeft: -20, marginRight: -20, marginBottom: -20, marginTop: 20,
  },
});

let scrollToNewQuestion = false;

function Section(props) {
  // id = sectionId
  const {
    sectionId,
    removeSection,
    updateFieldInSection,
    data,
    formErrors,
    question_ids,
    addQuestion,
    removeQuestion,
    updateSubmissionData,
    consultation_id,
    index,
    classes,
    scrollTo,
  } = props;

  const {
    sectionHeaderArea,
    sectionTitleArea,
    sectionTitleText,
    deleteButtonContainer,
    iconButton,
    paper,
    icon,
  } = classes;

  useEffect(() => {
    if (scrollToNewQuestion) {
      setTimeout(() => scrollTo(`question-${question_ids[question_ids.length - 1]}`), 100);
      scrollToNewQuestion = false;
    }
  }, [question_ids]);


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);

  const addQuestionToSection = () => {
    const question = createQuestion(sectionId, null, question_ids.length);
    const a0 = performance.now();
    addQuestion(question, question.id);
    const a1 = performance.now();
    console.log(`Adding question took ${a1 - a0} milliseconds.`);
    scrollToNewQuestion = true;
  };

  const removeQuestionFromSection = questionId => {
    removeQuestion(questionId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDialog(false);
  };

  const toggleDialog = () => {
    setAnchorEl(null);
    setDialog(true);
  };

  const remove = () => {
    removeSection(sectionId);
    handleClose();
  };

  // Submission form data effect
  useEffect(() => {
    updateSubmissionData(consultation_id);
    return () => {
      updateSubmissionData(consultation_id);
    };
  }, [consultation_id, updateSubmissionData]);

  return (
    <Paper elevation={ 3 } className={ paper } id={ `section-${sectionId}-builder` }>
      <div className={ sectionHeaderArea }>
        <div className={ sectionTitleArea }>
          <Typography className={ sectionTitleText }>
            {`Section ${numberToWords.toWords(index + 1)}`}
          </Typography>
        </div>
        <Tooltip title={ `Delete section ${numberToWords.toWords(index + 1)}` }>
          <div className={ deleteButtonContainer }>
            <CustomButton color='red' variant='text' label='Delete' onClick={ toggleDialog } aria-label='Delete Section Button' />
          </div>
        </Tooltip>
      </div>
      <DeleteConfirmationDialog
        open={ dialog }
        context='section'
        onCancel={ handleClose }
        onConfirm={ remove }
      />
      <FormItemDecorator
        label={ `Section ${numberToWords.toWords(index + 1)} name` }
        description='The name of the section.'
        required
        error={ formErrors.title }
      >
        <TypingTimeoutTextField
          multiline
          initialValue={ data.title || '' }
          onDoneEditing={ value => updateFieldInSection('title', value) }
          rowsMax={ 3 }
          placeholder='Enter the name of the section here...'
        />
      </FormItemDecorator>
      <FormItemDecorator
        label={ `Section ${numberToWords.toWords(index + 1)} description` }
        description='The description of the section.'
        required
        error={ formErrors.description }
      >
        <QuillFormItem
          initialValue={ data.description || '' }
          onDoneEditing={ value => updateFieldInSection('description', value) }
          placeholder='Enter a description of the section here...'
        />
      </FormItemDecorator>
      {question_ids	&& question_ids.length > 0 && question_ids.map((questionId, qIndex) => (
        <Question
          key={ `submission-question-${questionId}` }
          questionId={ questionId }
          removeQuestion={ removeQuestionFromSection }
          consultationId={ consultation_id }
          sectionIndex={ index }
          index={ qIndex }
        />
      ))}
      <div style={ {
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
      } }
      >
        {question_ids.length === 0 && <NoContentMessage context='question' />}
        <CustomButton
          color='darkBlue'
          variant='text'
          onClick={ addQuestionToSection }
          label={ `Add question to section ${numberToWords.toWords(index + 1)}` }
          aria-label={ `Add question to section ${numberToWords.toWords(index + 1)}` }
          icon={ Add }
        />
      </div>
      {/* <LinearProgress className={ classes.linearProgress } /> //<-- Can be used eventually to display while question is being added. */}
    </Paper>
  );
}

Section.propTypes = {
  sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  removeSection: PropTypes.func.isRequired,
  updateFieldInSection: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  question_ids: PropTypes.array.isRequired,
  consultation_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  removeQuestion: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { sectionId }) => getSection(state, sectionId);

const mapDispatchToProps = (dispatch, { sectionId }) => ({
  updateFieldInSection: (field, value) => dispatch(updateFieldInSection(sectionId, field, value)),
  addQuestion: (question, questionId) => dispatch(addQuestion(question, questionId)),
  removeQuestion: questionId => dispatch(removeQuestion(questionId, sectionId)),
  updateSubmissionData: consultationId => dispatch(updateSubmissionData(consultationId)),
  scrollTo: value => dispatch(scrollTo(value, 'builder')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(Section));
