import axios from 'axios';

const devURL = `//${window.location.hostname}:3000/api/v1/`;
const prodURL = `https://cep-test2.marscloud.co.nz/api/v1/`;
const baseURL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? devURL : prodURL;

// Used to map the actual column names to the appropriate names that the API supports.
const columnNameMappings = {
  'Ref#': 'ref',
  'Date Created': 'date_created',
  Submitter: 'submitter',
  'Submitter Type': 'submitter_type',
  Source: 'source',
  Status: 'status',
  Assignee: 'assignee',
  Labels: 'labels',
  Title: 'title',
  'Release Date': 'release_date',
  'Close Date': 'close_date',
};

const api = {
  getSubmissions: (consultationID, filters, sort) => {
    //Parse request data into correct format for API.
    let params = { filters: {}, sort_by: {}, page_number: 0, per_page: 25 };
    filters.forEach(filter => {
      let value = filter.value;
      // If the value is a date range, converted the dates from Moment.js date objects into a string date format.
      if (value.startDate && value.endDate) {
        value = { ...value };
        console.log('start date ', value.startDate);
        value.startDate = value.startDate.format('DD/MM/YYYY LT');
        value.endDate = value.endDate.format('DD/MM/YYYY LT');
      }
      params.filters[columnNameMappings[filter.column]] = value;
    });
    params.sort_by.column = columnNameMappings[sort.column];
    params.sort_by.ascending = sort.ascending;
    console.log('Getting submissions from API with params:', params);
    console.info(baseURL + 'consultations/' + consultationID + '/submissions', { params: params });
    return axios.get(baseURL + 'consultations/' + consultationID + '/submissions', { params: params });
  },

  getConsultations: (filters, sort) => {
    //Parse request data into correct format for API.
    let params = { filters: {}, sort_by: {}, page_number: 0, per_page: 25 };
    filters.forEach(filter => {
      let value = filter.value;
      // If the value is a date range, converted the dates from Moment.js date objects into a string date format.
      if (value.startDate && value.endDate) {
        value = { ...value };
        console.log('start date ', value.startDate);
        value.startDate = value.startDate.format('DD/MM/YYYY LT');
        value.endDate = value.endDate.format('DD/MM/YYYY LT');
      }
      params.filters[columnNameMappings[filter.column]] = value;
    });
    params.sort_by.column = columnNameMappings[sort.column];
    params.sort_by.ascending = sort.ascending;
    console.log('Getting consultations from API with params:', params);
    return axios.get(baseURL + 'consultations', { params: params });
  },
  getFilterOptions: consultationID => {
    console.log('Getting filter options from API');
    console.info(baseURL + 'consultations/' + consultationID + 'filter_lists');
    return axios.get(baseURL + 'consultations/' + consultationID + '/filter_lists');
  },

  createConsultation: organisationID => {
    let params = { organisation_id: organisationID };
    console.log("Creating a new consultation for organisation with ID '" + organisationID + "'...");
    return axios.post(baseURL + 'consultations', { params: params });
  },

  updateConsultation: (organisationID, consultationID, title, topics) => {
    let params = { organisation_id: organisationID, title: title, topics: topics };
    return axios.patch(baseURL + 'consultations/' + consultationID, { params: params });
  },
};

export default api;
