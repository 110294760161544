// Submissions
export const GET_SUBMISSIONS = 'GET_SUBMISSIONS';
export const GET_SUBMISSIONS_ERROR = 'GET_SUBMISSIONS_ERROR';
export const SET_SELECTED_SUBMISSIONS = 'SET_SELECTED_SUBMISSIONS';
export const ADD_SUBMISSION_FILTER = 'ADD_SUBMISSION_FILTER';
export const REMOVE_SUBMISSION_FILTER = 'REMOVE_SUBMISSION_FILTER';
export const REMOVE_ALL_SUBMISSION_FILTERS = 'REMOVE-ALL_SUBMISSION_FILTERS';
export const SET_SUBMISSION_SORT = 'SET_SUBMISSION_SORT';
export const UPDATE_SUBMISSION_PAGINATION = 'UPDATE_SUBMISSION_PAGINATION';
export const SET_PROCESS_SUBMISSION_VIEW_MODE = 'SET_PROCESS_SUBMISSION_VIEW_MODE';
export const GET_SUBMISSION_FILTER_OPTIONS = 'GET_SUBMISSION_FILTER_OPTIONS';
export const SUBMISSIONS_SHOW = 'SUBMISSIONS_SHOW';
export const SAVE_SUBMISSION = 'SAVE_SUBMISSION';

// Consultations
export const SHOW_CONSULTATION = 'SHOW_CONSULTATION';
export const UPDATE_CONSULTATION = 'UPDATE_CONSULTATION';
export const GET_CONSULTATIONS = 'GET_CONSULTATIONS';
export const GET_CONSULTATIONS_ERROR = 'GET_CONSULTATIONS_ERROR';
export const SET_SELECTED_CONSULTATIONS = 'SET_SELECTED_CONSULTATIONS';
export const ADD_CONSULTATION_FILTER = 'ADD_CONSULTATION_FILTER';
export const REMOVE_CONSULTATION_FILTER = 'REMOVE_CONSULTATION_FILTER';
export const REMOVE_ALL_CONSULTATION_FILTERS = 'REMOVE-ALL_CONSULTATION_FILTERS';
export const SET_CONSULTATION_SORT = 'SET_CONSULTATION_SORT';
export const UPDATE_CONSULTATION_PAGINATION = 'UPDATE_CONSULTATION_PAGINATION';
export const GET_CONSULTATION_FILTER_OPTIONS = 'GET_CONSULTATION_FILTER_OPTIONS';
export const UPDATE_FIELD_IN_CONSULTATION = 'UPDATE_FIELD_IN_CONSULTATION';
export const UPDATE_FIELD_ERROR_IN_CONSULTATION = 'UPDATE_FIELD_ERROR_IN_CONSULTATION';
export const ADD_SECTION = 'ADD_SECTION';
export const REMOVE_SECTION = 'REMOVE_SECTION';
export const UPDATE_FIELD_IN_SECTION = 'UPDATE_FIELD_IN_SECTION';
export const UPDATE_FIELD_ERROR_IN_SECTION = 'UPDATE_FIELD_ERROR_IN_SECTION';
export const ADD_QUESTION = 'ADD_QUESTION';
export const REMOVE_QUESTION = 'REMOVE_QUESTION';
export const UPDATE_FIELD_IN_QUESTION = 'UPDATE_FIELD_IN_QUESTION';
export const UPDATE_FIELD_ERROR_IN_QUESTION = 'UPDATE_FIELD_ERROR_IN_QUESTION';
export const SAVE_CONSULTATION = 'SAVE_CONSULTATION';
export const CREATE_CONSULTATION = 'CREATE_CONSULTATION';
export const REMOVE_CONSULTATION = 'REMOVE_CONSULTATION';
export const VALIDATE_ENTIRE_CONSULTATION_FORM = 'VALIDATE_ENTIRE_CONSULTATION_FORM';
// Sumbitters
export const SUBMITTERS_SHOW = 'SUBMITTERS_SHOW';

// Submission Form
export const UPDATE_SUBMISSION_DATA = 'UPDATE_SUBMISSION_DATA';

// Organisation
export const SAVE_SUBMITTER_TYPES = 'SAVE_SUBMITTER_TYPES';

// General
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
