import { SAVE_SUBMITTER_TYPES } from '../constants/action-types';
import dotProp from 'dot-prop-immutable';

const initialState = { submitterTypes: null };

const saveSubmitterTypes = (state, action) => {
  const { payload } = action;
  return dotProp.set(state, 'submitterTypes', payload);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SUBMITTER_TYPES:
      return saveSubmitterTypes(state, action);
    default:
      return state;
  }
};
