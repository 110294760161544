import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { omit } from 'lodash';

let timeout = null;

class TypingTimeoutTextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.initialValue,
    };
  }

  setTypingTimeout() {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      this.props.onDoneEditing(this.state.text);
    }, 1000);
  }

  onChange = event => {
    this.setTypingTimeout();
    this.setState({ text: event.target.value });
  };

  render() {
    return (
      <TextField
        { ...omit(this.props, ['initialValue', 'onDoneEditing']) } // Exclude initialValue and onDoneEditing from transferred props.
        style={ { ...this.props.style, margin: 0, fontSize: 13 } }
        placeholder={ this.props.placeholder ? this.props.placeholder : 'Enter text here...' }
        fullWidth
        margin='dense'
        variant='outlined'
        value={ this.state.text || '' }
        onChange={ this.onChange }
        onBlur={ () => this.props.onDoneEditing(this.state.text) }
      />
    );
  }
}

export default TypingTimeoutTextField;
