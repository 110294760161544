import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Autocomplete from '../../components/Autocomplete/Autocomplete';
import { createStaticHeader } from '../../util/data';
import {
  getSectionsFromConsultations,
  getQuestionsFromConsultations,
  getConsultation,
} from '../../redux/selectors/consultationSelectors';
import { addToArray } from '../../util/ImmutableFunctions';

const styles = theme => ({
  mainContainer: {
    width: 'calc(100% - 30px)',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 15,
    paddingRight: 15,
    boxShadow: '0 3px 6px -6px rgba(0,0,0,0.90), 0 3px 6px -6px rgba(0,0,0,1)',
    mozBoxShadow: '0 3px 6px -6px rgba(0,0,0,0.90), 0 3px 6px -6px rgba(0,0,0,1)',
    webkitBoxShadow: '0 3px 6px -6px rgba(0,0,0,0.90), 0 3px 6px -6px rgba(0,0,0,1)',
    zIndex: 5,
  },
  titleContainer: {
    display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
  },
  label: {
    fontWeight: 500, fontSize: 14, color: theme.palette.darkBlue.main, paddingTop: 5, whiteSpace: 'nowrap', marginRight: 10,
  },
});

const Header = props => {
  const {
    builder, sections, questions, consultation, classes,
  } = props;
  const [headerData, setHeaderData] = useState(createStaticHeader(builder));

  useEffect(() => {
    let data = createStaticHeader(builder);
    let sectionCount = 1;
    consultation.section_ids.map(sectionId => {
      const sectionData = [];
      const section = sections[sectionId];
      sectionData.push({
        value: `section-${sectionId}`,
        label: `Section ${sectionCount} – Details`,
      });
      let questionCount = 1;
      section.question_ids.map(questionId => {
        const question = questions[questionId];
        sectionData.push({
          value: `question-${questionId}`,
          label: `Section ${sectionCount} – Question ${questionCount++}: ${question.data.title || '(Unnamed Question)'}`,
        });
      });
      data = addToArray(data, { label: `Section ${sectionCount}`, options: sectionData });
      sectionCount += 1;
    });

    // data = addToArray(createStaticHeader(builder), { label: 'Sections and Questions', options: data });
    setHeaderData(data);
  }, [sections, questions]);

  return (
    <div className={ classes.mainContainer }>
      <div className={ classes.titleContainer }>
        <Autocomplete
          className={ classes.jumpToBar }
          placeholder='Search for an area within the form, or select from the list...'
          options={ headerData }
          onChange={ props.onChange }
          maxTotalNumber={ props.maxTotalNumber }
        />
      </div>
    </div>
  );
};

Header.propTypes = {
  consultationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  submitterName: PropTypes.string,
  submissionSource: PropTypes.string,
  maxTotalNumber: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  builder: PropTypes.bool,
};

const mapStateToProps = (state, { consultationId }) => ({
  consultation: getConsultation(state, consultationId),
  sections: getSectionsFromConsultations(state, consultationId),
  questions: getQuestionsFromConsultations(state, consultationId),
});
export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(Header));
